import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import {Message} from 'primeng/api'

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(private messageService: MessageService) { }

  showSuccess(message) {
    this.messageService.add({severity:'success', summary: 'Success', detail:message});
  }

  showError(message) {
    this.messageService.add({severity:'error', summary: 'Error', detail:message});
  }

  showInfo(message) {
    this.messageService.add({severity:'info', summary: 'Info', detail:message});
  }

  showWarning(message) {
    this.messageService.add({severity:'warn', summary: 'Warning', detail:message});
  }
}
