import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HttpClientModule,HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgxSpinnerModule,NgxSpinnerService} from 'ngx-spinner';
import {AuthInterceptor} from './interceptors/auth-interceptor.service'
import {MyHttpInterceptor} from './interceptors/my-http-interceptor';
import {ToastModule} from 'primeng/toast';
import {QCommonModule} from './components/q-common/q-common.module';
import { MessageService } from 'primeng/api';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { QuillModule } from 'ngx-quill';
import { BasicInfoComponent } from './test-setup/profile/basic-info/basic-info.component';
import { NgOtpInputModule } from 'ng-otp-input';
declare global {
  interface Window { checkout:any; }
}

@NgModule({
  declarations: [
    AppComponent,
    BasicInfoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxSpinnerModule,
    ToastModule,
    QCommonModule,
    ReactiveFormsModule,
    FormsModule,
    QuillModule.forRoot(),
    NgOtpInputModule
  ],
  providers: [
    NgxSpinnerService,
    MessageService,
    {
      provide:HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MyHttpInterceptor,
      multi: true,
    },
    {
      provide:MAT_DATE_LOCALE,useValue:'en-GB',multi:true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
