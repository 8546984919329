import { Component,ViewChild, OnInit,Output,EventEmitter, Input, OnChanges,Renderer2, OnDestroy} from '@angular/core';
import { API_PATH } from 'src/app/utility/reference/api-constant';
// import ClassicEditorCustum from '../../../../custum-ckeditor/build/ckEditor';
// import ClassicEditorCustum from 'src/app/custum-ckeditor/build/ckEditor'
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ck-editor',
  templateUrl: './ck-editor.component.html',
  styleUrls: ['./ck-editor.component.css']
})
export class CkEditorComponent implements OnInit,OnChanges, OnDestroy {
  apiConstant: any = API_PATH;
  content:any;
  constructor(private renderer: Renderer2,) { 
    this.renderer.addClass(document.body, 'result_processing');
  }
  @Input() inputContent:any;
  @Output() editorText: EventEmitter<string> = new EventEmitter<string>();
  ckeConfig: any;
  @ViewChild('myckeditor') ckeditor: any;
  ngOnInit(): void {
    this.ckeConfig = {
      // extraPlugins: 'uploadimage',
      extraPlugins: 'N1ED-editor',
      skin: 'n1theme,/assets/skins/n1theme/',
      apiKey: "U9VrYpxt",
      allowedContent: true,
      removePlugins: "iframe,magicline",
      Flmngr: {
        apiKey: "U9VrYpxt",
        // urlFileManager: 'https://pathapidev.azurewebsites.net',
        // urlFiles: 'https://pathapidev.azurewebsites.net/api/V1/FuncForACMAppToUploadckeditor?command=QuickUpload&type=Files&responseType=json',
      },
      uploadUrl: environment.basePath + this.apiConstant.IMAGE_UPLOAD_API_FOR_EDITOR,

      // Configure your file manager integration. This example uses CKFinder 3 for PHP.
      filebrowserBrowseUrl:
        'https://ckeditor.com/apps/ckfinder/3.4.5/ckfinder.html',
      filebrowserImageBrowseUrl:
        'https://ckeditor.com/apps/ckfinder/3.4.5/ckfinder.html?type=Images',
      filebrowserUploadUrl: environment.basePath + this.apiConstant.IMAGE_UPLOAD_API_FOR_EDITOR,
      filebrowserImageUploadUrl:environment.basePath + this.apiConstant.IMAGE_UPLOAD_API_FOR_EDITOR,
    };
   
  }
  ngOnChanges(){
    this.content = this.inputContent;
  }
  add(){
    this.editorText.emit(this.content)
    this.content = this.inputContent;
    }
  close(){
    // this.textEditorPopup = false
     this.editorText.emit('');
  }

     ///--------------------------------------Text Editor--------------------///
    //  public Editor = ClassicEditorCustum;
     editorData='false'
     // public config = this.service.getEditorProperties().config;
     public config = {
       toolbar: {
       item: [
         'heading', '|',
         'fontfamily', 'fontsize', '|',
         'fontColor', 'fontBackgroungColor', 'backgroundColor', '|',
         'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
         'link', '|',
         'outdent', 'indent', '|',
         'bulletedList', 'numberedList', 'todoList', '|',
         'code', 'codeBlock', '|',
         'insertTable', '|',
         'imageUpload', 'blockQuote', '|',
         'undo', 'redo'
       ],
       shouldNotGroupWhenFull: true,
       viewportTopOffset:30,
     },
     contentToolbar: [
      'tableColumn', 'tableRow', 'mergeTableCells',
      'tableProperties', 'tableCellProperties'
   ],
     language: 'en'
   }

   ngOnDestroy() {
    this.renderer.removeClass(document.body, 'result_processing');
  }
}
