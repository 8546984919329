<!-- Confirmation popup-->
<div class="common-dialog delete-confirmation">
    <div class="header">
    <div class="row">
        <div class="col-md-10">  
            <h1>Confirmation Popup</h1>           
        </div>
        <div class="col-md-1">
            <button mat-icon-button mat-dialog-close color="mat-primary"><mat-icon>close</mat-icon></button>
        </div>
    </div> 
</div>
<div class="modal-body">
    <p>Once Confirmed you would not be able to do booking form CC</p>
</div> 
<div class="modal-footer">
    <button pButton type="cancel" (click)="close()" label="Cancel" class="button-border mr-1" ></button>
    <button pButton type="save" (click)="confirmPopup()" label="Yes, Confirm" class="save-button"></button>
</div> 
</div>