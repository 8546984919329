<div class="print-recept-section">

<div class="row header-sec">
    <div class="col-md-4">
        <div class="address-face-one">
            <img src="assets/images/print-logo.svg">
            <ul>
                <li>55-56 Udyog Vihar Phase - 4<br /> Gurugram (HR) - 122015</li>
                <li>011-223456, 9856743217</li>
            </ul>
        </div>
    </div>
    <div class="col-md-4"></div>
    <div class="col-md-4">
        <div class="address-face-one">
            <h4 class="font-14 test-title">Booking Location Name</h4>
            <ul>
                <li>55-56 Udyog Vihar Phase - 4<br /> Gurugram (HR) - 122015</li>
                <li>011-223456, 9856743217</li>
            </ul>
        </div>
    </div>
</div>
<div class="row pt-3">
    <div class="col-md-6">
        <div class="print-details address-col-first">
            <ul>
                <li><span>Receipt No</span> 12345</li>
                <li><span>Patient ID</span> 12345</li>
                <li><span>Patient Name</span> Vandna Luthra</li>
                <li><span>Age / Sex</span> 28 Yrs / Female</li>
                <li><span>Contact No </span> 8510881850</li>
            </ul>
        </div>
    </div>
    <div class="col-md-1"></div>
    <div class="col-md-5">
        <div class="print-details address-col">
            <ul>
                <li class="d-block text-right">11/02/2021 9:30 AM</li>
                <li><span>Visit ID :</span> 1234</li>
                <li><span>Address :</span> First Floor , B -32, Malviya Nagar, New Delhi - 11036</li>
            </ul>
        </div>
    </div>

    <div class="col-md-12 mt-5">
        <div class="card common-table patient-booking">
            <p-table [value]="data" [rows]="10" [rowHover]="true">
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="S.No">S.No</th>
                        <th pSortableColumn="Test Code">Test Code </th>
                        <th pSortableColumn="Test Name">Test Name </th>
                        <th pSortableColumn="Amount (₹)">Amount (₹) </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-type>
                    <tr>
                        <td>1</td>
                        <td>B001</td>
                        <td>Glucose Fasting</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>B001</td>
                        <td>Hemoglobin</td>
                        <td>150</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>B001</td>
                        <td>Lipid Profile</td>
                        <td>800</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>

    <div class="col-md-8"></div>
    <div class="col-md-4">
        <div class="final-pay">
            <ul>
                <li><span>Gross Amount :</span> <span>1050</span></li>
                <li><span>Privilege Card Discount :</span> <span>100</span></li>
                <li><span>GST ( 7%) :</span> <span>70</span></li>
                <li><b>Total Amount</b> <b>1,170</b></li>
                <li><b>Amount Paid :</b> <b>1,170</b></li>
                <li style="color: #e54444;">Pending Amount : <span>170</span></li>
            </ul>
        </div>
    </div>

    <div class="col-md-12 mt-4 t-and-c">
        <h5 class="mb-1">Some Points :</h5>
        <ul class="pl-0">
            <li>Suspendisse non mi maximus, volutpat urna ut, fringilla massa.</li>
            <li>Nulla sed ligula lobortis, faucibus nulla eu, pharetra quam.</li>
            <li>Etiam nec ligula varius lorem varius fermentum porttitor eu lacus.</li>
        </ul>
    </div>
</div>

</div>

<mat-dialog-actions align="end">
    <button pButton  name="no" type="cancel" mat-dialog-close label="Cancel" class="save-button mr-1" ></button>
    <button pButton (click)="print()"   name="yes" type="save" label="Print Receipt" class="save-button"></button>
</mat-dialog-actions>