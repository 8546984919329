import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {path:'',redirectTo:'login',pathMatch:'full'},
  {path:'login',loadChildren:()=>import('./components/login/login.module').then(m=>m.LoginModule)},
  {path:'master',loadChildren:()=>import('./components/master/master.module').then(m=>m.MasterModule)},
  {path:'user',loadChildren:()=>import('./components/user-management/user-management.module').then(m=>m.UserManagementModule)},
  {path:'dashboard',loadChildren:()=>import('./components/dashboard/dashboard.module').then(m=>m.DashboardModule)},
  {path:'forgot-password',loadChildren:()=>import('./components/forgot-password/forgot-password.module').then(m=>m.ForgotPasswordModule)},
  {path:'change-password',loadChildren:()=>import('./components/change-password/change-password.module').then(m=>m.ChangePasswordModule)},
  {path:'reset-password',loadChildren:()=>import('./components/reset-password/reset-password.module').then(m=>m.ResetPasswordModule)},
  {path:'password-succesfully',loadChildren:()=>import('./components/passwordresetsuccesfully/passwordresetsuccesfully.module').then(m=>m.PasswordresetsuccesfullyModule)},
  {path:'test',loadChildren:()=>import('./components/test-setup/test-setup.module').then(m=>m.TestSetupModule)},
  {path:'client',loadChildren:()=>import('./components/client/client.module').then(m=>m.ClientModule)},
  {path:'lab-setup',loadChildren:()=>import('./components/lab-setup/lab-setup.module').then(m=>m.LabSetupModule)},
  {path:'patient-registration',loadChildren:()=>import('./components/patient-registration/patient-registration.module').then(m=>m.PatientRegistrationModule)},
  {path:'lab-operations',loadChildren:()=>import('./components/lab-operations/lab-operations.module').then(m=>m.LabOperationsModule)},
  {path:'accessioning',loadChildren:()=>import('./components/accessioning/accessioning.module').then(m=>m.AccessioningModule)},
  {path:'mis',loadChildren:()=>import('./components/mis/mis.module').then(m=>m.MisModule)},
  {path:'lms',loadChildren:()=>import('./components/lms/lms.module').then(m=>m.LmsModule)},
  {path:'logistic',loadChildren:()=>import('./components/logistic/logistic.module').then(m=>m.LogisticModule)},
  {path:'crm',loadChildren:()=>import('./components/crm/crm.module').then(m=>m.CrmModule)},
  {path:'phlebo-management',loadChildren:()=>import('./components/phlebo-management/phlebo-management.module').then(m=>m.PhleboManagementModule)},
  {path:'inventory-management',loadChildren:()=>import('./components/inventory-management/inventory-management.module').then(m=>m.InventoryManagementModule)},
  {path:'profile',loadChildren:()=>import('./components/profile/profile.module').then(m=>m.ProfileModule)},
  {path:'booking-summary',loadChildren:()=>import('./components/booking-summary/booking-summary.module').then(m=>m.BookingSummaryModule)},
  {path:'booking-details',loadChildren:()=>import('./components/booking-details/booking-details.module').then(m=>m.BookingDetailsModule)},
  {path:'scheme-coupan-camp',loadChildren:()=>import('./components/scheme-coupan-camp/scheme-coupan-camp.module').then(m=>m.SchemeCoupanCampModule)},
  {path:'crmindex',loadChildren:()=>import('./components/crm-index/crm-index.module').then(m=>m.CrmIndexModule)},
  {path:'generate',loadChildren:()=>import('./components/generate-password/generate-password.module').then(m=>m.GeneratePasswordModule)},
  {path:'generate-reset-password',loadChildren:()=>import('./components/generate-reset-password/generate-reset-password.module').then(m=>m.GenerateResetPasswordModule)},
  {path:'generate-password-succesfully',loadChildren:()=>import('./components/generate-pass-set-successful/generate-pass-set-successful.module').then(m=>m.GeneratePassSetSuccessfulModule)},
  {path:'global-search',loadChildren:()=>import('./components/global-search/global-search.module').then(m=>m.GlobalSearchModule)},
  {path:'quality-control',loadChildren:()=>import('./components/quality-control/quality-control.module').then(m=>m.QualityControlModule)},
  {path:'delay-intimation',loadChildren:()=>import('./components/delay-intimation/delay-intimation.module').then(m=>m.DelayIntimationModule)},
  {path:'finance',loadChildren:()=>import('./components/finance/finance.module').then(m=>m.FinanceModule)},
  {path:'patient-interface-report',loadChildren:()=>import('./components/patient-interface-report/patient-interface-report.module').then(m=>m.PatientInterfaceReportModule)},
  {path:'patient-interface-dashboard',loadChildren:()=>import('./components/patient-interface-dashboard/patient-interface-dashboard.module').then(m=>m.PatientInterfaceDashboardModule)},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
