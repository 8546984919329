<!-- {{title}} -->
<router-outlet></router-outlet>
<p-toast [baseZIndex]="1000000"></p-toast>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="default"
  color = "#ffcb08"
  type="square-loader"
  zIndex="1000000"
>
  <!-- <p style="font-size: 20px; color: white">Loading...</p> -->
</ngx-spinner>
