import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  isDisplay = false;
  userData: any;
  toggleDisplay(){
    this.isDisplay = !this.isDisplay;
  }
  isTabSave:boolean=true;
  constructor(private commonservice: CommonService) { }

  ngOnInit(): void {
    this.userData = JSON.parse(sessionStorage.getItem('userData')).data;
  }
  
  showHideMenu(){
    
    if(this.isTabSave==false){
   this.isTabSave=true;
    }else if(this.isTabSave==true){
      this.isTabSave=false;
    }

   

    if(this.isTabSave){
      this.commonservice. setTabrReset({
        isTabSave: true,
         })
    }else if(!this.isTabSave){
      this.commonservice. setTabrReset({
        isTabSave: false,
         })
        }
  }
}
