import { finalize, tap } from 'rxjs/operators';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable ,EMPTY} from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToasterService } from '../services/toaster.service';
import { CommonService } from '../services/common.service';
import { environment } from 'src/environments/environment';
import { PathkindService } from '../services/pathkind.service';

@Injectable()

export class MyHttpInterceptor implements HttpInterceptor{
    
    count = 0;
    isSpinnerStop = false;
    data:any;
    res: any;
    requestObj:any = {};


    constructor(private spinner: NgxSpinnerService,
         private router:Router,
         private toaster: ToasterService,
         private commonService: CommonService,
         private service: PathkindService,
         ) {}

     stopSpinnerForAutoRepo(){
       
        this.commonService.getReportBuilderAutoAPICall().subscribe(ele => {
                this.data = ele
                if(this.data?.data){
                    this.isSpinnerStop = true
                }else{
                    this.isSpinnerStop = false
                }
        });
     }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
        let reqData = JSON.stringify(req.body || "");
        let keyPressed = this.commonService.isKeyPressed;

        
        //if( this.requestObj && (this.requestObj.payload == reqData || (this.requestObj.resPanding && this.requestObj.url ==  req.url)))
        if( this.requestObj && this.requestObj.payload == reqData && keyPressed && this.requestObj.keyOn)
        {
            return EMPTY;
        }
        else
        {
            this.requestObj =  {url:req.url, payload: reqData, resPanding:true, routUrl:this.router.url, keyOn: keyPressed}
            
        }
        
       
       
        this.stopSpinnerForAutoRepo();
        if(!this.isSpinnerStop ){
            this.count++;
            this.spinner.show()
        }else{
            this.commonService.setReportBuilderAutoAPICall({data:false})
        }
       
        return next.handle(req).pipe(
            tap(
                
            (event: HttpEvent<any>) => 
                {

                    
                    if (event instanceof HttpResponse) {
                        let response = event.body
                        this.res = response;
                    

                        if (response) {
                        // console.log(this.res['version'], environment.version)
                            if(this.res.version){
                                if(this.res.version !== environment.version){
                                    this.service.isReLoad.next(true) 
                                    this.router.navigate(["/"]);
                                }
                            }
                        }
                    }
                       
                } ,
            error => {
                        this.requestObj = [];
                        if(error.status == 401 && error.statusText==='Unauthorized' && this.count>0){
                            this.toaster.showError( 'UNAUTHORIZED');
                            sessionStorage.clear();
                            this.router.navigate(["/"]);
                            this.count = 1;
                          }
                    }

                ), 

            finalize(() => {
                
                    this.requestObj.resPanding = false;

                    if(this.count>0){
                    this.count--;
                    if ( this.count == 0 ) this.spinner.hide ()
                    }
                })
            );
    }
}