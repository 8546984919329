import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { API_PATH } from 'src/app/utility/reference/api-constant';
import { environment } from 'src/environments/environment';

declare let CKEDITOR: any;

@Component({
  selector: 'app-ck-editor-master',
  templateUrl: './ck-editor-master.component.html',
  styleUrls: ['./ck-editor-master.component.css']
})
export class CkEditorMasterComponent implements OnInit,OnChanges, OnDestroy {
  apiConstant: any = API_PATH;
  content:any;
  constructor(
    public commonservice : CommonService,
    private renderer: Renderer2,) { 
    this.renderer.addClass(document.body, 'result_processing');
  }
  @Input() inputContent:any;
  @Output() editorText: EventEmitter<string> = new EventEmitter<string>();
  ckeConfig: any;
  @ViewChild('myckeditor') ckeditor: any;



  ngOnInit(): void {
    this.ckeConfig = {
      // extraPlugins: 'uploadimage',
      extraPlugins: 'N1ED-editor',
      skin: 'n1theme,/assets/skins/n1theme/',
      apiKey: "U9VrYpxt",
      allowedContent: true,
      removePlugins: "iframe,magicline",
      Flmngr: {
        apiKey: "U9VrYpxt",
        // urlFileManager: 'https://pathapidev.azurewebsites.net',
        // urlFiles: 'https://pathapidev.azurewebsites.net/api/V1/FuncForACMAppToUploadckeditor?command=QuickUpload&type=Files&responseType=json',
      },
      uploadUrl: environment.basePath + this.apiConstant.IMAGE_UPLOAD_API_FOR_EDITOR,

      // Configure your file manager integration. This example uses CKFinder 3 for PHP.
      filebrowserBrowseUrl:
        'https://ckeditor.com/apps/ckfinder/3.4.5/ckfinder.html',
      filebrowserImageBrowseUrl:
        'https://ckeditor.com/apps/ckfinder/3.4.5/ckfinder.html?type=Images',
        filebrowserUploadUrl: environment.basePath + this.apiConstant.IMAGE_UPLOAD_API_FOR_EDITOR,
        filebrowserImageUploadUrl:environment.basePath + this.apiConstant.IMAGE_UPLOAD_API_FOR_EDITOR,
    };
   
    //new editor code start
    this.commonservice.getEditorData().subscribe((editorData:any) => {
      if(editorData.type == ''){
        this.content = editorData.data;
      }
    })
    //new editor code end


    this.setFontList();


  }
  ngOnChanges(){
    // this.content = this.inputContent;

    //new editor code start
    this.commonservice.getEditorData().subscribe((editorData:any) => {
      if(editorData.type == ''){
        this.content = editorData.data;
      }
    })
     //new editor code end
  }
  add(){
    //old editor code
    // this.editorText.emit(this.content)
    // this.content = this.inputContent;
    //old editor code

    //new editor code start
    this.commonservice.setEditorData({data:this.obj.data, type: this.obj.type})
    //new editor code end
    }
  close(){
    // this.textEditorPopup = false  //commented code

    //old editor code
    //  this.editorText.emit('');
    //old editor code

    //new editor code start
    this.commonservice.setEditorData({data:this.content, type: 'change'})
    //new editor code end
  }

  //new editor code start
  obj:any;
  beforeAdd(event){
    //console.log(event.editor.getData())
    setTimeout(() => {
      this.obj= {data:event.editor.getData(), type: 'change'}
      // this.commonservice.setEditorData({data:event.editor.getData(), type: 'change'})
    } , 100)
  }
  //new editor code end

     ///--------------------------------------Text Editor--------------------///
    //  public Editor = ClassicEditorCustum;
     editorData='false'
     // public config = this.service.getEditorProperties().config;
     public config = {
       toolbar: {
       item: [
         'heading', '|',
         'fontfamily', 'fontsize', '|',
         'fontColor', 'fontBackgroungColor', 'backgroundColor', '|',
         'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
         'link', '|',
         'outdent', 'indent', '|',
         'bulletedList', 'numberedList', 'todoList', '|',
         'code', 'codeBlock', '|',
         'insertTable', '|',
         'imageUpload', 'blockQuote', '|',
         'undo', 'redo'
       ],
       shouldNotGroupWhenFull: true,
       viewportTopOffset:30,
     },
     
     contentToolbar: [
      'tableColumn', 'tableRow', 'mergeTableCells',
      'tableProperties', 'tableCellProperties'
   ],
     language: 'en'
   }

   ngOnDestroy() {
    this.renderer.removeClass(document.body, 'result_processing');
  }
  
  copyPastImplementationFromWord(){
    document.getElementsByTagName('iframe')[0].contentWindow.document.getElementsByTagName('body')[0].setAttribute('id', 'abc')
    let xyz = document.getElementsByTagName('iframe')[0].contentWindow.document.getElementById('abc')
    let aa = xyz.querySelectorAll('[lang]')
    let len = aa.length
    if(len>0){
      for(let i =0; i<len; i++){ aa[i].removeAttribute('lang')}
    }
    // for Image
    var img = xyz.querySelectorAll('img')
    var imgLen = img.length
    for (let i = 0; i < imgLen; i++) {
      img[i].style.maxWidth = '100%';
      img[i].style.height = 'auto';
    }
  }



  setFontList()
  {

   // CKEDITOR.config.fontSize_sizes = '8/8px;9/9px;10/10px;11/11px;12/12px;14/14px;16/16px;18/18px;';

  }

}
