// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,


  basePath:'https://dev-api.pathkind.net', //for pathkind IH dev 
  //basePath:'https://path-labsoul-function-uat.azurewebsites.net',  //QC
  //basePath:'https://pathapiprod01.azurewebsites.net', //PROD 
  
  reportBasePath:'https://uat.lab-soul.com/limslabreport_uat_pkl', //Dev
  //reportBasePath:'https://uat.lab-soul.com/limslabreport_nlt',  //QC
  //reportBasePath:'https://lab-soul.com/labsoul_report', // PROD


  reportvirtualPath:'https://uat.lab-soul.com/TestReport/',

  reportBasePathNew: 'https://pathpatientreportservices.azurewebsites.net/',


  
  newEndpoint: {
      _common:'https://reportapplication.azurewebsites.net',
      _genrateReport: 'https://uat.lab-soul.com/limslabreport_uat_pkl', //'https://uat.lab-soul.com/limslabreport_uat_pkl/',
      _basePath:'https://dev-api.pathkind.net', 
   },


  basePathACM: '/api/V1/',
  campaignIdInbond: '73', //Inbond
  campaignIdInbondOutbond: '73,74', //Inbond and outbond both
  campaignIdOutbond: '74', //Outbond
  version: '1.1.7'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
