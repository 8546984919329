
    <!-- <div [hidden]="!tabShow"> -->
<div [ngClass]="{'slide-out': !tabShow, 'slide-in': tabShow}">
    <div class="vertical-menu">
        <perfect-scrollbar thumbClass="custom-scrollbars">
        <ul>
            <li [ngClass]="{'user-profile':module.displayname=='Profile'}" *ngFor="let module of menuData?.moduleList">
                <a (click)="openMenuTabs(module)" [ngClass]="{'active':selectedModuleGuid==module.moduleGuid}"   pTooltip="{{module.modulename}}" tooltipStyleClass="menu-trim-text" tooltipPosition="right">
                    <span><img *ngIf="module.displayname!='Profile'"  [src]="module.moduleimagepath" alt="">
                        <h3 *ngIf="module.displayname=='Profile'&& !userData.profilepicturepath">{{userData?.profileName}}</h3>
                        <img  *ngIf="module.displayname=='Profile'&& userData.profilepicturepath"  [src]="userData.profilepicturepath">
                    </span>
                   <span  *ngIf="module.displayname!='Profile'">{{module.displayname}}</span> 
                </a>
            </li>      
        </ul>
    </perfect-scrollbar>
    </div>
    
    <ul *ngIf="model" class="layout-menu left-sidebar  layout-main-menu clearfix pad0">
        <perfect-scrollbar thumbClass="custom-scrollbars">
            <p-panelMenu [model]="model"></p-panelMenu>
        </perfect-scrollbar>
    </ul>
</div>
<!-- </div> -->

