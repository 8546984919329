import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor  } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { PathkindService } from '../services/pathkind.service';
import { ToasterService } from '../services/toaster.service';
import { API_PATH } from '../utility/reference/api-constant';

@Injectable({
    'providedIn': 'root'
})
export class AuthInterceptor implements HttpInterceptor{

    timeLeft: any;
    interval: any;
    apiConstant: any = API_PATH;

    constructor(private router: Router, 
      public service: PathkindService,
      private toaster: ToasterService ){
    }
    intercept(request : HttpRequest<any>, next: HttpHandler) : Observable<HttpEvent<any>>{
        if(request && request.url && request.url.indexOf("/api/Home/Index") !== -1 && request.method === "GET"){
            // request = request.clone({
            //    setHeaders: {
            //         'Content-Type':'application/x-www-form-urlencoded',
            //         'Authorization': `Basic ${btoa('odisha-one:'+this.util.getEncryptedValue('vindal'))}`
            //    }
            // });
        }else{
            let sendMail = JSON.parse(sessionStorage.getItem('sendmail'))
            let user = JSON.parse(sessionStorage.getItem('userData'));
            if(user && user.accessToken && !sendMail) {
                request = request.clone({
                    setHeaders: {
                         Authorization:`Bearer ${user.accessToken}`,
                         _client_Ip:'192.3.45.74'  
                    }
                 });
                 if(sessionStorage.getItem('userData')){
                  this.timeLeft = (JSON.parse(sessionStorage.getItem('userData')).data?.uiexpiryseconds) ? (JSON.parse(sessionStorage.getItem('userData')).data?.uiexpiryseconds) : 1200
                  // 
                  this.updateSessionTime();
                }
                //  this.updateSessionTime();
            }
            else if(user && user.accessToken && sendMail){
                request = request.clone({
                    setHeaders: {
                        Authorization:`Bearer ${user.accessToken}`,
                        _client_Ip:'192.3.45.74'  ,
                         api_key : "bfe6e3acbfc65e9a1f763cf4ccf15c61",
                    }
                 });
                 if(sessionStorage.getItem('userData')){
                  this.timeLeft = (JSON.parse(sessionStorage.getItem('userData')).data?.uiexpiryseconds) ? (JSON.parse(sessionStorage.getItem('userData')).data?.uiexpiryseconds) : 1200
                  // 
                  this.updateSessionTime();
                }
                //  this.updateSessionTime();
            }
        }

        // request = request.clone({
        //                 setHeaders: {
        //                      '_authToken':'1234567890',
        //                 }
        //              });
        return next.handle(request);
    }

   count : any = 0;
  startSessionTime() {
      clearInterval(this.interval)
      let time = this.timeLeft
      this.interval = setInterval(() => {
        if(time > 0) {
          time--;
          this.count == 0
        } else if(time == 0){                         // if seesion expired   
          if(this.count == 0){
            this.signOutApiIntegration();
          }
          this.count++;
        }
      },1000)
    }
  
    updateSessionTime() {
        let time = this.timeLeft
      this.startSessionTime();
    }


    // Logout API calling 

    signOutApiIntegration(){
      let request = {
        "Data": {loginstatus: true}
      }
      this.service.postService(this.apiConstant.LOG_OUT, request).subscribe(res => {
        if (res['statusCode'] === "0") {
          this.service.signOut();
          this.toaster.showSuccess(res['statusMessage']);
        } else {
          this.toaster.showError(res['statusMessage']);
        }
      }, error => {
        this.toaster.showError(error?.error?.Message);
      })
    }
}
