import { environment } from 'src/environments/environment';
export const API_PATH: any = {
    'LOGIN_API': environment.basePathACM + 'FuncForHomeAppToValidateLogin',
    'MASTER_DROP_DOWNS': environment.basePathACM + 'FuncForACMAppToGetCommonddl',
    'MASTER_DROP_DOWNS_REDIS': environment.basePathACM + 'FuncForACMAppToGetCommonddlRedis',
    'MENU_GET': environment.basePathACM + 'FuncForUMAppToGetUserMenuPermissionList',
    'COMMON_DDL_LIST': environment.basePathACM + 'FuncForACMAppToGetCommonmasters',
    'COMMON_DDL_LIST_RADIS': environment.basePathACM + 'FuncForACMAppToGetCommonmastersRedis',
    'GET_USER_SALT_API' : environment.basePathACM + 'FuncForHomeAppToGetUserKey',


    //DROPDOWN MASTER LIST
    'MASTER_DROP_DOWN_LIST': environment.basePathACM + 'FuncForACMAppToGetCommonddllist',



    //--- Test Module End Point  -------------------------------//

    //TEST
    'TEST_TEST_SEARCH': environment.basePathACM + 'FuncForACMAppToSearchTest',
    'TEST_COMMON_DROP_DOWNS': environment.basePathACM + 'FuncForACMAppToGetCommonmasters',
    'TEST_BASIC_INFO_ADD': environment.basePathACM + 'FuncForACMAppToADDTestBasicInfo',
    'TEST_BASIC_INFO_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateTestBasicInfo',
    'TEST_DRAFT_SEARCH': environment.basePathACM + 'FuncForACMAppToSearchTestDraft',
    'TEST_ELEMENT_DROPDOWN_LIST_GET': environment.basePathACM + 'FuncForACMAppToGetCommonmasters',
    'TEST_ELEMENT_ADD': environment.basePathACM + 'FuncForACMAppToADDTestElement',
    'TEST_MASTER_DETAILS_GET': environment.basePathACM + 'FuncForACMAppToGetTest',
    'TEST_STATUS_UPDATE': environment.basePathACM + 'FuncForACMAppToStatusUpdateTest',
    'TEST_ELEMENT_LIST': environment.basePathACM + 'FuncForACMAppToGetElementSampleDDL',
    'TEST_LOG_DETAIL': environment.basePathACM + 'FuncForACMAppToLogSearchTest',
    //TEST PROFILE
    'TEST_PROFILE_SEARCH': environment.basePathACM + 'FuncForTMAppToSearchProfile',
    'TEST_PROFILE_DRAFT_SEARCH': environment.basePathACM + 'FuncForTMAppToSearchDraftProfile',
    'TEST_PROFILE_UPDATE': environment.basePathACM + 'FuncForTMAppToStatusUpdateProfile',
    'TEST_PROFILE_GET': environment.basePathACM + 'FuncForTMAppToGetProfile',
    'TEST_PROFILE_ADD': environment.basePathACM + 'FuncForTMAppToAddProfile',
    'TEST_PROFILE_ADD_TEST': environment.basePathACM + 'FuncForTMAppToAddProfileTest',
    'TEST_CLIENT_GET': environment.basePathACM + 'FuncForTMAppToGetClient',
    // 'TEST_PROFILE_GET_DATA': environment.basePathACM + 'FuncForTMAppToGetProTest',
    'TEST_PROFILE_UPDATE_DATA': environment.basePathACM + 'FuncForTMAppToUpdateProfile',
    'TEST_PROFILE_TEST_NAME_SEARCH': environment.basePathACM + 'FuncForTMAppToGetProTest',
    'TEST_PROFILE_VALID_TEST_NAME_SEARCH': environment.basePathACM + 'FuncForTMAppToGetvalidProTest',
    'TEST_PROFILE__SEARCH_LOG_DETAIL': environment.basePathACM + 'FuncForTMAppToSearchProfilelog',
    'TEST_PROFILE_GET_LOG_DETAIL': environment.basePathACM + 'FuncForTMAppToGetProfilelog',
  

    //FORGOT_PASSWORD
    'FORGOT_PASSWORD': environment.basePathACM + 'FuncForHomeAppToSendOtp',
    'RESEND_OTP': environment.basePathACM + 'FuncForHomeAppToReSendOtp',
    'VERIFY_OTP': environment.basePathACM + 'FuncForHomeAppToVerifyOtp',
    'RESET_PASSWORD': environment.basePathACM + 'FuncForHomeAppToResetPassword',
    //Test_ELEMENT
    'TEST_GET_ELEMENT_TYPE_LIST': environment.basePathACM + 'FuncForACMAppToGetElementSampleDDL',
    'TEST_DEL_ELEMENT_SAMPLE_DDL': environment.basePathACM + 'FuncForACMAppToDelElementSampleDDL',
    'TEST_PERFORMING_CENTER_SEARCH': environment.basePathACM + 'FuncForACMAppToSearchTestCenter',
    'TEST_ELEMENT_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateTestElement',
    'TEST_PERFORMING_LOC_ADD': environment.basePathACM + 'FuncForACMAppToAddTestPerformingLoc',
    'TEST_PERFORMING_LOC_ADD_UPDATE': environment.basePathACM + 'FuncForACMAppToAddTestDaysPerformingLoc',
    'TEST_BOOKING_LOCATION_SEARCH': environment.basePathACM + 'FuncForACMAppToSearchTestBookingLoc',
    'TEST_BOOKING_LOCATION_ADD_UPDATE': environment.basePathACM + 'FuncForACMAppToAddTestBookingLoc',
    'TEST_INTERPRETATION_ADD_UPDATE': environment.basePathACM + 'FuncForACMAppToAddTestInterpretation',
    'UPDATE_INTERPRETATION': environment.basePathACM + 'FuncForACMAppToUpdateClientPrintInterpretation',
    'GET_INTERPRETATION': environment.basePathACM + 'FuncForACMAppToGetClientPrintInterpretation',
    'TEST_OTHER_INFO_ADD_UPDATE': environment.basePathACM + 'FuncForACMAppToAddTestOtherInfo',
    'TEST_PERFORRMING_GET_TIME_SLOT': environment.basePathACM + 'FuncForACMAppToGetTestPerfSlotList',
    'TEST_PERFORMING_DELETE_TIME_SLOT': environment.basePathACM + 'FuncForACMAppToDeleteTestDaysPerformingLoc',
    // ORGANISM MASTER
    'TEST_MASTER_ORGANISM_SEARCH': environment.basePathACM + 'FuncForTMAppToOrganismSearch',
    'TEST_MASTER_ORGANISM_STATUS_UPDATE': environment.basePathACM + 'FuncForTMAppToStatusUpdateOrganism',
    'TEST_MASTER_ORGANISM_DETAILS_GET': environment.basePathACM + 'FuncForTMAppToGetOrganism',
    'TEST_MASTER_ORGANISM_DETAILS_ADD': environment.basePathACM + 'FuncForTMAppToAddOrganism',
    'TEST_MASTER_ORGANISM_DETAILS_UPDATE': environment.basePathACM + 'FuncForTMAppToUpdateOrganism',
  // ORGANISM MASTER
  'TEST_MASTER_ANTIBIOTIC_SEARCH': environment.basePathACM + 'FuncForTMAppToAntibioticSearch',
  'TEST_MASTER_ANTIBIOTIC_STATUS_UPDATE': environment.basePathACM + 'FuncForTMAppToStatusUpdateAntibiotic',
  'TEST_MASTER_ANTIBIOTIC_DETAILS_GET': environment.basePathACM + 'FuncForTMAppToGetAntibiotic',
  'TEST_MASTER_ANTIBIOTIC_DETAILS_ADD': environment.basePathACM + 'FuncForTMAppToAddAntibiotic',
  'TEST_MASTER_ANTIBIOTIC_DETAILS_UPDATE': environment.basePathACM + 'FuncForTMAppToUpdateAntibiotic',






    //-----------User Module End Points ---------------------------------------------------
    //MODULE
    'USER_MODULE_SEARCH': environment.basePathACM + 'FuncForUMAppToModuleSearch',
    'MODULE_ADD': environment.basePathACM + 'FuncForUMAppToAddModule',
    'MODULE_GET': environment.basePathACM + 'FuncForUMAppToGetModule',
    'MODULE_UPDATE': environment.basePathACM + 'FuncForUMAppToUpdateModule',
    'MODULE_STATUS_UPDATE': environment.basePathACM + 'FuncForUMAppToStatusUpdateModule',

    //MENU MANAGEMENT
    'USER_MENUMANAGEMENT_SEARCH': environment.basePathACM + 'FuncForUMAppToSearchMenu',
    'MENUMANAGEMENT_ADD': environment.basePathACM + 'FuncForUMAppToAddMenu',
    'MENUMANAGEMENT_GET': environment.basePathACM + 'FuncForUMAppToGetMenu',
    'MENUMANAGEMENT_UPDATE': environment.basePathACM + 'FuncForUMAppToUpdateMenu',
    'MENUMANAGEMENT_STATUS_UPDATE': environment.basePathACM + 'FuncForUMAppToStatusUpdateMenu',

    // MENU TYPE
    'USER_MENU_TYPE_SEARCH': environment.basePathACM + 'FuncForUMAppToMenutypeSearch',
    'MENU_TYPE_ADD': environment.basePathACM + 'FuncForUMAppToAddMenutype',
    'MENU_TYPE_GET': environment.basePathACM + 'FuncForUMAppToGetMenutype',
    'MENU_TYPE_UPDATE': environment.basePathACM + 'FuncForUMAppToUpdateMenutype',
    'MENU_TYPE_STATUS_UPDATE': environment.basePathACM + 'FuncForUMAppToStatusUpdateMenutype',

    //ROLE GROUP
    'USER_ROLE_GROUP_SEARCH': environment.basePathACM + 'FuncForUMAppToSearchRoleGroup',
    'ROLE_GROUP_ADD': environment.basePathACM + 'FuncForUMAppToAddRoleGroup',
    'ROLE_GROUP_GET': environment.basePathACM + 'FuncForUMAppToGetRoleGroup',
    'ROLE_GROUP_UPDATE': environment.basePathACM + 'FuncForUMAppToUpdateRoleGroup',
    'ROLE_GROUP_STATUS_UPDATE': environment.basePathACM + 'FuncForUMAppToStatusUpdateRoleGroup',

    //ROLE_SETUP
    'USER_ROLE_SEARCH': environment.basePathACM + 'FuncForUMAppToRoleSearch',
    'ROLE_ADD': environment.basePathACM + 'FuncForUMAppToAddRole',
    'ROLE_GET': environment.basePathACM + 'FuncForUMAppToGetRole',
    'ROLE_UPDATE': environment.basePathACM + 'FuncForUMAppToUpdateRole',
    'ROLE_STATUS_UPDATE': environment.basePathACM + 'FuncForUMAppToStatusUpdateRole',

    //ROLE_RIGHTS
    'ROLE_RIGHT_ADD': environment.basePathACM + 'FuncForUMAppToAddRoleRights',
    'ROLE_RIGHT_GET': environment.basePathACM + 'FuncForUMAppToGetRoleRights',
    //AUDIT_TRIAL_ROLE
    'AUDIT_TRAIL_GET': environment.basePathACM + 'FuncForUMAppToSearchAuditTrail',
    'USER_ROLE_LIST': environment.basePathACM + 'FuncForUMAppToGetRoleList',
    'AUDIT_TRAIL_GET_EXPORT_EXCEL': environment.basePathACM + 'FuncForUMAppToSearchAuditTrailExportExl',
    //GET_PROFILE
    'GET_USER_PROFILE': environment.basePathACM + 'FuncForUMAppToGetProfile',
    'UPDATE_USER_PROFILE': environment.basePathACM + 'FuncForUMAppToUpdateProfile',
    //CHANGE_PASSWORD
    'CHANGE_PASSWORD': environment.basePathACM + 'FuncForUMAppToUpdateChangePassword',

    //USER_REGISTRATION
    'USER_REG_SEARCH': environment.basePathACM + 'FuncForUMAppToUserSearch',
    'USER_REG_ADD': environment.basePathACM + 'FuncForUMAppToAddUser',
    'USER_REG_GET': environment.basePathACM + 'FuncForUMAppToGetUser',
    'USER_REG_UPDATE': environment.basePathACM + 'FuncForUMAppToUpdateUser',
    'USER_REG_STATUS_UPDATE': environment.basePathACM + 'FuncForUMAppToStatusUpdateUser',
    'USER_REG_ROLE_GET': environment.basePathACM + 'FuncForUMAppToGetRolelist',
    'USER_REG_MANAGER_GET': environment.basePathACM + 'FuncForUMAppToGetManagerlist',
    'USER_REG_CLIENT_CENTER_GET': environment.basePathACM + 'FuncForUMAppToGetClientCenterlist',
    'USER_REG_EXIST_USER_GET': environment.basePathACM + 'FuncForUMAppToExistUser',
    'USER_REG_LAB_DEPT_GET': environment.basePathACM + 'FuncForUMAppToGetLabuserdepartment',
    'USER_REG_LAB_DEPT_ADD': environment.basePathACM + 'FuncForUMAppToAddLabuserdepartment',
    'USER_REG_ZONE_MAP_GET': environment.basePathACM + 'FuncForUMAppToGetUserzonemap',
    'USER_REG_ZONE_MAP_ADD': environment.basePathACM + 'FuncForUMAppToAddUserzonemap',
    'USER_REG_MAP_OUTLET_DROPDOWN': environment.basePathACM + 'FuncForACMAppToGetCommonmasters',
    'USER_REG_MAP_OUTLET_ADD_UPDATE': environment.basePathACM + 'FuncForUMAppToAddUsermapoutlet',
    'USER_REG_MAP_OUTLET_GET': environment.basePathACM + 'FuncForUMAppToGetUsermapoutlet',
    'USER_REG_LAB_MULTY_GET': environment.basePathACM + 'FuncForUMAppToGetuserMultyLab',
    'USER_REG_LAB_MULTY_ADD': environment.basePathACM + 'FuncForUMAppToAdduserMultyLab',
    'USER_REG_SIGNINGAUTHORITY_LIST': environment.basePathACM + 'FuncForUMAppToGetsignigauthoritylist',
    'USER_REG_SIGNINGAUTHORITY_DEPARTMENT_LIST': environment.basePathACM + 'FuncForUMAppToGetsignigauthoritydeptlist',
    'USER_REG_AREA_MAP_GET': environment.basePathACM + 'FuncForUMAppToGetUserareamap',
    'USER_REG_AREA_MAP_ADD': environment.basePathACM + 'FuncForUMAppToAddUserareamap',

    //USER RIGHT
    'USER_RIGHT_ADD': environment.basePathACM + 'FuncForUMAppToAddUserRights',
    'USER_RIGHT_GET': environment.basePathACM + 'FuncForUMAppToGetUserRights',
    'USER_USERLIST_GET': environment.basePathACM + 'FuncForUMAppToGetUserlist',

    // Home Collection
    'HOME_COLLECTION_GET': environment.basePathACM + 'FuncForACMAppToGetHomecolchargeSearch',
    'HOME_COLLECTION_SAVE': environment.basePathACM + 'FuncForACMAppToAddHomecolcharge',
    //-----------Master Module End Points ---------------------------------------------------

    // Client_Type_Master APIs
    'CLIENT_TYPE_MASTER_SEARCH': environment.basePathACM + 'FuncForACMAppToClientTypeSearch',
    'CLIENT_TYPE_MASTER_ADD': environment.basePathACM + 'FuncForACMAppToAddClientType',
    'CLIENT_TYPE_MASTER_GET': environment.basePathACM + 'FuncForACMAppToGetClientType',
    'CLIENT_TYPE_MASTER_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateClientType',
    'CLIENT_TYPE_STATUS_UPDATE': environment.basePathACM + 'FuncForACMAppToStatusUpdateClientType',
    'CLIENT_TYPE_GET_PRICING_SLAB': environment.basePathACM + 'FuncForACMAppToGetIspricingSlab',
    'CLIENT_TYPE_SEARCH_LOG_DETAIL': environment.basePathACM + 'FuncForACMAppToClientTypeLogSearch',
    'CLIENT_TYPE_GET_LOG_DETAIL': environment.basePathACM + 'FuncForACMAppToGetClientTypeLog ',
   


    // Department APIs
    'MASTER_DEPARTMENT_SEARCH': environment.basePathACM + 'FuncForACMAppToSearchDepartmentMstDtls',
    'DEPARTMENT_ADD': environment.basePathACM + 'FuncForACMAppToAddDepartmentMstDtls',
    'DEPARTMENT_GET': environment.basePathACM + 'FuncForACMAppToGetDepartmentMstDtls',
    'DEPARTMENT_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateDepartmentMstDtls',
    'DEPARTMENT_STATUS_UPDATE': environment.basePathACM + 'FuncForACMAppToStatusUpdateDepartmentMstDtls',


    // Divsion APIs
    'DIVISON_SEARCH': environment.basePathACM + 'FuncForACMAppToSearchDivision',
    'DIVISION_ADD': environment.basePathACM + 'FuncForACMAppToAddDivision',
    'DIVISION_GET': environment.basePathACM + 'FuncForACMAppToGetDivision',
    'DIVISION_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateDivision',
    'DIVISION_STATUS_UPDATE': environment.basePathACM + 'FuncForACMAppToStatusUpdateDivision',

    // Price List APIs
    'CENTRE_LIST_GET': environment.basePathACM + 'FuncForACMAppToGetPriceList',
    'PRICE_LIST_ADD': environment.basePathACM + 'FuncForACMAppToAddPriceList',
    'PRICE_LIST_PASTE': environment.basePathACM + 'FuncForACMAppToPastePriceList',
    'PRICE_LIST_GET': environment.basePathACM + 'FuncForACMAppToGetAllPriceList',
    'PRICE_LIST_DETAIL_GET': environment.basePathACM + 'FuncForACMAppToGetTestPriceList',
    'PRICE_LIST_HISTORY_GET': environment.basePathACM + 'FuncForACMAppToGetTestPricehist',
    'PRICE_LIST_HISTORY_ALL': environment.basePathACM + 'FuncForACMAppToGetPricehist',
    'PRICE_LIST_UPDATE_TEST_MRP': environment.basePathACM + 'FuncForACMAppToUpdateTestMRP',

        // Fast Moving Price List APIs
        'FAST_MOVING_PRICE_LIST_DETAIL_GET': environment.basePathACM + 'FuncForACMAppToGetFastMovingPriceList',
        'FAST_MOVING_PRICE_LIST_GET_LOG_DETAIL': environment.basePathACM + 'FuncForACMAppToGetFastMovingPriceListlog',

          // Add Fast Moving Price List APIs
          'ADD_FAST_MOVING_PRICE_LIST': environment.basePathACM + 'FuncForACMAppToAddFastMovingPriceList',

    // Routes Master APIs
    'ROUTES_OUTLET_NAMES_GET': environment.basePathACM + 'FuncForACMAppToGetPriceList',
    'ROUTES_OUTLET_GET_LAT_LNG': environment.basePathACM + 'FuncForACMAppToGetRouteslongitudelatitude',
    'ROUTES_ROUTE_NAME_SEARCH': environment.basePathACM + 'FuncForACMAppToRoutesSearch',
    'ROUTES_GET_TOTAL_DISTANCE': environment.basePathACM + 'FuncForACMAppToGetRoutestotaldistance',
    'ROUTES_ADD_ROUTE': environment.basePathACM + 'FuncForACMAppToAddRoutes',
    'ROUTES_ROUTE_GET': environment.basePathACM + 'FuncForACMAppToGetRoutes',
    'ROUTES_ROUTE_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateRoutes',
    'ROUTES_STATUS_UPDATE': environment.basePathACM + 'FuncForACMAppToStatusUpdateRoutes',


    // BOM APIs
    'BOM_SEARCH': environment.basePathACM + 'FuncForACMAppToSearchBomMasterDetails',
    'BOM_ADD': environment.basePathACM + 'FuncForACMAppToSaveBomMasterDetails',
    'BOM_GET': environment.basePathACM + 'FuncForACMAppToGetBomMasterDetails',
    'BOM_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateBomMasterDetails',
    'BOM_STATUS_UPDATE': environment.basePathACM + 'FuncForACMAppToStatusUpdateBomMasterDetails',



    // WORKSTATION APIs
    'WORKSTATION_SEARCH': environment.basePathACM + 'FuncForACMAppToWorkStationSearch',
    'WORKSTATION_ADD': environment.basePathACM + 'FuncForACMAppToAddWorkStation',
    'WORKSTATION_GET': environment.basePathACM + 'FuncForACMAppToGetWorkStation',
    'WORKSTATION_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateWorkStation',
    'WORKSTATION_DEPARTMENT_GET': environment.basePathACM + 'FuncForACMAppToGetCommonddl',
    'WORKSTATION_UPDATE_STATUS': environment.basePathACM + 'FuncForACMAppToStatusUpdateWorkStation',

    // UNITMASTER APIs
    'UNITMASTER_SEARCH': environment.basePathACM + 'FuncForACMAppToSearchUnitMst',
    'UNITMASTER_ADD': environment.basePathACM + 'FuncForACMAppToAddUnitMst',
    'UNITMASTER_GET': environment.basePathACM + 'FuncForACMAppToGetUnitMst',
    'UNITMASTER_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateUnitMst',
    'UNITMASTER_UPDATE_STATUS': environment.basePathACM + 'FuncForACMAppToStatusUpdateUnitMst',

    // INSTRUMENT APIs
    'INSTRUMENT_SEARCH': environment.basePathACM + 'FuncForACMAppToSearchInstrumentMst',
    'INSTRUMENT_UPDATE_STATUS': environment.basePathACM + 'FuncForACMAppToStatusUpdateInstrumentMst',
    'INSTRUMENT_ADD': environment.basePathACM + 'FuncForACMAppToAddInstrumentMst',
    'INSTRUMENT_GET': environment.basePathACM + 'FuncForACMAppToGetInstrumentMst',
    'INSTRUMENT_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateInstrumentMst',

    //Result-Type APIs
    'RESULT_TYPE_SEARCH': environment.basePathACM + 'FuncForACMAppToSearchResultType',
    'RESULT_TYPE_ADD': environment.basePathACM + 'FuncForACMAppToAddResultType',
    'RESULT_TYPE_GET': environment.basePathACM + 'FuncForACMAppToGetResultType',
    'RESULT_TYPE_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateResultType',
    'RESULT_TYPE_UPDATE_STATUS': environment.basePathACM + 'FuncForACMAppToStatusUpdateResultType',

    'ORGAN_SEARCH': environment.basePathACM + 'FuncForACMAppToOrganSearch',
    'ORGAN_ADD': environment.basePathACM + 'FuncForACMAppToAddOrgan',
    'ORGAN_GET': environment.basePathACM + 'FuncForACMAppToGetOrgan',
    'ORGAN_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateOrgan',
    'ORGAN_UPDATE_STATUS': environment.basePathACM + 'FuncForACMAppToStatusUpdateOrgan',

    // METHODMASTER APIs
    'METHODMASTER_SEARCH': environment.basePathACM + 'FuncForACMAppToMethodSearch',
    'METHODMASTER_ADD': environment.basePathACM + 'FuncForACMAppToAddMethod',
    'METHODMASTER_GET': environment.basePathACM + 'FuncForACMAppToGetMethod',
    'METHODMASTER_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateMethod',
    'METHODMASTER_UPDATE_STATUS': environment.basePathACM + 'FuncForACMAppToStatusUpdateMethod',

    // CODES APIs
    'CODES_SEARCH': environment.basePathACM + 'FuncForACMAppToCodesSearch',
    'CODES_ADD': environment.basePathACM + 'FuncForACMAppToAddCodes',
    'CODES_GET': environment.basePathACM + 'FuncForACMAppToGetCodes',
    'CODES_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateCodes',
    'CODES_UPDATE_STATUS': environment.basePathACM + 'FuncForACMAppToStatusUpdateCodes',

    // SAMPLE TYPE APIs
    'SAMPLETYPE_SEARCH': environment.basePathACM + 'FuncForACMAppToSampletypeSearch',
    'SAMPLETYPE_ADD': environment.basePathACM + 'FuncForACMAppToAddSampletype',
    'SAMPLETYPE_GET': environment.basePathACM + 'FuncForACMAppToGetSampletype',
    'SAMPLETYPE_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateSampletype',
    'SAMPLETYPE_UPDATE_STATUS': environment.basePathACM + 'FuncForACMAppToStatusUpdateSampletype',

    //DISEASE APIs
    'MASTER_DISEASE_SEARCH': environment.basePathACM + 'FuncForACMAppToDiseaseSearch',
    'DISEASE_ADD': environment.basePathACM + 'FuncForACMAppToAddDisease',
    'DISEASE_GET': environment.basePathACM + 'FuncForACMAppToGetDisease',
    'DISEASE_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateDisease',
    'DISEASE_STATUS_UPDATE': environment.basePathACM + 'FuncForACMAppToStatusUpdateDisease',

    //LIFE_STYLE
    'MASTER_LIFESTYLE_SEARCH': environment.basePathACM + 'FuncForACMAppToSearchLifestyle',
    'LIFESTYLE_ADD': environment.basePathACM + 'FuncForACMAppToAddLifestyle',
    'LIFESTYLE_GET': environment.basePathACM + 'FuncForACMAppToGetLifestyle',
    'LIFESTYLE_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateLifestyle',
    'LIFESTYLE_STATUS_UPDATE': environment.basePathACM + 'FuncForACMAppToStatusUpdateLifestyle',
    'SAMPLETYPE_SAMPLECATEGORY_GET': environment.basePathACM + 'FuncForACMAppToGetCommonddl',
    'SAMPLETYPE_UNITCATEGORY_GET': environment.basePathACM + 'FuncForACMAppToGetCommonddl',


    // ACCREDITATION APIs
    'ACCREDITATION_SEARCH': environment.basePathACM + 'FuncForACMAppToAccreditationSearch',
    'ACCREDITATION_ADD': environment.basePathACM + 'FuncForACMAppToAddAccreditation',
    'ACCREDITATION_GET': environment.basePathACM + 'FuncForACMAppToGetAccreditation',
    'ACCREDITATION_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateAccreditation',
    'ACCREDITATION_UPDATE_STATUS': environment.basePathACM + 'FuncForACMAppToStatusUpdateAccreditation',

    // GENDER APIs
    'GENDER_SEARCH': environment.basePathACM + 'FuncForACMAppToSearchGender',
    'GENDER_ADD': environment.basePathACM + 'FuncForACMAppToAddGender',
    'GENDER_GET': environment.basePathACM + 'FuncForACMAppToGetGender',
    'GENDER_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateGender',
    'GENDER_UPDATE_STATUS': environment.basePathACM + 'FuncForACMAppToStatusUpdateGender',

    //SOFTCOPY
    'MASTER_SOFTCOPY_SEARCH': environment.basePathACM + 'FuncForACMAppToSoftCopyrpttypeSearch',
    'SOFTCOPY_ADD': environment.basePathACM + 'FuncForACMAppToAddSoftCopyrpttype',
    'SOFTCOPY_GET': environment.basePathACM + 'FuncForACMAppToGetSoftCopyrpttype',
    'SOFTCOPY_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateSoftCopyrpttype',
    'SOFTCOPY_STATUS_UPDATE': environment.basePathACM + 'FuncForACMAppToStatusUpdateSoftCopyrpttype',

    //REPORTING TYPE
    'MASTER_REPORTING_SEARCH': environment.basePathACM + 'FuncForACMAppToReportingtypeSearch',
    'MASTER_REPORTING_ADD': environment.basePathACM + 'FuncForACMAppToAddReportingtype',
    'MASTER_REPORTING_GET': environment.basePathACM + 'FuncForACMAppToGetReportingtype',
    'MASTER_REPORTING_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateReportingtype',
    'MASTER_REPORTING_STATUS_UPDATE': environment.basePathACM + 'FuncForACMAppToStatusUpdateReportingtype',

    //SPECIALLITY_TEST
    'MASTER_SPECIALLITY_TEST_SEARCH': environment.basePathACM + 'FuncForACMAppToSearchSpeciality',
    'SPECIALLITY_TEST_ADD': environment.basePathACM + 'FuncForACMAppToAddSpeciality',
    'SPECIALLITY_TEST_GET': environment.basePathACM + 'FuncForACMAppToGetSpeciality',
    'SPECIALLITY_TEST_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateSpeciality',
    'SPECIALLITY_TEST_STATUS_UPDATE': environment.basePathACM + 'FuncForACMAppToStatusUpdateSpeciality',

    // TESTCATEGORY APIs
    'TESTCATEGORY_SEARCH': environment.basePathACM + 'FuncForACMAppToSearchTestCategory',
    'TESTCATEGORY_ADD': environment.basePathACM + 'FuncForACMAppToAddTestCategory',
    'TESTCATEGORY_GET': environment.basePathACM + 'FuncForACMAppToGetTestCategory',
    'TESTCATEGORY_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateTestCategory',
    'TESTCATEGORY_UPDATE_STATUS': environment.basePathACM + 'FuncForACMAppToStatusUpdateTestCategory',


    // TESTCATEGORY APIs
    'CURRENCY_SEARCH': environment.basePathACM + 'FuncForACMAppToSearchCurrency',
    'CURRENCY_ADD': environment.basePathACM + 'FuncForACMAppToAddCurrency',
    'CURRENCY_GET': environment.basePathACM + 'FuncForACMAppToGetCurrency',
    'CURRENCY_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateCurrency',
    'CURRENCY_UPDATE_STATUS': environment.basePathACM + 'FuncForACMAppToStatusUpdateCurrency',

    //COUNTRY APIs
    'MASTER_COUNTRY_SEARCH': environment.basePathACM + 'FuncForACMAppToSearchCountry',
    'COUNTRY_ADD': environment.basePathACM + 'FuncForACMAppToAddCountry',
    'COUNTRY_GET': environment.basePathACM + 'FuncForACMAppToGetCountry',
    'COUNTRY_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateCountry',
    'COUNTRY_STATUS_UPDATE': environment.basePathACM + 'FuncForACMAppToStatusUpdateCountry',
    'COUNTRY_UPLOAD_EXCEL': environment.basePathACM + 'FuncForACMAppToCountryExcelUpload',

    // CLUSTER APIs
    'CLUSTER_SEARCH': environment.basePathACM + 'FuncForACMAppToClusterSearch',
    'CLUSTER_ADD': environment.basePathACM + 'FuncForACMAppToAddCluster',
    'CLUSTER_GET': environment.basePathACM + 'FuncForACMAppToGetCluster',
    'CLUSTER_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateCluster',
    'CLUSTER_UPDATE_STATUS': environment.basePathACM + 'FuncForACMAppToStatusUpdateCluster',
    'CLUSTER_COMMON_DROP_DOWNS': environment.basePathACM + 'FuncForACMAppToGetCommonmasters',

    // SOP APIs
    'SOP_SEARCH': environment.basePathACM + 'FuncForACMAppToSopSearch',
    'SOP_ADD': environment.basePathACM + 'FuncForACMAppToAddSop',
    'SOP_GET': environment.basePathACM + 'FuncForACMAppToGetSop',
    'SOP_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateSop',
    'SOP_UPDATE_STATUS': environment.basePathACM + 'FuncForACMAppToStatusUpdateSop',

    // STATE APIs
    'MASTER_STATE_SEARCH': environment.basePathACM + 'FuncForACMAppToSearchState',
    'STATE_ADD': environment.basePathACM + 'FuncForACMAppToAddState',
    'STATE_GET': environment.basePathACM + 'FuncForACMAppToGetState',
    'STATE_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateState',
    'STATE_STATUS_UPDATE': environment.basePathACM + 'FuncForACMAppToStatusUpdateState',
    'STATE_UPLOAD_EXCEL': environment.basePathACM + 'FuncForACMAppToStateExcelUpload',

    // REFFERING_DOCTOR APIs
    'REFFERING_DOCTOR_SEARCH': environment.basePathACM + 'FuncForACMAppToSearchRefDoctor',
    'REFFERING_DOCTOR_ADD_BASIC_DETAIL': environment.basePathACM + 'FuncForACMAppToAddRefDoctor',
    'REFFERING_DOCTOR_ADD_OTHER_DETAIL': environment.basePathACM + 'FuncForACMAppToAddOtherInfoRefDoctor',
    'REFFERING_DOCTOR_GET': environment.basePathACM + 'FuncForACMAppToGetRefDoctor',
    'REFFERING_DOCTOR_UPDATE_BASIC_DETAIL': environment.basePathACM + 'FuncForACMAppToUpdateRefDoctor',
    'REFFERING_DOCTOR_UPDATE_OTHER_DETAIL': environment.basePathACM + 'FuncForACMAppToUpdateOtherInfoRefDoctor',
    'REFFERING_DOCTOR_UPDATE_STATUS': environment.basePathACM + 'FuncForACMAppToStatusUpdateRefDoctor',
    // DISTRICT APIs
    'MASTER_DISTRICT_SEARCH': environment.basePathACM + 'FuncForACMAppToSearchDistrict',
    'DISTRICT_ADD': environment.basePathACM + 'FuncForACMAppToAddDistrict',
    'DISTRICT_GET': environment.basePathACM + 'FuncForACMAppToGetDistrict',
    'DISTRICT_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateDistrict',
    'DISTRICT_STATUS_UPDATE': environment.basePathACM + 'FuncForACMAppToStatusUpdateDistrict',
    'DISTRICT_UPLOAD_EXCEL': environment.basePathACM + 'FuncForACMAppToDistrictExcelUpload',

    // CITY APIs
    'MASTER_CITY_SEARCH': environment.basePathACM + 'FuncForACMAppToSearchCity',
    'CITY_ADD': environment.basePathACM + 'FuncForACMAppToAddCity',
    'CITY_GET': environment.basePathACM + 'FuncForACMAppToGetCity',
    'CITY_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateCity',
    'CITY_STATUS_UPDATE': environment.basePathACM + 'FuncForACMAppToStatusUpdateCity',
    'CITY_UPLOAD_EXCEL': environment.basePathACM + 'FuncForACMAppToCityExcelUpload',

    // CONSENTFORM APIs
    'MASTER_CONSENTFORM_SEARCH': environment.basePathACM + 'FuncForACMAppToSearchConsentForm',
    'CONSENTFORM_ADD': environment.basePathACM + 'FuncForACMAppToAddConsentForm',
    'CONSENTFORM_GET': environment.basePathACM + 'FuncForACMAppToGetConsentForm',
    'CONSENTFORM_DOC_GET': environment.basePathACM + 'FuncForACMAppToGetConsentFormDoc',
    'CONSENTFORM_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateConsentForm',
    'CONSENTFORM_STATUS_UPDATE': environment.basePathACM + 'FuncForACMAppToStatusUpdateConsentForm',

    // HISTORYFORM APIs
    'MASTER_HISTORYFORM_SEARCH': environment.basePathACM + 'FuncForACMAppToSearchHistoryForm',
    'HISTORYFORM_ADD': environment.basePathACM + 'FuncForACMAppToAddHistoryForm',
    'HISTORYFORM_GET': environment.basePathACM + 'FuncForACMAppToGetHistoryForm',
    'HISTORYFORM_DOC_GET': environment.basePathACM + 'FuncForACMAppToGetHistoryFormDoc',
    'HISTORYFORM_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateHistoryForm',
    'HISTORYFORM_STATUS_UPDATE': environment.basePathACM + 'FuncForACMAppToStatusUpdateHistoryForm',

    //UPLOAD DOCUMENT
    'UPLOAD_DOCUMENT': environment.basePathACM + 'FuncForACMAppToUploadDocument',

    //LAB TYPE
    'MASTER_LABTYPE_SEARCH': environment.basePathACM + 'FuncForACMAppToSearchLabType',
    'LABTYPE_ADD': environment.basePathACM + 'FuncForACMAppToAddLabType',
    'LABTYPE_GET': environment.basePathACM + 'FuncForACMAppToGetLabType',
    'LABTYPE_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateLabType',
    'LABTYPE_STATUS_UPDATE': environment.basePathACM + 'FuncForACMAppToStatusUpdateLabType',
    'ADD_INSDEPT': environment.basePathACM + 'FuncForACMAppToAddCenterdeptinstrument',
    // BILLING TYPE
    'MASTER_BILLINGTYPE_SEARCH': environment.basePathACM + 'FuncForACMAppToBillingcodeSearch',
    'BILLINGTYPE_ADD': environment.basePathACM + 'FuncForACMAppToAddBillingcode',
    'BILLINGTYPE_GET': environment.basePathACM + 'FuncForACMAppToGetBillingcode',
    'GET_CLIENT_TYPE_LIST': environment.basePathACM + 'FuncForACMAppToGetBillingcodeClienttypeList',
    'BILLINGTYPE_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateBillingcode',
    'BILLINGTYPE_STATUS_UPDATE': environment.basePathACM + 'FuncForACMAppToStatusUpdateBillingcode',

    //APPROVAL APIS
    'ADD_APPROVAL': environment.basePathACM + 'FuncForUMAppToAddApprovalManagement',
    'SEARCH_APPROVAL': environment.basePathACM + 'FuncForUMAppToSearchApprovalManagement',
    'APPROVAL_STATUS_UPDATE': environment.basePathACM + 'FuncForUMAppToStatusUpdateApprovalManagement',
    'APPROVAL_GET': environment.basePathACM + 'FuncForUMAppToGetApprovalManagement',
    'APPROVAL_UPDATE': environment.basePathACM + 'FuncForUMAppToUpdateApprovalManagement',


    'PRIVILEDGE_ADD': environment.basePathACM + 'FuncForACMAppToAddPrivilegecardtype',
    'PRIVILEDGE_SEARCH': environment.basePathACM + 'FuncForACMAppToPrivilegecardtypeSearch',
    'PRIVILEDGE_STATUS_UPDATE': environment.basePathACM + 'FuncForACMAppToStatusUpdatePrivilegecardtype',
    'PRIVILEDGE_GET': environment.basePathACM + 'FuncForACMAppToGetPrivilegecardtype',
    'PRIVILEDGE_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdatePrivilegecardtype',


    //TNP REASON
    'TNP_TYPE_SEARCH': environment.basePathACM + 'FuncForACMAppToSearchtnpreason',
    'TNP_TYPE_ADD': environment.basePathACM + 'FuncForACMAppToAddtnpreason',
    'TNP_TYPE_GET': environment.basePathACM + 'FuncForACMAppToGettnpreason',
    'TNP_TYPE_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdatetnpreason',
    'TNP_TYPE_UPDATE_STATUS': environment.basePathACM + 'FuncForACMAppToStatusUpdatetnpreason',
    //SAMPLE_CATEGORY
    'SAMPLE_TYPE_SEARCH': environment.basePathACM + 'FuncForACMAppToSearchSampleCategory',
    'SAMPLE_TYPE_ADD': environment.basePathACM + 'FuncForACMAppToAddSampleCategory',
    'SAMPLE_TYPE_GET': environment.basePathACM + 'FuncForACMAppToGetSampleCategory',
    'SAMPLE_TYPE_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateSampleCategory',
    'SAMPLE_TYPE_UPDATE_STATUS': environment.basePathACM + 'FuncForACMAppToStatusUpdateSampleCategory',
    //ZONE_API
    'ZONE_COUNTRY_SEARCH': environment.basePathACM + 'FuncForUMAppToZoneSearch',
    'ZONE_ADD': environment.basePathACM + 'FuncForUMAppToAddZone',
    'ZONE_GET': environment.basePathACM + 'FuncForUMAppToGetZone',
    'ZONE_UPDATE': environment.basePathACM + 'FuncForUMAppToUpdateZone',
    'ZONE_STATUS_UPDATE': environment.basePathACM + 'FuncForUMAppToStatusUpdateZone',
    'MASTER_DROP_DOWNS_ZONE': environment.basePathACM + 'FuncForACMAppToGetCommonmasters',
    //QUERY_TYPE
    'QUERY_TYPE_SEARCH': environment.basePathACM + 'FuncForACMAppToSearchquerytype',
    'QUERY_TYPE_ADD': environment.basePathACM + 'FuncForACMAppToAddquerytype',
    'QUERY_TYPE_GET': environment.basePathACM + 'FuncForACMAppToGetquerytype',
    'QUERY_TYPE_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdatequerytype',
    'QUERY_TYPE_UPDATE_STATUS': environment.basePathACM + 'FuncForACMAppToStatusUpdatequerytype',
    //PSN_REASON
    'PSN_REASON_SEARCH': environment.basePathACM + 'FuncForACMAppToSearchpsnreason',
    'PSN_REASON_ADD': environment.basePathACM + 'FuncForACMAppToAddpsnreason',
    'PSN_REASON_GET': environment.basePathACM + 'FuncForACMAppToGetpsnreason',
    'PSN_REASON_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdatepsnreason',
    'PSN_REASON_UPDATE_STATUS': environment.basePathACM + 'FuncForACMAppToStatusUpdatepsnreason',
    //DOCTOR_SPECIALITY
    'DOCTOR_SPECIALITY_SEARCH': environment.basePathACM + 'FuncForACMAppToDrspecialitySearch',
    'DOCTOR_SPECIALITY_ADD': environment.basePathACM + 'FuncForACMAppToAddDrspeciality',
    'DOCTOR_SPECIALITY_GET': environment.basePathACM + 'FuncForACMAppToGetDrspeciality',
    'DOCTOR_SPECIALITY_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateDrspeciality',
    'DOCTOR_SPECIALITY_UPDATE_STATUS': environment.basePathACM + 'FuncForACMAppToStatusUpdateDrspeciality',

     //DEGREE MODULE
     'DEGREE_SEARCH': environment.basePathACM + 'FuncForACMAppToSearchDegreeMstDtls',
     'DEGREE_ADD': environment.basePathACM + 'FuncForACMAppToAddDegreeMstDtls',
     'DEGREE_GET': environment.basePathACM + 'FuncForACMAppToGetDegreeMstDtls',
     'DEGREE_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateDegreeMstDtls',
     'DEGREE_STATUSUPDATE': environment.basePathACM + 'FuncForACMAppToStatusUpdateDegreeMstDtls',

      //Designation MODULE
      'DESIGNATION_ADD': environment.basePathACM + 'FuncForACMAppToAddDesignationMstDtls',
      'DESIGNATION_SEARCH': environment.basePathACM + 'FuncForACMAppToSearchDesignationMstDtls',
      'DESIGNATION_STATUSUPDATE': environment.basePathACM + 'FuncForACMAppToStatusUpdateDesignationMstDtls',
      'DESIGNATION_GET': environment.basePathACM + 'FuncForACMAppToGetDesignationMstDtls',
      'DESIGNATION_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateDesignationMstDtls',
   
    //-----------Test Module End Points ---------------------------------------------------

    'ELEMENT_BASIC_INFO_ADD': environment.basePathACM + 'FuncForTMAppToAddElement',
    'ELEMENT_SAMPLE_ADD': environment.basePathACM + 'FuncForTMAppToAddElementSample',
    'ELEMENT_INSTRUMENT_ADD': environment.basePathACM + 'FuncForTMAppToAddElementInstrument',
    'ELEMENT_OTHER_INFO_ADD': environment.basePathACM + 'FuncForTMAppToAddUpdElementOtherDtls',

    'ELEMENT_SEARCH': environment.basePathACM + 'FuncForTMAppToSearchElement',
    'ELEMENT_DRAFT_SEARCH': environment.basePathACM + 'FuncForTMAppToSearchDraftElement',
    'ELEMENT_STATUS_UPDATE': environment.basePathACM + 'FuncForTMAppToStatusUpdateElement',
    'ELEMENT_GET': environment.basePathACM + 'FuncForTMAppToGetElement',
    'ELEMENT_BASIC_INFO_UPDATE': environment.basePathACM + 'FuncForTMAppToUpdateElement',
    'ELEMENT_SAMPLE_UPDATE': environment.basePathACM + 'FuncForTMAppToUpdateElementSample',
    'ELEMENT_INSTRUMENT_UPDATE': environment.basePathACM + 'FuncForTMAppToUpdateElementInstrument',
    'ELEMENT_OTHER_INFO_UPDATE': environment.basePathACM + 'FuncForTMAppToAddUpdElementOtherDtls',

    'ELEMENT_TO_GET_INSTRUMENT_TEMP_DESC': environment.basePathACM + 'FuncForTMAppToGetElementInstrTempDesc',
    'ELEMENT_TO_GET_INSTRUMENT_NUMERIC_REF_RANGE': environment.basePathACM + 'FuncForTMAppToGetElementInstrNumRefRange',
    'ELEMENT_TO_GET_INSTRUMENT_SELECTIVE_REF_RANGE': environment.basePathACM + 'FuncForTMAppToGetElementInstrSelectiveRefRange',
    'ELEMENT_TO_ADD_INSTRUMENT_NUMERIC_REF_RANGE': environment.basePathACM + 'FuncForTMAppToAddElementInstrumentNumRefRange',
    'ELEMENT_TO_ADD_INSTRUMENT_SELECTIVE_REF_RANGE': environment.basePathACM + 'FuncForTMAppToAddElementInstrumentSelectiveRefRange',
    'ELEMENT_TO_ADD_INSTRUMENT_TEMP_DESC_REF_RANGE': environment.basePathACM + 'FuncForTMAppToAddElementInstrumentTempDesc',

    //Selective value API 
    'ELEMENT_TO_ADD_INSTRUMENT_SELECTIVE_VALUE': environment.basePathACM + 'FuncForTMAppToAddElementSelectiveValue',
    'ELEMENT_TO_GET_INSTRUMENT_SELECTIVE_VALUE': environment.basePathACM + 'FuncForTMAppToGetElementSelectiveValue',
    'ELEMENT_TO_UPDATE_INSTRUMENT_SELECTIVE_VALUE': environment.basePathACM + 'FuncForTMAppToUpdateElementSelectiveValue',
    'ELEMENT_TO_DELETE_INSTRUMENT_SELECTIVE_VALUE': environment.basePathACM + 'FuncForTMAppToDeleteElementSelectiveValue',



    //LAB
    'LAB_COUNTRY_SEARCH': environment.basePathACM + 'FuncForACMAppToSearchCenter',
    'LAB_COUNTRY_STATUS': environment.basePathACM + 'FuncForACMAppToStatusUpdateCenter',
    'LAB_ADD': environment.basePathACM + 'FuncForACMAppToAddCenter',
    'LAB_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateCenter',
    'LAB_GET': environment.basePathACM + 'FuncForACMAppToGetCenter',
    'LAB_MASTER_DROP_DOWNS': environment.basePathACM + 'FuncForACMAppToGetCommonmasters',
    'CLIENT_SEARCH_DROP_DOWNS': environment.basePathACM + 'FuncForACMAppToGetCommonmasters',



    //CLIENT MODULE------------------------------------------------------------
    'CLIENT_TYPE_LIST_GET': environment.basePathACM + 'FuncForACMAppToGetClienttablList',
    'CLIENT_CC_CENTER_INFO_ADD': environment.basePathACM + 'FuncForACMAppToAddClientCenterInfo',
    'CLIENT_CC_CENTER_INFO_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateClientCenterInfo',
    'CLIENT_DRAFT_SEARCH': environment.basePathACM + 'FuncForTMAppToSearchDraftClient',
    'CLIENT_ALL_LISTING_SEARCH': environment.basePathACM + 'FuncForTMAppToSearchClient',
    'CLIENT_STATUS_UPDATE': environment.basePathACM + 'FuncForACMAppToStatusUpdateClient',
    'CLIENT_GET': environment.basePathACM + 'FuncForACMAppToGetClientList',
    'CLIENT_CC_OWNER_INFO_ADD': environment.basePathACM + 'FuncForACMAppToAddClientOwnerInfo',
    'CLIENT_SPECIAL_RATE_DETAIL': environment.basePathACM + 'FuncForACMAppToGetSpecialRates',
    'CLIENT_CC_BILLING_ADD_UPDATE': environment.basePathACM + 'FuncForACMAppToAddClientCCBilling',
    'CLIENT_CC_DISCOUNT_RATE_GET': environment.basePathACM + 'FuncForACMAppToGetDiscountRates',
    'CLIENT_CC_SERVICE_RATE_ADD_UPDATE': environment.basePathACM + 'FuncForACMAppToAddClientCCServiceRates',
    'CLIENT_DC_UNDER_CC_SERVICE_RATE_ADD_UPDATE': environment.basePathACM + 'FuncForCMAppToAddClientDcUnderCcServiceRates',
    'SERVICE_RATES_LOG_DETAILS_HISTORY_GET': environment.basePathACM + 'FuncForACMAppToGetClientlog',
    'SPECIAL_RATES_LOG_DETAILS_HISTORY_GET': environment.basePathACM + 'FuncForACMAppToGetSpecialRateslog',
    'GET_CLIENT_DETAILS_SIDEBAR': environment.basePathACM + 'FuncForCMAppToGetClintInfo',
    'UPDATE_CLIENT_DETAILS_SIDEBAR': environment.basePathACM + 'FuncForCMAppToUpdateClientInfo',
    
    


    //CO
    'CLIENT_CO_GET': environment.basePathACM + 'FuncForACMAppToGetClientList',
    'CLIENT_CO_CENTER_INFO_ADD': environment.basePathACM + 'FuncForACMAppToAddCOClientCenterInfo',
    'CLIENT_CO_CENTER_INFO_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateCOClientCenterInfo',

    //DC
    'CLIENT_DC_CENTER_INFO_ADD': environment.basePathACM + 'FuncForACMAppToAddDcClientCenterInfo',
    'CLIENT_DC_CENTER_INFO_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateDcClientCenterInfo',
    'CLIENT_DC_CASH_BILLING_ADD_UPDATE': environment.basePathACM + 'FuncForACMAppToAddClientCashBilling',
    'CLIENT_DC_CREDIT_BILLING_ADD_UPDATE': environment.basePathACM + 'FuncForACMAppToAddClientCreditBilling',

    //CEP
    'CLIENT_CEP_CENTER_INFO_ADD': environment.basePathACM + 'FuncForACMAppToAddCEPClientCenterInfo',
    'CLIENT_CEP_CENTER_INFO_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateCEPClientCenterInfo',
    'CLIENT_CEP_CASH_BILLING_ADD_UPDATE': environment.basePathACM + 'FuncForACMAppToAddClientCashBilling',

    //DCUCC
    'CLIENT_DCUCC_CENTER_INFO_ADD': environment.basePathACM + 'FuncForACMAppToAddDcUnderCcClientCenterInfo',
    'CLIENT_DCUCC_CENTER_INFO_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateDcUnderCcClientCenterInfo',
    'CLIENT_DCUCC_ROLLING_BILLING_ADD_UPDATE': environment.basePathACM + 'FuncForACMAppToAddClientDcUnderccRollingBilling',

    //SIS
    'CLIENT_SIS_INVENTORY_ADD': environment.basePathACM + 'FuncForACMAppToAddsisClientInventory',
    'CLIENT_SIS_SERVICE_RATE_ADD_UPDATE': environment.basePathACM + 'FuncForACMAppToAddClientsisServiceRates',

    //Corporate
    'CLIENT_OUTLET_SERVED_SEARCH': environment.basePathACM + 'FuncForTMAppToSearchOutletType ',
    'CLIENT_OUTLET_SERVED_ADD': environment.basePathACM + 'FuncForACMAppToAddCopClientServedBy ',
    'CLIENT_OUTLET_SERVED_GET': environment.basePathACM + 'FuncForTMAppToSearchSavedOutletType ',
    'CLIENT_COP_SERVICE_RATE_ADD_UPDATE': environment.basePathACM + 'FuncForCMAppToAddClientCorporateServiceRates ',
    'CLIENT_OUTLET_SERVED_DELETE': environment.basePathACM + 'FuncForCMAppToDelcorptOutlettype',
    'CLIENT_PRICELIST_SPECIAL_CHARGE_DETAIL_GET': environment.basePathACM + 'FuncForCMAppToGetcorptSpecialRates',
    //PHLEBO
    'CLIENT_PHLEBO_GET': environment.basePathACM + 'FuncForACMAppToGetPhleboClientList',
    'CLIENT_PHLEBO_BILLING_ADD_UPDATE': environment.basePathACM + 'FuncForACMAppToAddClientPhleboBilling',
    'CLIENT_PHLEBO_EMI_CALCULATION': environment.basePathACM + 'FuncForTMAppToPhleboBillingEmiCalClient',

    // FRANCHISE
    'CLIENT_FRANCHISE_COMMISSION_GET_RATE': environment.basePathACM + 'FuncForACMAppToGetClientCommissionrates',
    'CLIENT_FRANCHISE_COMMISSION_ADD_UPDATE': environment.basePathACM + 'FuncForACMAppToAddFranchiseClientCommissionrate',
    'CLIENT_FRANCHISE_COMMISSION_ADD_UPDATE_RATE': environment.basePathACM + 'FuncForACMAppToAddClientCommissionrate',

    //Grid Side-bar
    'CLIENT_PAYMENT_MODE_UPDATE': environment.basePathACM + 'FuncForCMAppToUpdateClientBilling',
    'CLIENT_GET_BILL_PAYMENT_MODE': environment.basePathACM + 'FuncForCMAppToGetBillMode',
    'CLIENT_PAY_ADVANCE_EMI': environment.basePathACM + 'FuncForCMAppToAddPhleboPayEmiAdvance',
    'CLIENT_GET_ADVANCE_EMI_LIST': environment.basePathACM + 'FuncForCMAppToGetPhleboPayEmiAdvanceList',
    'CLIENT_COP_SERVICE_RATE_UPDATE_FROM_GRID': environment.basePathACM + 'FuncForCMAppToUpdateClientCorporateServiceRates ',
    'CLIENT_COMMON_SERVICE_RATE_UPDATE_FROM_GRID': environment.basePathACM + 'FuncForACMAppToUpdateClientServiceRates ',


    //Client Billing Get API For Client
    'CLIENT_ALL_BILLING_GET': environment.basePathACM + 'FuncForCMAppToGetPaymentDetails',

    //Owner Details Update from Grid
    'CLIENT_OWNER_DETAILS_GET': environment.basePathACM + 'FuncForCMAppToGetOwnerinfoList',
    'CLIENT_OWNER_DETAILS_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateClientOwnerInfo',

    //UPDATE CC
    'CLIENT_DC_UNDER_CC_GET': environment.basePathACM + 'FuncForACMAppToGetClientDCUnderCCGetCC',
    'CLIENT_DC_UNDER_CC_UPDATE': environment.basePathACM + 'FuncForCMAppToUpdateClientDCUnderCCUpdateCC',

    // UPDATE CHANGE ADDRESS
    'CLIENT_DC_UNDER_CHANGE_ADDRESS_GET': environment.basePathACM + 'FuncForACMAppToGetClientAddressInfo',
    'CLIENT_DC_UNDER_CHANGE_ADDRESS_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateClientAddressInfo',

    // GET CLIENT ADDRESS HISTORY 
    'CLIENT_GET_ADDRESS_HISTORY_LIST': environment.basePathACM + 'FuncForACMAppToGetClientAddressHistory',


    //Change Service by lab
    'CLIENT_CHANGE_SERVICE_BY_LAB_GET': environment.basePathACM + 'FuncForCMAppToGetClientServiceByLabMap',
    'CLIENT_CHANGE_SERVICE_BY_LAB_UPDATE': environment.basePathACM + 'FuncForCMAppToUpdateClientServiceByLabMap',

    //PATIENT REGISTRATION MODULE -------------------------------------------------------------------

    //SEARCH
    'PATIENT_SEARCH_GET_ESTIMATION': environment.basePathACM + 'FuncForPMAppToSearchPatientTodaysBooking',
    'PATIENT_SEARCH_GET_ESTIMATION_HISTROY': environment.basePathACM + 'FuncForPMAppToSearchPatientTodaysBookingHist',
    'PATIENT_PENDING_BOOKINGS_SEARCH': environment.basePathACM + 'FuncForPMAppToSearchPatientPendingSample',
    'PATIENT_PENDING_BOOKINGS_SEARCH_HISTORY': environment.basePathACM + 'FuncForPMAppToSearchPatientPendingSampleHist',
    'PATIENT_REGISTRATION_BOOKINGS_SEND_MAIL': environment.basePathACM + 'FuncForPMAppToSendReceiptOverMail',
    'PATIENT_REGISTRATION_BOOKINGS_GET_PROFILE_TEST': environment.basePathACM + 'FuncForPMAppToGetProfiletest',
    
    'PATIENT_BILLING_DETAILS_GET': environment.basePathACM + 'FuncForPMAppToGetPatientBillDtls',
    'PATIENT_GET_BILLING_CODE_LIST': environment.basePathACM + 'FuncForPMAppToGetBillingCode',
    'PATIENT_GET_DOCUMENTS': environment.basePathACM + 'FuncForPMAppToGetTestDocumentList',

    'PATIENT_GET_REQUEST_FOR_TNP': environment.basePathACM + 'FuncForPMAppToGetPatientBookedTest',
    'PATIENT_ADD_REQUEST_FOR_TNP': environment.basePathACM + 'FuncForPMAppToAddTestTNPMark',
    
    //REPORTS AVAILABLE SEARCH GRID IN PATIENT REGISTRATION MODULE
    'PATIENT__GET_REPORTS_AVAILABLE_LIST': environment.basePathACM + 'FuncForPMAppToSearchPatientReportAvailableClient',
    'PATIENT__GET_REPORTS_AVAILABLE_LIST_HISTORY': environment.basePathACM + 'FuncForPMAppToSearchPatientReportAvailableClientHist',
    'PATIENT_GET_PATIENT_TEST_LIST_REPORT': environment.basePathACM + 'FuncForPMAppToGetPatientTestReportClient',

    //LEAD
    'PATIENT_GET_LEAD_LIST': environment.basePathACM + 'FuncForPMAppToSearchLeadClient',
    'PATIENT_REJECTACCEPT': environment.basePathACM + 'FuncForPMAppToLeadAcceptReject',
    'PATIENT_ACCEPTPHELOBO': environment.basePathACM + 'FuncForPMAppToUpdateAssignPhlebo',
    'PATIENT_REASSIGN_PHLEBO_SAVE_CLIENT': environment.basePathACM + 'FuncForPMAppToUpdateReassignPhlebo',
    'LEAD_REQUEST_FOR_RESCHUDLE': environment.basePathACM + 'FuncForPMAppToLeadRequestForReschedule',
    'PATIENT_GET_LEAD_LIST_REMOVE': environment.basePathACM + 'FuncForPMAppToLeadPatientRemoveClient',

 // LEAD ACCEPT REJECT LAB
 'PATIENT_REJECTACCEPT_LAB': environment.basePathACM + 'FuncForPMAppToLeadAcceptRejectLab',
    'PATIENT_ACCEPTPHELOBO_SAVE_LAB': environment.basePathACM + 'FuncForPMAppToUpdateAssignPhleboLab',
    'PATIENT_REASSIGN_PHLEBO_SAVE_LAB': environment.basePathACM + 'FuncForPMAppToUpdateReassignPhleboLab',
    'LEAD_REQUEST_FOR_RESCHUDLE_LAB': environment.basePathACM + 'FuncForPMAppToLeadRequestForRescheduleLab',
    'PATIENT_GET_LEAD_LIST_REMOVE_LAB': environment.basePathACM + 'FuncForPMAppToLeadPatientRemoveLab',
    //-----End Points for Update  Sample from PSN grid ------------------------//
    'PATIENT_PSN_GET_SAMPLE_DATA_CLIENT': environment.basePathACM + 'FuncForPMAppToGetPSNMarkSampleClient',
    'PATIENT_PSN_GET_DOCUMENT_DATA_CLIENT': environment.basePathACM + 'FuncForPMAppToGetPSNMarkDocClient',
    'PATIENT_PSN_GET_SAMPLE_DOC_CLIENT': environment.basePathACM + 'FuncForPMAppToGetPSNMarkSampleWithDocClient',
    'PATIENT_PSN_SAMPLE_ADD_CLIENT': environment.basePathACM + 'FuncForPMAppToAddPSNMarkSampleClient',
    'PATIENT_PSN_SAMPLE_DOC_ADD_CLIENT': environment.basePathACM + 'FuncForPMAppToAddPSNMarkSampleDocClient',
    'PATIENT_PSN_SAMPLE_DOC_CLIENT': environment.basePathACM + 'FuncForPMAppToAddPSNMarkDocClient',

 
    //TEST & PATIENT
    'PATIENT_TEST_GET_TEST_LIST': environment.basePathACM + 'FuncForPMAppToGetTestNameClientBased',
    'PATIENT_GET_TEST_ESTIMATION': environment.basePathACM + 'FuncForPMAppToSearchTestEstimationClient',
    'PATIENT_GET_CURRENT_ROLLING_AMOUNT': environment.basePathACM + 'FuncForPMAppToGetCurrentRollingAmountClient',
    'PATIENT_ADD_TEST_PATIENT_INFO': environment.basePathACM + 'FuncForPMAppToAddPatientRegistrationClient',
    'PATIENT_GET_TEST_PATIENT_INFO': environment.basePathACM + 'FuncForPMAppToGetPatientTestBookClient',
    'PATIENT_UPDATE_TEST_PATIENT_INFO': environment.basePathACM + 'FuncForPMAppToUpdatePatientRegistrationClient',
    'PATIENT_TO_GET_PATIENT_INFO_ON_SEARCH': environment.basePathACM + 'FuncForPMAppToGetPatientinfoClient',
    'PATIENT_REFERRING_DOCTOR_ADD': environment.basePathACM + 'FuncForPMAppToAddPatientRefdoctor',
    'PATIENT_TEST_CHECK_TEST_PROFILE': environment.basePathACM + 'FuncForPMAppToGetTestProfileClient',
    'PATIENT_TEST_CHECK_TEST_VALIDITY': environment.basePathACM + 'FuncForPMAppToValidateTestPerformedOn',
    'PATIENT_TEST_GET_REF_DOCTOR_NAME_CLIENT': environment.basePathACM + 'FuncForACMAppToGetRefDoctorNameClient',
    'PATIENT_GET_TEST_PATIENT_INFO_BY_LEAD': environment.basePathACM + 'FuncForPMAppToGetLeadClient',
    //OTP VERIFICATION
    'PATIENT_BASIC_INFO_SEND_OTP': environment.basePathACM + 'FuncForPMAppToSendOtp',
    'PATIENT_BASIC_INFO_RESEND_OTP': environment.basePathACM + 'FuncForPMAppToReSendOtp',
    'PATIENT_BASIC_INFO_VERIFY_OTP': environment.basePathACM + 'FuncForPMAppToVerifyOtp',

    //BILLING
    'PATIENT_ADD_BILLING': environment.basePathACM + 'FuncForPMAppToAddPatientBillingClient',
    'PATIENT_GET_TEST_SUMMARY': environment.basePathACM + 'FuncForPMAppToGetTestSummaryClient',
    'PATIENT_ADD_PRIVILELE_CARD': environment.basePathACM + 'FuncForPMAppToAddPatientprivlcardClient',
    'PATIENT_GET_PRIVILEGE_CARD_VALIDITY': environment.basePathACM + 'FuncForPMAppToGetPrivilegeCardTypeCardamountClient',
    'PATIENT_GET_VERIFY_MOBILE_CARD': environment.basePathACM + 'FuncForPMAppToGetPrivilegeCardDiscountClient',
    'PATIENT_CLIENT_GET_BILL_RECEIPT_JUST_AFTER_BILL': environment.basePathACM + 'FuncForPMAppToGetPatientBillClient',

    //SAMPLE
    'PATIENT_SAMPLE_GET_DATA': environment.basePathACM + 'FuncForPMAppToGetPatientSampleClient',
    'PATIENT_SAMPLE_ADD': environment.basePathACM + 'FuncForPMAppToAddPatientSampleClient',
    'PATIENT_PARTIAL_SAMPLE_GET_DATA': environment.basePathACM + 'FuncForPMAppToGetPatientPendingSampleClient',
    'GET_PATIENT_TEST_INVENTORY': environment.basePathACM + 'FuncForPMAppToGetPatientTestInventory',
    'PATIENT_PARTIAL_SAMPLE_ADD': environment.basePathACM + 'FuncForPMAppToAddPatientPendingSampleClient',
    'LAB_PATIENT_PARTIAL_SAMPLE_GET_DATA': environment.basePathACM + 'FuncForPMAppToGetPatientPendingSampleLab',
    'LAB_PATIENT_PARTIAL_SAMPLE_ADD': environment.basePathACM + 'FuncForPMAppToAddPatientPendingSampleLab',

    //PATIENT REGISTRATION

    'PATIENT_PATIENT_REG_ADD_PATIENT_INFO': environment.basePathACM + 'FuncForPMAppToAddPBRegistration',
    'PATIENT_PATIENT_REG_UPDATE_PATIENT_INFO': environment.basePathACM + 'FuncForPMAppToUpdatePBRegistration',
    'PATIENT_GET_PB_TEST_ESTIMATION': environment.basePathACM + 'FuncForPMAppToGetPBTestEstimationList',
    'PATIENT_PATIENT_REG_ADD_TEST': environment.basePathACM + 'FuncForPMAppToAddPatientTest',
    'PATIENT_PATIENT_REG_UPDATE_TEST': environment.basePathACM + 'FuncForPMAppToUpdatePatientTest',

    //PATIENT SIDE BAR

    'PATIENT_RECEIPT_LIST_GETC': environment.basePathACM + 'FuncForPMAppToGetPatientReceiptList',
    'PATIENT_CLIENT_PREVIEW_RECEIPT_GET': environment.basePathACM + 'FuncForPMAppToGetPatientReceiptClient',
    'PATIENT_CANCEL_TEST_GET': environment.basePathACM + 'FuncForPMAppToGetPatientTestCancelClient',
    'PATIENT_CANCEL_TEST_UPDATE': environment.basePathACM + 'FuncForPMAppToCancelPatientTestClient',

    // CLIENT LIST
    'PATIENT_TEST_GET_DCUNDERCC_CORP_SANJI_CLIENT_LIST': environment.basePathACM + 'FuncForPMAppToGetDcUnderCcCorporateClientList',

    //Tracking
    'PATIENT_TEST_GET_TRACKING': environment.basePathACM + 'FuncForPMAppToGetPatientTracking',
    'GET_SAMPLE_TRACKING': environment.basePathACM + 'FuncForLOAppToGetSampleTracking',

    // CAMP LIST DROPDOWN IN PATIENT MANAGEMENT
    'PATIENT_TEST_GET_CAMP_LIST': environment.basePathACM + 'FuncForPMAppToGetClientCampList',
    'PATIENT_TEST_GET_CAMP_LIST_LAB': environment.basePathACM + 'FuncForPMAppToGetlabCampList',


    //******************PATIENT REGISTRATION IF LOGIN USER IS LAB ...//

    //PAYMENT GATEWAY
    'PATIENT_ADD_FUND': environment.basePathACM + 'FuncForPMAppToAddFund',
    'PATIENT_FUND_INFORMATION': environment.basePathACM + 'FuncForPMAppToGetFundDetails',
    'TRANSECTION_STATUS_RESPONSE_DETAILS': environment.basePathACM + 'FuncForPMAppToGetCashfreeTrnStatus',
    'TRANSECTION_GET_FUND_DETAILS': environment.basePathACM + 'FuncForPMAppToGetFundTrnLogDetails',



    
    
    //TNP(LAB and Client)
    'PATIENT_LAB_SEARCH_GET_TNP_LAB': environment.basePathACM + 'FuncForPMAppToSearchPatientTNPLab',
    'PATIENT_LAB_SEARCH_GET_TNP_LAB_HISTORY': environment.basePathACM + 'FuncForPMAppToSearchPatientTNPLabHist',
    'PATIENT_LAB_VIEW_TNP_DATA': environment.basePathACM + 'FuncForPMAppToGetPatientTnpTestView',

    'PATIENT_SEARCH_GET_TNP_CLIENT': environment.basePathACM + 'FuncForPMAppToSearchPatientTNPClient',
    'PATIENT_SEARCH_GET_TNP_CLIENT_HISTORY': environment.basePathACM + 'FuncForPMAppToSearchPatientTNPClientHist',
    


    // SEARCH (LAB)
    'PATIENT_LAB_SEARCH_GET_ESTIMATION': environment.basePathACM + 'FuncForPMAppToSearchPatientTodaysBookingLab',
    'PATIENT_LAB_SEARCH_GET_ESTIMATION_HISTORY': environment.basePathACM + 'FuncForPMAppToSearchPatientTodaysBookingLabHist',
  
    'PATIENT_LAB_PENDING_BOOKINGS_SEARCH': environment.basePathACM + 'FuncForPMAppToSearchPatientPendingSampleLab',
    'PATIENT_LAB_PENDING_BOOKINGS_SEARCH_HISTORY': environment.basePathACM + 'FuncForPMAppToSearchPatientPendingSampleLabHist',

    'PATIENT_BILLING_DETAILS_GET_LAB': environment.basePathACM + 'FuncForPMAppToGetPatientBillDtlsLab',
    
    'PATIENT_LAB_GET_BILLING_CODE_LIST': environment.basePathACM + 'FuncForPMAppToGetBillingCodeLab',
    'PATIENT_LAB_GET_BILLING_CODE_LIST_HISTORY': environment.basePathACM + 'FuncForPMAppToGetBillingCodeLabHist',
    
    'PATIENT_RECEIPT_LIST_GET': environment.basePathACM + 'FuncForPMAppToGetPatientReceiptList',
    'PATIENT_PREVIEW_RECEIPT_GET': environment.basePathACM + 'FuncForPMAppToGetPatientReceiptClient',
    'PATIENT_GET_REQUEST_FOR_TNP_LAB': environment.basePathACM + 'FuncForPMAppToGetPatientBookedTestLab',
    'PATIENT_ADD_REQUEST_FOR_TNP_LAB': environment.basePathACM + 'FuncForPMAppToAddTestTNPMarkLab',
    'PATIENT_GET_LEAD_LIST_LAB': environment.basePathACM + 'FuncForPMAppToSearchLeadLab',

    //-----End Points for Update  Sample from PSN grid ------------------------//
    'PATIENT_PSN_GET_SAMPLE_DATA_LAB': environment.basePathACM + 'FuncForPMAppToGetPSNMarkSampleLab',
    'PATIENT_PSN_GET_DOCUMENT_DATA_LAB': environment.basePathACM + 'FuncForPMAppToGetPSNMarkDocLab',
    'PATIENT_PSN_GET_SAMPLE_DOC_LAB': environment.basePathACM + 'FuncForPMAppToGetPSNMarkSampleWithDocLab',
    'PATIENT_PSN_SAMPLE_DOC_ADD_LAB': environment.basePathACM + 'FuncForPMAppToAddPSNMarkSampleDocLab',
    'PATIENT_PSN_DOC_ADD_LAB': environment.basePathACM + 'FuncForPMAppToAddPSNMarkDocLab',
    'PATIENT_PSN_SAMPLE_ADD_LAB': environment.basePathACM + 'FuncForPMAppToAddPSNMarkSampleLab',
    
     //REPORTS AVAILABLE SEARCH GRID IN PATIENT REGISTRATION MODULE
     'PATIENT_GET_REPORTS_AVAILABLE_LIST_LAB': environment.basePathACM + 'FuncForPMAppToSearchPatientReportAvailableLab',
     'PATIENT_GET_REPORTS_AVAILABLE_LIST_LAB_HISTORY': environment.basePathACM + 'FuncForPMAppToSearchPatientReportAvailableLabHist',
    
     'PATIENT_GET_PATIENT_TEST_LIST_REPORT_LAB': environment.basePathACM + 'FuncForPMAppToGetPatientTestReportLab',


    //--------PATIENT SIDE BAR (LAB) --------------//

    'PATIENT_LAB_RECEIPT_LIST_GET': environment.basePathACM + 'FuncForPMAppToGetPatientReceiptListLab',
    'PATIENT_LAB_PREVIEW_RECEIPT_GET': environment.basePathACM + 'FuncForPMAppToGetPatientReceiptLab',

    'PATIENT_LAB_CANCEL_TEST_GET': environment.basePathACM + 'FuncForPMAppToGetPatientTestCancelLab',
    'PATIENT_LAB_CANCEL_TEST_UPDATE': environment.basePathACM + 'FuncForPMAppToCancelPatientTestLab',

    'GET_PAYMENT_RECEIPT': environment.basePathACM + 'FuncForPMAppToGetPatientReceipt',

    //GET ESTIMATE TEST

    //TEST && PATIENT INFO
    'PATIENT_TEST_CHECK_TEST_PROFILE_LAB': environment.basePathACM + 'FuncForPMAppToGetTestProfileLab',
    'PATIENT_GET_BILLING_CODE_LIST_FOR_LAB': environment.basePathACM + 'FuncForPMAppToGetBillingCodeLab',
    'PATIENT_TEST_GET_TEST_LIST_LAB': environment.basePathACM + 'FuncForPMAppToGetTestNameLabBased',
    'PATIENT_GET_TEST_ESTIMATION_LAB': environment.basePathACM + 'FuncForPMAppToSearchTestEstimationLab',
    'PATIENT_ADD_TEST_PATIENT_INFO_LAB': environment.basePathACM + 'FuncForPMAppToAddPatientRegistrationLab',
    'PATIENT_GET_TEST_PATIENT_INFO_LAB': environment.basePathACM + 'FuncForPMAppToGetPatientTestBookLab',
    'PATIENT_UPDATE_TEST_PATIENT_INFO_LAB': environment.basePathACM + 'FuncForPMAppToUpdatePatientRegistrationLab',
    'PATIENT_TO_GET_PATIENT_INFO_LAB_ON_SEARCH': environment.basePathACM + 'FuncForPMAppToGetPatientinfoLab',
    'PATIENT_TEST_CHECK_TEST_VALIDITY_LAB': environment.basePathACM + 'FuncForPMAppToValidateTestPerformedOnLab',
    'PATIENT_TEST_GET_DC_SIS_CORP_LAB_LIST': environment.basePathACM + 'FuncForPMAppToGetDcSISCorporateClientListlab',
    'PATIENT_TEST_GET_REF_DOCTOR_NAME_LAB': environment.basePathACM + 'FuncForACMAppToGetRefDoctorNameLab',
    'PATIENT_GET_CURRENT_CREDIT_AMOUNT_LAB': environment.basePathACM + 'FuncForPMAppToGetCurrentcreditAmountlab',
    'PATIENT_GET_TEST_PATIENT_INFO_BY_LEAD_LAB': environment.basePathACM + 'FuncForPMAppToGetLeadLab',


    //BILLING
    'PATIENT_ADD_BILLING_LAB': environment.basePathACM + 'FuncForPMAppToAddPatientBillingLab',
    'PATIENT_ADD_BILLING_PROCEED_AND_PAY_LAB': environment.basePathACM + 'FuncForPMAppToAddproceedforpaymentLab',
    'PATIENT_ADD_BILLING_PROCEED_AND_VERIFY_PAYMENT_LAB': environment.basePathACM + 'FuncForPMAppToVerifypaymentStatusLab',
    'PATIENT_LAB_GET_BILL_RECEIPT_JUST_AFTER_BILL': environment.basePathACM + 'FuncForPMAppToGetPatientBillLab',



    'PATIENT_GET_TEST_SUMMARY_LAB': environment.basePathACM + 'FuncForPMAppToGetTestSummaryLab',
    'PATIENT_ADD_PRIVILELE_CARD_LAB': environment.basePathACM + 'FuncForPMAppToAddPatientprivlcardLab',
    'PATIENT_GET_PRIVILEGE_CARD_VALIDITY_LAB': environment.basePathACM + 'FuncForPMAppToGetPrivilegeCardTypeCardamountLab',
    'PATIENT_GET_VERIFY_MOBILE_CARD_LAB': environment.basePathACM + 'FuncForPMAppToGetPrivilegeCardDiscountLab',

    //SAMPLE
    'PATIENT_SAMPLE_GET_DATA_LAB': environment.basePathACM + 'FuncForPMAppToGetPatientSampleLab',
    'PATIENT_SAMPLE_ADD_LAB': environment.basePathACM + 'FuncForPMAppToAddPatientSampleLab',

    //PATIENT REGISTRATION (LAB)

    'PATIENT_PATIENT_REG_ADD_PATIENT_INFO_LAB': environment.basePathACM + 'FuncForPMAppToAddPBRegistrationLab',
    'PATIENT_PATIENT_REG_UPDATE_PATIENT_INFO_LAB': environment.basePathACM + 'FuncForPMAppToUpdatePBRegistrationLab',
    'PATIENT_GET_PB_TEST_ESTIMATION_LAB': environment.basePathACM + 'FuncForPMAppToGetPBTestEstimationListLab',
    'PATIENT_PATIENT_REG_ADD_TEST_LAB': environment.basePathACM + 'FuncForPMAppToAddPatientTestLab',
    'PATIENT_PATIENT_REG_UPDATE_TEST_LAB': environment.basePathACM + 'FuncForPMAppToUpdatePatientTestLab',

    'PATIENT_NAME_LIST_GET_LAB': environment.basePathACM + 'FuncForPMAppToGetPatientNameListLab',
    'PATIENT_NAME_LIST_GET_CLIENT': environment.basePathACM + 'FuncForPMAppToGetPatientNameListClient',

    // PATIENT AUTHORIZERLIST
    'PATIENT_AUTHORIZE_USER_LIST_CLIENT': environment.basePathACM + 'FuncForPMAppToGetAuthorizeUserClient',
    'PATIENT_AUTHORIZE_USER_LIST_LAB': environment.basePathACM + 'FuncForPMAppToGetAuthorizeUserLab',

    // PATIENT PRIVILEGE CARD (LAB)
    'PATIENT_PRIVILEGE_CARD_SEARCH_LAB': environment.basePathACM + 'FuncForPMAppToSearchPatientprivlcard',

    // PATIENT BATCH CLIENT
    'PATIENT_BATCH_CLIENT_GET': environment.basePathACM + 'FuncForPMAppToGetBatchClient',
    'PATIENT_BATCH_CLIENT_ADD': environment.basePathACM + 'FuncForPMAppAddBatchClient',
    'PATIENT_BATCH_CLIENT_UPDATE': environment.basePathACM + 'FuncForPMAppToUpdateBatchClient',
    'PATIENT_BATCH_CLIENT_VIEW': environment.basePathACM + 'FuncForPMAppToViewBatchwiseClient',
    'PATIENT_BATCH_CLIENT_SEARCH': environment.basePathACM + 'FuncForPMAppToSearchBatchClient',
    'PATIENT_BATCH_CLIENT_SEARCH_SAMPLE': environment.basePathACM + 'FuncForPMAppToSearchBatchwiseSampleClient',
    'PATIENT_BATCH_CLIENT_SERVICE_BY_LAB_GET': environment.basePathACM + 'FuncForPMAppToGetServiceByLabBatchClient',
    'PATIENT_BATCH_CLIENT_STATUS_UPDATE_TO_DISPATCH': environment.basePathACM + 'FuncForPMAppToUpdateStatusdispatchedBatchClient',

    // PATIENT BATCH LAB
    'PATIENT_BATCH_LAB_GET': environment.basePathACM + 'FuncForPMAppToGetBatchLab',
    'PATIENT_BATCH_LAB_ADD': environment.basePathACM + 'FuncForPMAppAddBatchLab',
    'PATIENT_BATCH_LAB_UPDATE': environment.basePathACM + 'FuncForPMAppToUpdateBatchLab',
    'PATIENT_BATCH_LAB_VIEW': environment.basePathACM + 'FuncForPMAppToViewBatchwiseLab',
    'PATIENT_BATCH_LAB_SEARCH': environment.basePathACM + 'FuncForPMAppToSearchBatchLab',
    'PATIENT_BATCH_LAB_SEARCH_SAMPLE': environment.basePathACM + 'FuncForPMAppToSearchBatchwiseSampleLab',
    'PATIENT_BATCH_LAB_SERVICE_BY_LAB_GET': environment.basePathACM + 'FuncForPMAppToGetServiceByLabBatchLab',
    'PATIENT_BATCH_LAB_STATUS_UPDATE_TO_DISPATCH': environment.basePathACM + 'FuncForPMAppToUpdateStatusdispatchedBatchLab',

    //SAMPLE TRANSFER FROM LAB TO DEPARTMENT
    'PATIENT_SCAN_SAMPLE_TRANSFER': environment.basePathACM + 'FuncForPMAppToGetSampleTransferScan',
    'PATIENT_UPDATE_SAMPLE_TRANSFER_DEPARTMENT': environment.basePathACM + 'FuncForPMAppToUpdateSampleTransfer',

//*********************  ACCESSIONING START ***************//
//RECEIVE BATCH
'LAB_OPERATION_RECEIVE_BATCH_SEARCH': environment.basePathACM + 'FuncForLOAppToSearchReceivedBatch',
'LAB_OPERATION_RECEIVE_BATCH_SCAN': environment.basePathACM + 'FuncForLOAppToSearchScanBatch',
'LAB_OPERATION_MARK_RECEIVE_BATCH': environment.basePathACM + 'FuncForLOAppToUpdateBatchReceivedStatus',
'LAB_OPERATION_BATCH_SAMPLE_VIEW': environment.basePathACM + 'FuncForLOAppToGetBatchSample',
'LAB_OPERATION_RECEIVE_BATCH_SEARCH_HISTORY': environment.basePathACM + 'FuncForLOAppToSearchReceivedHisBatch',

//MISSING SAMPLE
'LAB_OPERATION_MISSING_SAMPLE_GET': environment.basePathACM + 'FuncForLOAppToSearchMissingSample',

//SAMPLE
'LAB_OPERATION_SEARCH_SAMPLE': environment.basePathACM + 'FuncForLOAppToSearchSample',
'LAB_OPERATION_SEARCH_PROBLEM_SAMPLE': environment.basePathACM + 'FuncForLOAppToSearchProblemSample',
'LAB_OPERATION_SCAN_SAMPLE_GET': environment.basePathACM + 'FuncForLOAppToGetScanSample',
'LAB_OPERATION_ACCEPT_RECEIVE_SAMPLE': environment.basePathACM + 'FuncForLOAppToUpdateAcceptReceivedSample',
'LAB_OPERATION_MARK_PSN_SAMPLE_ADD': environment.basePathACM + 'FuncForLOAppToAddSampleMarkPSN',
'LAB_OPERATION_PERFORMING_LOC_CHANGE_SAMPLE_SEARCH': environment.basePathACM + 'FuncForLOAppToSearchPerflocSample',
'LAB_OPERATION_PERFORMING_LOC_CHANGE_SAMPLE_ADD': environment.basePathACM + 'FuncForLOAppToAddSamplePerfloc',
'LAB_OPERATION_PERFORMING_LOC_CHANGE_LAB': environment.basePathACM + 'FuncForLOAppToGetPerflocLab',
'LAB_OPERATION_DOC_VIEW': environment.basePathACM + 'FuncForLOAppToGetTestDocumentList',
'LAB_OPERATION_GET_TEST_SAMPLE_WISE': environment.basePathACM + 'FuncForLOAppToGetTestWiseSample',
'LAB_OPERATION_UPDATE_SAMPLE_AS_VIP': environment.basePathACM + 'FuncForLOAppToUpdateSampleVIP',

//CREATE BATCH
'LAB_OPERATION_SCAN_BATCH_WISE_SAMPLE': environment.basePathACM + 'FuncForLOAppToSearchBatchwiseSample',
'LAB_OPERATION_ADD_BATCH': environment.basePathACM + 'FuncForLOAppAddBatch',
'LAB_OPERATION_UPDATE_BATCH': environment.basePathACM + 'FuncForLOAppToUpdateBatch',
'LAB_OPERATION_GET_BATCH': environment.basePathACM + 'FuncForLOAppToGetBatch',
'LAB_OPERATION_VIEW_BATCH': environment.basePathACM + 'FuncForLOAppToViewBatchwise',
'LAB_OPERATION_SEARCH_BATCH': environment.basePathACM + 'FuncForLOAppToSearchBatch',
'LAB_OPERATION_UPDATE_DISPATCH_BATCH': environment.basePathACM + 'FuncForLOAppToUpdateStatusdispatchedBatch',

//Transfer Sample accessioning
'LAB_OPERATION_SCAN_SAMPLE_TRANSFER': environment.basePathACM + 'FuncForLOAppToGetSampleTransferScan',
'LAB_OPERATION_UPDATE_SAMPLE_TRANSFER_DEPARTMENT': environment.basePathACM + 'FuncForLOAppToUpdateSampleTransfer',


//*********************  ACCESSIONING END ***************//

//*********************  LAB OPERATIION START ***************//
//RECEIVE BATCH
'LAB_OPERATION_RECEIVE_BATCH_SEARCH_ANALITICAL': environment.basePathACM + 'FuncForLOAppToSearchReceivedBatchanalytical',
'LAB_OPERATION_RECEIVE_BATCH_SCAN_ANALITICAL': environment.basePathACM + 'FuncForLOAppToSearchScanBatchanalytical',
'LAB_OPERATION_MARK_RECEIVE_BATCH_ANALITICAL': environment.basePathACM + 'FuncForLOAppToUpdateBatchReceivedStatusanalytical',
'LAB_OPERATION_BATCH_SAMPLE_VIEW_ANALITICAL': environment.basePathACM + 'FuncForLOAppToGetBatchSampleanalytical',

//MISSING SAMPLE
'LAB_OPERATION_MISSING_SAMPLE_GET_ANALITICAL': environment.basePathACM + 'FuncForLOAppToSearchMissingSampleanalytical',

//SAMPLE
'LAB_OPERATION_SEARCH_SAMPLE_ANALITICAL': environment.basePathACM + 'FuncForLOAppToSearchSampleanalytical',
'LAB_OPERATION_SEARCH_UNRECEIVED_SAMPLE_ANALITICAL': environment.basePathACM + 'FuncForLOAppToSearchUnreceivedSampleanalytical',
'LAB_OPERATION_SEARCH_PROBLEM_SAMPLE_ANALITICAL': environment.basePathACM + 'FuncForLOAppToSearchProblemSampleanalytical',
'LAB_OPERATION_SCAN_SAMPLE_GET_ANALITICAL': environment.basePathACM + 'FuncForLOAppToGetScanSampleanalytical',
'LAB_OPERATION_ACCEPT_RECEIVE_SAMPLE_ANALITICAL': environment.basePathACM + 'FuncForLOAppToUpdateAcceptReceivedSampleanalytical',
'LAB_OPERATION_MARK_PSN_SAMPLE_ADD_ANALITICAL': environment.basePathACM + 'FuncForLOAppToAddSampleMarkPSNanalytical',
'LAB_OPERATION_PERFORMING_LOC_CHANGE_SAMPLE_SEARCH_ANALITICAL': environment.basePathACM + 'FuncForLOAppToSearchPerflocSampleanalytical',
'LAB_OPERATION_PERFORMING_LOC_CHANGE_SAMPLE_ADD_ANALITICAL': environment.basePathACM + 'FuncForLOAppToAddSamplePerflocanalytical',
'LAB_OPERATION_PERFORMING_LOC_CHANGE_LAB_ANALITICAL': environment.basePathACM + 'FuncForLOAppToGetPerflocLabanalytical',
'LAB_OPERATION_DOC_VIEW_ANALITICAL': environment.basePathACM + 'FuncForLOAppToGetTestDocumentList',
'LAB_OPERATION_GET_TEST_SAMPLE_WISE_ANALITICAL': environment.basePathACM + 'FuncForLOAppToGetTestWiseSampleanalytical',
'LAB_OPERATION_CHANGE_PERFOMING_LOCATION_ANALITICAL_ADD': environment.basePathACM + 'FuncForLOAppToAddSampleAcceptPerflocanalytical',

//SEND OUT TAB
'LAB_OPERATION_SEARCH_SAMPLE_ANALITICAL_SEND_OUT': environment.basePathACM + 'FuncForLOAppToSearchSampleanalyticalSendOut',


//CREATE BATCH
'LAB_OPERATION_SCAN_BATCH_WISE_SAMPLE_ANALITICAL': environment.basePathACM + 'FuncForLOAppToSearchBatchwiseSampleanalytical',
'LAB_OPERATION_ADD_BATCH_ANALITICAL': environment.basePathACM + 'FuncForLOAppAddBatchanalytical',
'LAB_OPERATION_UPDATE_BATCH_ANALITICAL': environment.basePathACM + 'FuncForLOAppToUpdateBatchanalytical',
'LAB_OPERATION_GET_BATCH_ANALITICAL': environment.basePathACM + 'FuncForLOAppToGetBatchanalytical',
'LAB_OPERATION_VIEW_BATCH_ANALITICAL': environment.basePathACM + 'FuncForLOAppToViewBatchwiseanalytical',
'LAB_OPERATION_SEARCH_BATCH_ANALITICAL': environment.basePathACM + 'FuncForLOAppToSearchBatchanalytical',
'LAB_OPERATION_UPDATE_DISPATCH_BATCH_ANALITICAL': environment.basePathACM + 'FuncForLOAppToUpdateStatusdispatchedBatchanalytical',

//Transfer Sample in Lab OPeration
'LAB_OPERATION_SCAN_SAMPLE_TRANSFER_ANALITICAL': environment.basePathACM + 'FuncForLOAppToGetSampleTransferScananalytical',
'LAB_OPERATION_UPDATE_SAMPLE_TRANSFER_DEPARTMENT_ANALITICAL': environment.basePathACM + 'FuncForLOAppToUpdateSampleTransferanalytical',
//---------------------RESULT ENRTY---------------------------------------//

//PENDING ENTRY
'LAB_OPERATION_RESULT_ENTRY_SEARCH_PENDING_ENTRY': environment.basePathACM + 'FuncForLOAppToSearchPendingTestResultEntry',
'GET_DEPARTMENT_USER_WISE_LIST': environment.basePathACM + 'FuncForACMAppToGetDepartmentUserWiseList',
'GET_INSTRUMENT_USER_WISE_LIST': environment.basePathACM + 'FuncForACMAppToGetInstrumentUserWiseList',
'LAB_OPERATION_RESULT_ENTRY_GET_TEST_ELEMENT': environment.basePathACM + 'FuncForLOAppToGetTestElement',
'LAB_OPERATION_RESULT_ENTRY_ADD_PATIENT_TEST_RESULT': environment.basePathACM + 'FuncForLOAppToAddPatientTestResult',
'LAB_OPERATION_RESULT_ENTRY_GET_REFLEX_ELEMENT': environment.basePathACM + 'FuncForLOAppToGetElementReflexParameter',
'LAB_OPERATION_RESULT_ENTRY_ADD_REFLEX_ELEMENT': environment.basePathACM + 'FuncForLOAppToAddReflexParamMacData',
'LAB_OPERATION_RESULT_ENTRY_REMOVE_REFLEX_ELEMENT': environment.basePathACM + 'FuncForLOAppToDelReflexParamMacData',
'LAB_OPERATION_RESULT_ENTRY_GET_ELEMENT_RESULT': environment.basePathACM + 'FuncForLOAppToGetSelectedElementResult',
'LAB_OPERATION_RESULT_ENTRY_GENERATE_WORKSHEET': environment.basePathACM + 'FuncForLOAppToGetWorkSheetPatientTestElement',
'LAB_OPERATION_RESULT_ENTRY_WORKSHEET_ADD': environment.basePathACM + 'FuncForLOAppToAddWorkSheetPatientTestElement',
'LAB_OPERATION_RESULT_ENTRY_ENTER_RESULT_GET_MODIFLICATION_LOG': environment.basePathACM + 'FuncForLOAppToGetPatientTestResutlModflog',
'LAB_OPERATION_RESULT_ENTRY_GET_ELEMENT_RESULT_HISTORY': environment.basePathACM + 'FuncForLOAppToGetElementResultHistory',
'LAB_OPERATION_RESULT_ENTRY_GET_DESC_TEMPLATE_RESULT': environment.basePathACM + 'FuncForLOAppToGetTemplateDescriptiveValue',

//MICROBIOLOGY RESULT TYPE IN PENDING ENTRY
'LAB_OPERATION_RESULT_ENTRY_GET_MICROBIOLOGY_ANTIBIOTIC_LIST': environment.basePathACM + 'FuncForLOAppToGetMicroTestForPendingEntry',
'LAB_OPERATION_RESULT_ENTRY_ADD_MICROBIOLOGY_ANTIBIOTIC_LIST': environment.basePathACM + 'FuncForLOAppToAddMicroTestForPendingEntry',
'LAB_OPERATION_RESULT_ENTRY_UPDATE_MICROBIOLOGY_ANTIBIOTIC_LIST': environment.basePathACM + 'FuncForLOAppToUpdateMicroTestForPendingEntry',


//PENDING CHECKING
'LAB_OPERATION_RESULT_ENTRY_SEARCH_PENDING_CHECKING': environment.basePathACM + 'FuncForLOAppToSearchPendingChecking',
'LAB_OPERATION_RESULT_ENTRY_GET_RESULT_ENTRY_FOR_CHECKING': environment.basePathACM + 'FuncForLOAppToGetResultEntryForChecking',
'LAB_OPERATION_RESULT_ENTRY_ADD_PENDING_CHECKING': environment.basePathACM + 'FuncForLOAppToAddPatientTestResultPendingChecking',

//MICROBIOLOGY RESULT TYPE IN PENDING CHECKING
'LAB_OPERATION_RESULT_ENTRY_GET_MICROBIOLOGY_ANTIBIOTIC_LIST_PENDING_CHECKING': environment.basePathACM + 'FuncForLOAppToGetMicroTestForPendingChecking',

//AUTHENTICATED
'LAB_OPERATION_RESULT_ENTRY_SEARCH_AUTHENTICATED': environment.basePathACM + 'FuncForLOAppToSearchAuthenticated',
'LAB_OPERATION_RESULT_ENTRY_GET_RESULT_ENTRY_FOR_AUTHENTICATED': environment.basePathACM + 'FuncForLOAppToGetResultEntryForAuthenticated',
'LAB_OPERATION_RESULT_ENTRY_GET_REVERSE_RESULT_FOR_AUTHENTICATED': environment.basePathACM + 'FuncForLOAppToGetResultEntryForAuthenticatedReverse',

'LAB_OPERATION_RESULT_ENTRY_UPDATE_REVERSE_RESULT': environment.basePathACM + 'FuncForLOAppToUpdreverseresult',
'LAB_OPERATION_RESULT_ENTRY_GET_DEMOGRAPHIC': environment.basePathACM + 'FuncForLOAppToGetPatientDemographic',
'LAB_OPERATION_RESULT_ENTRY_UPDATE_DEMOGRAPHIC': environment.basePathACM + 'FuncForLOAppToUpdatePatientDemographic',

//PENDING Authentication
'LAB_OPERATION_RESULT_ENTRY_SEARCH_PENDING_AUTHENTICATION': environment.basePathACM + 'FuncForLOAppToSearchPendingAuthentication',
'LAB_OPERATION_RESULT_ENTRY_GET_RESULT_ENTRY_AUTHENTICATION': environment.basePathACM + 'FuncForLOAppToGetResultEntryForAuthentication',
'LAB_OPERATION_RESULT_ENTRY_ADD_PATIENT_TEST_RESULT_AUTHENTICATION': environment.basePathACM + 'FuncForLOAppToAddPatientTestResultPendingAuthentication',
'LAB_OPERATION_RESULT_ENTRY_REPROCESS_SAMPLE': environment.basePathACM + 'FuncForLOAppToUpdreprocesssample',
'LAB_OPERATION_RESULT_ENTRY_MARK_PSN': environment.basePathACM + 'FuncForLOAppToAddSampleMarkPSNAuthentication',
'LAB_OPERATION_RESULT_ENTRY_INSTRUMENT_TECHNIQUE': environment.basePathACM + 'FuncForLOAppToGetElementInstrTechnique',
'LAB_OPERATION_RESULT_ENTRY_ELEMENT_REFERENCE_RANGE': environment.basePathACM + 'FuncForLOAppToGetElementReferenceRange',

//Result Entry Problem Sample 
'LAB_OPERATION_RESULT_ENTRY_PROBLEM_SAMPLE_SEARCH': environment.basePathACM + 'FuncForLOAppToSearchResultProblemSample',
'LAB_OPERATION_RESULT_ENTRY_PROBLEM_SAMPLE_GET': environment.basePathACM + 'FuncForLOAppToGetResultProblemSample',
'LAB_OPERATION_RESULT_ENTRY_PROBLEM_SAMPLE_SUBMIT': environment.basePathACM + 'FuncForLOAppToAddTNP',
'LAB_OPERATION_RESULT_ENTRY_PROBLEM_DOCUMENT_DOWNLOAD': environment.basePathACM + 'FuncForLOAppToGetPatientTestDocument',

//TNP
'LAB_OPERATION_RESULT_ENTRY_VIEW_TNP': environment.basePathACM + 'FuncForLOAppToGetPatientTnpTestApproval',
'LAB_OPERATION_RESULT_ENTRY_SEARCH_TNP': environment.basePathACM + 'FuncForLOAppToSearchPatientTnpTestApproval',
'LAB_OPERATION_RESULT_ENTRY_ADD_TNP_TEST_APPROVAL': environment.basePathACM + 'FuncForLOAppToAddPatientTnpTestApproval',
'LAB_OPERATION_RESULT_ENTRY_GET_TNP_TEST_DOCUMENT': environment.basePathACM + 'FuncForLOAppToGetPatientTnpTestDocument',

//REPORT AVAILABLE
'LAB_OPERATION_RESULT_ENTRY_GET_REPORT_AVAILABLE_LIST': environment.basePathACM + 'FuncForLOAppToSearchTestReportAvailable',
'GET_PATIENT_PREVIEW_REPORT_AVAILABLE_LIST': environment.basePathACM + 'FuncForLOAppToPatientReportAvailable',
'GET_PATIENT_PREVIEW_REPORT_PRINT_ATTACHMENT_LIST': environment.basePathACM + 'FuncForLOAppToAdvertisementAttachNameGet',

'GET_PATIENT_TEST_LIST_REPORT': environment.basePathACM + 'FuncForLOAppToPatientTestReportAvailable',
// 'GET_PATIENT_TEST_LAB_VIEW_REPORT': '/api/LabReport/PreViewReport',
'GET_PATIENT_TEST_LAB_VIEW_REPORT': '/api/LabReport/PreViewReportBuilder',
// 'GET_PATIENT_TEST_LAB_VIEW_REPORT_ON_REPORT_AVAILABLE': '/api/LabReport/ViewReport', //(new change) USE Only for Report available screen on result entry.
// 'GET_PATIENT_TEST_LAB_VIEW_REPORT_ON_REPORT_AVAILABLE': '/api/LabReport/ViewReportBuilder', //(new change) USE Only for Report available screen on result entry.
'GET_PATIENT_TEST_LAB_VIEW_REPORT_ON_REPORT_AVAILABLE': '/api/LabReportBuilder/ViewReportBuilder', //(new change) USE Only for Report available screen on result entry.

'GET_PATIENT_TEST_SEND_MAIL_PATIENT_REPORT': environment.basePathACM + 'FuncForLOAppToSendMailPatientReport',


//*********************  LAB OPERATIION END ***************//

// PSN ACKNOWLEDGE
'PATIENT_PSN_ACKNOWLEDGE_CLIENT': environment.basePathACM + 'FuncForPMAppToUpdAcknowledgePSNClient',
'PATIENT_PSN_ACKNOWLEDGE_LAB': environment.basePathACM + 'FuncForPMAppToUpdAcknowledgePSNLab',

//PSN GRID (PROBLEM SAMPLE)
'PATIENT_PSN_SEARCH_SAMPLE_LAB': environment.basePathACM + 'FuncForPMAppToSearchPatientProblemSampleLab',
'PATIENT_PSN_SEARCH_SAMPLE_LAB_HISTORY': environment.basePathACM + 'FuncForPMAppToSearchPatientProblemSampleLabHist',

'PATIENT_PSN_SEARCH_SAMPLE_CLIENT': environment.basePathACM + 'FuncForPMAppToSearchPatientProblemSampleClient',
'PATIENT_PSN_SEARCH_SAMPLE_CLIENT_HISTORY': environment.basePathACM + 'FuncForPMAppToSearchPatientProblemSampleClientHist',

//PSN GRID (PROBLEM SAMPLE Collection LAB)
'PATIENT_PSN_COLLECT_SAMPLE_LAB': environment.basePathACM + 'FuncForPMAppToGetPSNMarkSampleLab',
'PATIENT_PSN_COLLECT_SAMPLE_DOC_LAB': environment.basePathACM + 'FuncForPMAppToGetPSNMarkSampleWithDocLab',
'PATIENT_PSN_COLLECT_DOC_LAB': environment.basePathACM + 'FuncForPMAppToGetPSNMarkDocLab',

//PSN GRID (PROBLEM SAMPLE Collection Client)
'PATIENT_PSN_COLLECT_SAMPLE_CLIENT': environment.basePathACM + 'FuncForPMAppToGetPSNMarkSampleClient',
'PATIENT_PSN_COLLECT_SAMPLE_DOC_CLIENT': environment.basePathACM + 'FuncForPMAppToGetPSNMarkSampleWithDocClient',
'PATIENT_PSN_COLLECT_DOC_CLIENT': environment.basePathACM + 'FuncForPMAppToGetPSNMarkDocClient',

//CLIENT REFERING DOCTOR
'CLIENT_TYPE': environment.basePathACM + 'FuncForACMAppToGetRefDoctrClientTypeList',
'GET_SEARCH_LIST': environment.basePathACM + 'FuncForACMAppToSearchRefDoctrClient ',
'ADD_CLIENT_TYPE_LIST': environment.basePathACM + 'FuncForACMAppToAddRefDoctrClient ',
'GET_CLIENT_LIST': environment.basePathACM + 'FuncForACMAppToGetRefDoctrClient',
'GET_CLIENT_LIST_DELETE': environment.basePathACM + 'FuncForACMAppToDeleteRefDoctrClient',

// INCLUDE DOCTOR TYPE 
'GET_DOCTOR_TYPE_LIST': environment.basePathACM + 'FuncForACMAppToGetRefDoctorTypeList',

// MIS Module
'GET_MIS_CLIENT_LEDGER_REPORT': environment.basePathACM + 'FuncForMISAppToClientledgerReport',
'GET_MIS_DAILY_SALES_REPORT': environment.basePathACM + 'FuncForMISAppToDailySalesReport',
'GET_MIS_SEARCH_BILLING_REPORT': environment.basePathACM + 'FuncForMISAppToSearchBillingReport',
'GET_MIS_BILLING_REPORT_RECEIPT': environment.basePathACM + 'FuncForMISAppToSearchBillingReportPatientReceipt',
'GET_MIS_DAILY_SALES_REPORT_EXPORT_EXCEL': environment.basePathACM + 'FuncForMISAppToDailySalesReportExportExl',
'GET_MIS_LEDGER_REPORT_EXPORT_EXCEL': environment.basePathACM + 'FuncForMISAppToClientledgerReportExportExl',
'GET_MIS_SEARCH_BILLING_REPORT_EXPORT_EXCEL': environment.basePathACM + 'FuncForMISAppToSearchBillingReportExportExl',
'GET_MIS_CLIENT_REPORT': environment.basePathACM + 'FuncForMISAppToClientReport',
'GET_MIS_CLIENT_REPORT_EXPORT_EXCEL': environment.basePathACM + 'FuncForMISAppToClientReportExportExl',
'GET_MIS_GENERETED_INVOICE_REPORT': environment.basePathACM + 'FuncForMISAppToGenerateInvoiceReport',
'GET_MIS_GENERETED_INVOICE_REPORT_EXPORT_EXCEL': environment.basePathACM + 'FuncForMISAppToGenerateInvoiceReportExportExl',
'GET_MIS_CAMP_REPORT': environment.basePathACM + 'FuncForMISAppToCampReport',
'GET_MIS_CAMP_REPORT_EXPORT_EXCEL': environment.basePathACM + 'FuncForMISAppToCampReportExportExl',
'GET_MIS_CO_PROMOTION_BRANDING_REPORT': environment.basePathACM + 'FuncForMISAppToCoPromotionBrandingReport',
'GET_MIS_CO_PROMOTION_BRANDING_REPORT_EXPORT_EXCEL': environment.basePathACM + 'FuncForMISAppToCoPromotionBrandingReportExportExl',
'GET_MIS_RECEIPT_REGISTER':  environment.basePathACM +'FuncForMISAppToReceiptRegister',
'GET_MIS_RECEIPT_REGISTER_EXCEL': environment.basePathACM +'FuncForMISAppToReceiptRegisterExportExl',
'GET_MIS_LCodes':  environment.basePathACM +'FuncForMISAppTolcode',
'GET_MIS_LCode_EXCEL': environment.basePathACM +'FuncForMISAppTolcodeExportExl',
'TEST_WISE_REVENUE_GET': environment.basePathACM +'FuncForMISAppTotestwiserevenue',
'TEST_WISE_REVENUE_DOWNLOAD_EXCEL': environment.basePathACM +'FuncForMISAppTotestwiserevenueExpoertExcel',



// ATTACHMENT ADVERTISEMENT
'ADD_ATTACHMENT': environment.basePathACM + 'FuncForACMAppToAddAdvertisement',
'USER_ATTACHMENT_SEARCH': environment.basePathACM + 'FuncForACMAppToAdvertisementSearch',
'ATTACHMENT_STATUS_UPDATE': environment.basePathACM + 'FuncForACMAppToStatusUpdateAdvertisement',
'ATTACHMENT_GET': environment.basePathACM + 'FuncForACMAppToGetAdvertisement',
'UPDATE_ATTACHMENT': environment.basePathACM + 'FuncForACMAppToUpdateAdvertisement',

// Routes Logistic APIs

'LOGISTIC_ROUTES_OUTLET_GET_LAT_LNG': environment.basePathACM + 'FuncForLMAppToGetRouteslongitudelatitude',
'LOGISTIC_ROUTES_NAME_SEARCH': environment.basePathACM + 'FuncForLMAppToRoutesSearch',
'LOGISTIC_ROUTES_GET_TOTAL_DISTANCE': environment.basePathACM + 'FuncForLMAppToGetRoutestotaldistance',
'LOGISTIC_ROUTES_ADD_ROUTE': environment.basePathACM + 'FuncForLMAppToAddRoutes',
'LOGISTIC_ROUTES_ROUTE_GET': environment.basePathACM + 'FuncForLMAppToGetRoutes',
'LOGISTIC_ROUTES_ROUTE_UPDATE': environment.basePathACM + 'FuncForLMAppToUpdateRoutes',
'LOGISTIC_ROUTES_STATUS_UPDATE': environment.basePathACM + 'FuncForLMAppToStatusUpdateRoutes',

// Rider Logistic APIs
'LOGISTIC_RIDER_NAME_SEARCH': environment.basePathACM + 'FuncForLMAppToRiderSearch',
'LOGISTIC_RIDER_ADD': environment.basePathACM + 'FuncForLMAppToAddRider',
'LOGISTIC_RIDER_CENTERLIST': environment.basePathACM + 'FuncForLMAppToGetOutLetSearch',
'LOGISTIC_RIDER_GET': environment.basePathACM + 'FuncForLMAppToGetRider',
'LOGISTIC_RIDER_UPDATE': environment.basePathACM + 'FuncForLMAppToUpdateRider',
'LOGISTIC_RIDER_STATUS_UPDATE': environment.basePathACM + 'FuncForLMAppToStatusUpdateRider',

// Storage APIs
'LAB_OPERATION_SAMPLE_STORAGE_SEARCH': environment.basePathACM + 'FuncForLOAppToSearchSampleStorage',
'LAB_OPERATION_SAMPLE_STORAGE_SCAN': environment.basePathACM + 'FuncForLOAppToGetSampleStorageScan',
'LAB_OPERATION_SAMPLE_STORAGE_ADD': environment.basePathACM + 'FuncForLOAppToAddSampleStorage',

// RoutrRider Mapping Logistic APIs
'LOGISTIC_RIDER_ROUTE_MAPPING_SEARCH': environment.basePathACM + 'FuncForLMAppToRiderRouteMappingSearch',
'LOGISTIC_RIDER_ROUTE_MAPPING_IMPORTTOEXCEL': environment.basePathACM + 'FuncForLMAppToRiderRouteMappingExcelUpload',
'LOGISTIC_RIDER_ROUTE_MAPPING_EXPORTTOEXCEL': environment.basePathACM + 'FuncForLMAppToRiderRouteMappingExportExl',
'LOGISTIC_RIDER_ROUTE_MAPPING_DELIVERY_TO': environment.basePathACM + 'FuncForLMAppToDeliveryTo',
'LOGISTIC_RIDER_ROUTE_MAPPING_DELIVERY_LOCATION': environment.basePathACM + 'FuncForLMAppTodeliveryLocationByRoute',
'LOGISTIC_RIDER_ROUTE_MAPPING_ROUTE_BYNAME': environment.basePathACM + 'FuncForLMAppToGetRouteByName',
'LOGISTIC_RIDER_ROUTE_MAPPING_RIDER_BYNAME': environment.basePathACM + 'FuncForLMAppToGetRiderByName',
'LOGISTIC_RIDER_ROUTE_MAPPING_ADD': environment.basePathACM + 'FuncForLMAppToAddRouteMapping',
'LOGISTIC_RIDER_ROUTE_MAPPING_GET': environment.basePathACM + 'FuncForLMAppToGetRouteMapping',
'LOGISTIC_RIDER_ROUTE_MAPPING_UPDATE': environment.basePathACM + 'FuncForLMAppToUpdateRouteMapping',
'LOGISTIC_RIDER_ROUTE_MAPPING_GET_RECCURENCE_DAY': environment.basePathACM + 'FuncForLMAppToGetReccurenceDays',
'LOGISTIC_RIDER_ROUTE_MAPPING_GET_APPLICABLE_DAY_GET': environment.basePathACM + 'FuncForLMAppToGetApplicableDateGet',
'LOGISTIC_RIDER_ROUTE_MAPPING_SEARCH_HISTORY': environment.basePathACM + 'FuncForLMAppToRiderRouteMappingSearchHist',

//NEW LEAD TEST BOOKING
'Lead_TEST_GET_TEST_LIST': environment.basePathACM + 'FuncForLMSAppToGetTestName',
'Lead_GET_LB_TEST_ESTIMATION': environment.basePathACM + 'FuncForLMSAppToSearchTestEstimation',
'LEAD_TEST_CHECK_TEST_PROFILE': environment.basePathACM + 'FuncForLMSAppToGetTestProfile',
'LEAD_ADD_TEST': environment.basePathACM + 'FuncForLMSAppToAddPatientTest',
'LEAD_GET_TEST_LEAD_INFO': environment.basePathACM + 'FuncForLMSAppToGetNewLead ',
'LEAD_UPDATE_TEST': environment.basePathACM + 'FuncForLMSAppToUpdatePatientTest',
'LEAD_TEST_PATIENT_LIST': environment.basePathACM + 'FuncForLMSAppToGetLeadPatient',
'LEAD_TEST_PATIENT_TEST_VALIDATE': environment.basePathACM + 'FuncForLMSAppToGetLeadPatientTestValidate',
'LEAD_ELEMENT_LIST_ON_TEST': environment.basePathACM + 'FuncForLMSAppToGetTestElement',

// LEAD INFO EDIT ADDITIONAL PATIENT
'LEAD_GET_ADDITIONAL_PATIENT': environment.basePathACM + 'FuncForLMSAppToGetAdditionalPatient',
'LEAD_SAVE_UPDATE_ADDITIONAL_PATIENT': environment.basePathACM + 'FuncForLMSAppToUpdateAdditionalPatient',
'LEAD_Delete_ADDITIONAL_PATIENT': environment.basePathACM + 'FuncForLMSAppToDeleteAdditionalPatient',


// LMS LEAD LIST
'LEAD_SEARCH': environment.basePathACM + 'FuncForLMSAppToSearchLead',
'LEAD_SEARCH_SECONDARY': environment.basePathACM + 'FuncForLMSAppToSearchLeadSecondary',
'LEAD_UPLOAD_EXCEL': environment.basePathACM + 'FuncForLMSAppToLeadExcelUpload',
'LMS_TEST_GET_TRACKING': environment.basePathACM + 'FuncForPMAppToGetLMSPatientTracking',
'LMS_SEND_PAYMENT_LINK': environment.basePathACM + 'FuncForLMSAppToSendPaymentLink',
'LMS_EXPORT_CITY_EXCEL': environment.basePathACM + 'FuncForLMSAppToFuncForLMSAppToGetCityExportExl',
'LMS_EXPORT_REFERENCE_EXCEL': environment.basePathACM + 'FuncForLMSAppToGetLeadreferenceExportExl',
'LMS_CLICK_TO_DIAL_CUSTOMER': environment.basePathACM + 'FuncForLMSAppClickToDialCustomer',

//Lead Management Sysytem APIs
"LEAD_MANGEMENT_BILLING_CODE": environment.basePathACM + 'FuncForACMAppToGetCommonddl',
"LEAD_MANGEMENT_BILLING_AUTOSEARCH": environment.basePathACM + 'FuncForLMSAppToCorporateClientList',
"LEAD_MANGEMENT_ADD": environment.basePathACM + 'FuncForLMSAppToAddNewLead',
"LEAD_MANGEMENT_REMINDER": environment.basePathACM + 'FuncForLMSAppToAddLeadReminder',
"LEAD_MANGEMENT_CHECK_HISTORY": environment.basePathACM + 'FuncForLMSAppToGetLeadReminderHist',
"LEAD_MANGEMENT_CHECK_STATUS": environment.basePathACM + 'FuncForLMSAppToGetLeadReminderStatus',
"LEAD_MANGEMENT_UPDATE": environment.basePathACM + 'FuncForLMSAppToUpdateNewLead',
"GET_LEAD_MANGEMENT": environment.basePathACM + 'FuncForLMSAppToGetNewLead',
"GET_NEARBY_CENTERS": environment.basePathACM + 'FuncForLMSAppToGetNearByClientCenter',
"LEAD_MANGEMENT_BILLING_CLIENT_AUTOSEARCH": environment.basePathACM + 'FuncForACMAppToGetCommonmasters',
"LEAD_MANGEMENT_AUTO_REMINDER": environment.basePathACM + 'FuncForLMSAppToGetAutoLeadReminder',
"LEAD_MANGEMENT_LEAD_RELEASE": environment.basePathACM + 'FuncForLMSAppToUpdateLeadRelease',
"LEAD_MANGEMENT_LEAD_Payment": environment.basePathACM + 'FuncForLMSAppToGetOrderId',
"LEAD_MANGEMENT_LEAD_Payment_SIGNATURE_VERIFY": environment.basePathACM + 'FuncForLMSAppToGetSignatureverification',
"LEAD_MANGEMENT_LEAD_BOOKING_SUMMARY": environment.basePathACM + 'FuncForLMSAppToGetleadbookingsummary',

//LMS SCHUEDULE
"LEAD_GET_CLIENT_CENTER_LIST": environment.basePathACM + 'FuncForLMSAppToGetClientcenterList',
"LEAD_GET_PHELEBO_USER_LIST": environment.basePathACM + 'FuncForLMSAppToGetPhleboUserList',
"LEAD_SCHEDULE_ADD": environment.basePathACM + 'FuncForLMSAppToAddLeadSchedule',
"LEAD_RESCHEDULE_GET": environment.basePathACM + 'FuncForLMSAppToGetLeadSchedule',
"LEAD_SCHEDULE_UPDATE": environment.basePathACM + 'FuncForLMSAppToUpdateLeadSchedule',
"LEAD_SCHEDULE_LOG": environment.basePathACM + 'FuncForLMSAppToGetLeadScheduleLog',
"LEAD_GET_HOME_COLLECTION": environment.basePathACM + 'FuncForLMSAppToGetLeadHomeCollection',
//Phlebo 
"ADD_PHLEBO_MANG": environment.basePathACM + 'FuncForPhMAppToAddPhlebo',
"SEARCH_PHLEBO_MANG": environment.basePathACM + 'FuncForPhMAppToPhleboSearch',
"DRAFTSEARCH_PHLEBO_MANG": environment.basePathACM + 'FuncForPhMAppToPhleboSearchDraft',
"SAVE_SCHDULE_PHLEBO_MANG_": environment.basePathACM + 'FuncForPhMAppToAddUpdatePhleboworkschedule',
'PHLEBO_SEARCH_STATUS_UPDATE': environment.basePathACM + 'FuncForPhMAppToStatusUpdatePhlebo',
'GET_PHLEBO_MGMT': environment.basePathACM + 'FuncForPhMAppToGetPhlebo',
'UPDATE_PHLEBO_MGMT': environment.basePathACM + 'FuncForPhMAppToUpdatePhlebo',

// LEAD CANCEL TEST
"LEAD_CANCEL_TEST": environment.basePathACM + 'FuncForLMSAppToGetLeadCancelTest',
"ADD_LEAD_CANCEL_TEST": environment.basePathACM + 'FuncForLMSAppToAddLeadCancelTest',

// LEAD REQUEST TNP
"LEAD_GET_REQUEST_FOR_TNP": environment.basePathACM + 'FuncForLMSAppToGetLeadTNP',
"LEAD_ADD_REQUEST_FOR_TNP": environment.basePathACM + 'FuncForLMSAppToAddLeadTNP',

//Send Mail API 
"SEND_MAIL": 'https://api.pepipost.com/V5.1/mail/send',


  // CAMP
  'CAMP_SEARCH': environment.basePathACM + 'FuncForSCCMAppToSearchCamp',
  'CAMP_STATUS_UPDATE': environment.basePathACM + 'FuncForSCCMAppToStatus',
  'CAMP_DETAILS_GET': environment.basePathACM + 'FuncForSCCMAppToGetCamp',
  'CAMP_ADD': environment.basePathACM + 'FuncForSCCMAppToAddCampInfo',
  'CAMP_UPDATE': environment.basePathACM + 'FuncForSCCMAppToUpdateCampInfo',
  'CAMP_TEST_SEARCH': environment.basePathACM + 'FuncForSCCMAppToTestSearch',
  'CAMP_OUTLET_SEARCH': environment.basePathACM + 'FuncForSCCMAppToSearchOutletType',
  'CAMP_OUTLET_ADD': environment.basePathACM + 'FuncForSCCMAppToAddCampServedBy',
  'CAMP_OUTLET_STATUS_UPDATE': environment.basePathACM + 'FuncForSCCMAppToUpdateStatusOutlettype',
  'CAMP_VIEW_DETAIL_SEARCH':environment.basePathACM+'FuncForSCCMAppToSearchCamplog',
  'CAMP_VIEW_DETAIL_GET':environment.basePathACM+'FuncForSCCMAppToGetCamplog',
  'CAMP_COPY_AND_ACTIVATE':environment.basePathACM+'FuncForSCCMAppToAddCopyCamp',

  // CRM Get Estimation Tab
  'GETESTIMATION_LOCATION_AUTOSEARCH': environment.basePathACM + 'FuncForACMAppToGetCommonmasters',
  'GETESTIMATION_TESTNAME_AUTOSEARCH': environment.basePathACM + 'FuncForCRMAppToGetTestNameLabBased',
  'GETESTIMATION_TESTNAME_ESTIMATION': environment.basePathACM + 'FuncForCRMAppToSearchTestEstimation',
  'GETESTIMATION_CHECKPROFILE': environment.basePathACM + 'FuncForCRMAppToGetTestProfileCenter',
  
// CRM UPCOMING CAMP Tab
'UPCOMING_CAMP_SEARCH': environment.basePathACM + 'FuncForCRMAppToSearchCamps',

// CRM MIS PSN TAB
'PSN_MIS_SEARCH': environment.basePathACM + 'FuncForCRMAppToCRMPSNMISSearch',

// CRM MIS TNP TAB
'TNP_MIS_SEARCH': environment.basePathACM + 'FuncForCRMAppToCRMTNPMISSearch',

// CRM TICKET MIS
'TICKET_MIS_SEARCH': environment.basePathACM + 'FuncForCRMAppToSearchTicketMIS',
'TICKET_MIS_EXPORTTOEXCEL': environment.basePathACM + 'FuncForCRMAppToTicektMISSearchReportExportExl',

// CRM TICKET LOG MIS
'TICKET_LOG_MIS_SEARCH': environment.basePathACM + 'FuncForCRMAppToSearchTicketLogMIS',
'TICKET_LOG_MIS_EXPORTTOEXCEL': environment.basePathACM + 'FuncForCRMAppToTicektLogMISSearchReportExportExl',

// CRM MIS PSN TAB EXPORT EXCEL
'PSN_MIS_EXPORTTOEXCEL': environment.basePathACM + 'FuncForCRMAppToCRMPSNMISSearchReportExportExl',

// CRM MIS TNP TAB EXPORT EXCEL
'TNP_MIS_EXPORTTOEXCEL': environment.basePathACM + 'FuncForCRMAppToCRMTNPMISSearchReportExportExl',


// CRM ADD LEAD Tab
'CRM_LEAD_SEARCH': environment.basePathACM + 'FuncForCRMAppToSearchLead',
'CRM_LEAD_TRACKING': environment.basePathACM + 'FuncForCRMAppToGetPatientTracking',


// CRM PROBLEM SAMPLE Tab
'CRM_PROBLEM_SAMPLE_SEARCH': environment.basePathACM + 'FuncForCRMAppToSearchResultProblemSample',
'CRM_PROBLEM_SAMPLE_View_LOG': environment.basePathACM + 'FuncForCRMAppToProblemSampleRemarkGet',
'CRM_PROBLEM_SAMPLE_ADD_PSN_REMARK': environment.basePathACM + 'FuncForCRMAppToProblemSampleRemarkAdd',

// CRM SAMPLE REPORT
'CRM_SAMPLE_REPORT_SEARCH': environment.basePathACM + 'FuncForCRMAppToSampleReport',
'CRM_SAMPLE_REPORT_GENERATE': '/api/CRMReport/ViewSampleReport',

// CRM LIVE CALL
'CRM_LIVE_CALL_TEST_HISTORY': environment.basePathACM + 'FuncForCRMAppToGetTestHistory',
'CRM_LIVE_CALL_Call_HISTORY': environment.basePathACM + 'FuncForCRMAppToCallHistory',
'CRM_LIVE_CALL_SUBMIT': environment.basePathACM + 'FuncForCRMAddCallLog',
'CRM_LIVE_CALL_TRACK': environment.basePathACM + 'FuncForCRMAppToGetPatientTestTracking',
'CRM_LIVE_CALL_SEARCH_PATIENT_LIST': environment.basePathACM + 'FuncForCRMAppToGetPatientNameList',
'CRM_LIVE_ADD_TICKET': environment.basePathACM + 'FuncForCRMAppToVerifyTicket',
'VALIDATE_CRM_USER_API': environment.basePathACM + 'FuncForHomeAppToValidateCRMUsers',
'CRM_LIVE_SHARE': environment.basePathACM + 'FuncForCRMAppToSendMailReportTestHistory',
'CRM_SAMPLE_REPORT_SHARE': environment.basePathACM + 'FuncForCRMAppToSendMailSampleReportTestHistory',
'CRM_LIVE_CALL_Call_CUSTOMER': environment.basePathACM + 'FuncForCRMAppToCallCustomer',
'CRM_CLICK_TO_DIAL_CUSTOMER': environment.basePathACM + 'FuncForCRMAppClickToDialCustomer',
'CRM_ADD_TICKET_LOG_REMARK': environment.basePathACM + 'FuncForCRMAppToAddTicketLogRemarks',
'CRM_GET_TICKET_LOG_REMARK': environment.basePathACM + 'FuncForCRMAppToGetTicketLogRemarks',
'CRM_Open_CLOSE_TICKET_REMARK': environment.basePathACM + 'FuncForCRMAppToUpdateTicketHistory',

// INVENTORY MODULE API
'MASTER_INVENTORY_LIST_SEARCH': environment.basePathACM + 'FuncForInvAppToSearchInventoryItem',
'MASTER_INVENTORY_LIST_UPDATE': environment.basePathACM + 'FuncForInvAppToUpdateInventoryItem',
'SEARCH_OUTLET_NAME':environment.basePathACM + 'FuncForInvAppToSearchoutletname',
'SEARCH_OUTLET_ITEM':environment.basePathACM + 'FuncForInvAppToSearchoutletItem',
'Add_OUTLET_ITEM':environment.basePathACM + 'FuncForInvAppToAddOutLetItem',
'GET_CURRENT_STOCK_LIST':environment.basePathACM + 'FuncForInvAppToSearchoutletItemStock',
'SEARCH_INVETRORY_IEM_REQUEST':environment.basePathACM + 'FuncForInvAppToSearchInventoryItemRequest',
'ADD_INVENTORY_ITEM_REQUEST':environment.basePathACM + 'FuncForInvAppToAddInventoryItemRequest',
'GET_INVENTORY_ITEM_REQUEST_LIST':environment.basePathACM + 'FuncForInvAppToSearchItemRequest',
'GET_INVENTORY_ITEM_REQUEST_DRAFT':environment.basePathACM + 'FuncForInvAppToGetInventoryItemRequest',
'UPDATE_INVENTORY_ITEM_REQUEST_DRAFT':environment.basePathACM + 'FuncForInvAppToUpdateRequestItem',
'SEARCH_ITEM_REQUEST_APPROVAL':environment.basePathACM + 'FuncForInvAppToSearchItemRequestApproval',
'GET_INVENTORY_ITEM_APPROVAL':environment.basePathACM + 'FuncForInvAppToGetInventoryItemApproval',
'UPDATE_ITEM_REQUEST_APPROVAL':environment.basePathACM + 'FuncForInvAppToUpdateItemRequestApproval',
'GET_ITEM_LIST_FOR_WASTAGE_ENTRY':environment.basePathACM + 'FuncForInvAppTowastageSearchoutletItem',
'ADD_WASTAGE':environment.basePathACM + 'FuncForInvAppToAddwastageItemRequest',
'GET_WASTAGE_LIST':environment.basePathACM + 'FuncForInvAppTowastagesesearch',
'VIEW_WASTAGE':environment.basePathACM + 'FuncForInvAppTowastageview',
'SEARCH_RECEIVE_ITEM_REQUEST': environment.basePathACM + 'FuncForInvAppToSearchReceiveItemRequest',
'ADD_RECEIVE_STOCK': environment.basePathACM + 'FuncForInvAppToSendItemRequesttoSAP',
'UPDATE_NON_TRANSACTIONAL_ITEM': environment.basePathACM + 'FuncForInvAppToUpdatNonTrasactionalItem',
'ADD_RECEIVE_ITEM_REQUEST' : environment.basePathACM + 'FuncForInvAppToAddReceiveItemRequest',
'VIEW_APPROVAL_WORKFLOW' : environment.basePathACM + 'FuncForInvAppToViewApprovalWorkflow',

//Camp Bulk registration Client
'PATIENT_GET_BILLING_CODE_LIST_BULK_CAMP_REG': environment.basePathACM + 'FuncForPMAppToGetBillingCodecampClient',
'GET_CLIENT_CAMP_LIST_FOR_EXCEL_UPLOAD':  environment.basePathACM + 'FuncForPMAppToGetClientCampListForExcelUpload',
'PATIENT_CAMP_BULK_REGISTRATION_CLIENT':  environment.basePathACM + 'FuncForPMAppToAddPatientcampbulkbookingclient',
//Camp Bulk registration Lab
'PATIENT_GET_BILLING_CODE_LIST_BULK_CAMP_REG_LAB': environment.basePathACM + 'FuncForPMAppToGetBillingCodecampLab',
'PATIENT_CAMP_BULK_REGISTRATION_LAB':  environment.basePathACM + 'FuncForPMAppToAddPatientcampbulkbookinglab',

// Result Entry multi test get api on a visit id
'RESULT_ENTRY_GET_ELEMENT_ON_SELECTED_VISIT_ID': environment.basePathACM + 'FuncForLOAppToGetVisitIdTestElement',
'RESULT_ENTRY_ADD_ELEMENT_ON_SELECTED_VISIT_ID': environment.basePathACM + 'FuncForLOAppToAddVisitIdTestResult',

// Dropdown for booking location and performing location
'RESULT_ENTRY_BOOKING_LOCATION_DROPDOWN': environment.basePathACM + 'FuncForLOAppToGetBookingLocList',
'RESULT_ENTRY_PERFORMING_LOCATION_DROPDOWN': environment.basePathACM + 'FuncForLOAppToGetPerlocList',

//LEAD REFERENCE MASTER
  'LEAD_REFERENCE_SEARCH': environment.basePathACM + 'FuncForACMAppToSearchleadreference',
  'LEAD_REFERENCE_ADD': environment.basePathACM + 'FuncForACMAppToAddleadreference',
  'LEAD_REFERENCE_GET': environment.basePathACM + 'FuncForACMAppToGetleadreference',
  'LEAD_REFERENCE_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateleadreference',
  'LEAD_REFERENCE_UPDATE_STATUS': environment.basePathACM + 'FuncForACMAppToStatusUpdateleadreference',

// SALUTATION API's

  'SALUTATION_SEARCH': environment.basePathACM + 'FuncForACMAppToSearchSalutation',
  'SALUTATION_ADD': environment.basePathACM + 'FuncForACMAppToAddSalutation',
  'SALUTATION_GET': environment.basePathACM + 'FuncForACMAppToGetsalutationby',
  'SALUTATION_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateSalutation',
  'SALUTATION_UPDATE_STATUS': environment.basePathACM + 'FuncForACMAppToStatusUpdatesalutation',
  
  // LMS MIS
  'LMS_MIS_EXPORT_EXCEL': environment.basePathACM + 'FuncForLMSAppToLeadMISSearchReportExportExl',

  // Test Module Element Add Group Get

  'ELEMENT_TO_GET_GROUP_TEMP_DESC': environment.basePathACM + 'FuncForTMAppToGetTestGroup',
  'ELEMENT_TO_ADD_GROUP_TEMP_DESC': environment.basePathACM + 'FuncForTMAppToAddtestgroup',

  // Active Camp
  'ACTIVE_CAMP_TEST_SEARCH': environment.basePathACM + 'FuncForSCCMAppToSearchCampList',
  'GET_ACTIVE_CAMP_OUTLET_LIST': environment.basePathACM + 'FuncForSCCMAppToGetOutlet',
  'SAVE_ACTIVE_CAMP_DETAILS': environment.basePathACM + 'FuncForSCCMAppToAddCampActivationInfo',
  'UPDATE_ACTIVE_CAMP_DETAILS': environment.basePathACM + 'FuncForSCCMAppToUpdateCampActivationInfo',
  'SEARCH_ACTIVE_CAMP_LIST': environment.basePathACM + 'FuncForSCCMAppToSearchActivationCamp',
  'DELETE_ACTIVE_CAMP_OUTLET': environment.basePathACM +'FuncForSCCMAppToDeleteOutlettype',
  'GET_ACTIVE_CAMP_DETAILS': environment.basePathACM +'FuncForSCCMAppToGetCampActivation',
  //Report Available in patient management of cc
  'PATIENT_GET_REPORTS_AVAILABLE_OTHER_LIST': environment.basePathACM + 'FuncForPMAppToSearchPatientReportAvailableOtherClient',

  //upload outlet map
  'UPLOAD_ACTIVE_CAMP_OUTLET': environment.basePathACM +'FuncForSCCMAppToAddcampbulkoutlet',

  // MIS Module New Reports
  'GET_MIS_CLIENT_TYPE_SALE_REPORT': environment.basePathACM + 'FuncForMISAppToClientTypeSalesReport',
  'GET_MIS_CLIENT_TYPE_SALE_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppToClientTypeSalesReportExportExl',
  'GET_MIS_LABWISE_DAILY_SALE_REPORT' : environment.basePathACM + 'FuncForMISAppTolabwiseDailysale',
  'GET_MIS_LABWISE_DAILY_SALE_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppTolabwiseDailysaleExportExl',
  'GET_MIS_DATEWISE__SALES_REPORT' : environment.basePathACM + 'FuncForMISAppToDateWiseSalesReport',
  'GET_MIS_DATEWISE_SALES_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppToDatewiseSalesReportExportExl',
  'GET_MIS_TESTWISE_DAILY_SALE_REPORT' : environment.basePathACM + 'FuncForMISAppTotestwisedailysale',
  'GET_MIS_TESTWISE_DAILY_SALE_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppTotestwisedailysaleExportExl',

  // PSN Register MIS Report
  'GET_MIS_PSN_REGISTER_REPORT' : environment.basePathACM + 'FuncForMISAppToPSNReport',
  'GET_MIS_PSN_REGISTER_REPORT_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppToPSNReportExportExl',

   // TNP Register MIS Report
   'GET_MIS_TNP_REGISTER_REPORT' : environment.basePathACM + 'FuncForMISAppToTnpRegister',
   'GET_MIS_TNP_REGISTER_REPORT_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppToTnpRegisterExportExl',

   // Cash Collection MIS Report
   'GET_MIS_CASH_COLLECTION_REPORT' : environment.basePathACM + 'FuncForMISAppToCollectionReport',
   'GET_MIS_CASH_COLLECTION_REPORT_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppToCollectionReportExportExl',

   // Patient Detail with Test Breakup MIS Report
   'GET_PATIENT_DETAIL_TEST_BREAKUP_REPORT' : environment.basePathACM + 'FuncForMISAppToPatientdetailwithBreakup',
   'GET_PATIENT_DETAIL_TEST_BREAKUP_REPORT_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppToPatientdetailwithBreakupExportExl',

   // Sunday Camp Data MIS Report
    'GET_SUNDAY_CAMP_DATA_REPORT' : environment.basePathACM + 'FuncForMISAppToSundayCampReport',
    'GET_SUNDAY_CAMP_DATA_REPORT_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppToSundayCampReportExportExl',

   // TAT Report Test Basis MIS Report
    'GET_TAT_REPORT_TEST_BASIS_REPORT' : environment.basePathACM + 'FuncForMISAppToTATReport',
    'GET_TAT_REPORT_TEST_BASIS_REPORT_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppToTATReportExportExl',

  // Update Credit Limit in Client Billing (Credit)
  'CLIENT_CREDIT_UPDATE_LIMIT' : environment.basePathACM + 'FuncForCMAppToUpdateClientCreditLimit',

  //Log Out API Integration
  'LOG_OUT':environment.basePathACM + 'FuncForHomeAppToValidateSignOut',

  //OutSourced Lab Page
  'OUTSOURCED_LAB_SEARCH':environment.basePathACM +'FuncForTMAppToSearchoutsourcecenterTest',
  'OUTSOURCED_LAB_UPDATE_STATUS':environment.basePathACM +'FuncForTMAppToStatusoutsourcecenterTest',

  //CLIENT MANUAL RECHARGE ROLLING
  'CLIENT_MANUAL_RECHARGE_ROLLING_LOGS': environment.basePathACM + 'FuncForPMAppToGetRollingTrnLogDetails',
  'ROLLING_CLIENT_LIST_GET': environment.basePathACM + 'FuncForACMAppToGetRollingClientList',
  'CLIENT_MANUAL_RECHARGE_ROLLING_UPDATE': environment.basePathACM + 'FuncForCMAppToUpdateClientRollingAmount',
  'CLIENT_LIST_GET_INVOICE': environment.basePathACM + 'FuncForACMAppToGetCreditClientList',

  //outsource Test Details page
  //SAMPLE

'LAB_OPERATION_SEARCH_OUTSOURCE_TEST': environment.basePathACM + 'FuncForLOAppToSearchOutsourceSample',
'GET_OUTSOURCE_DETAILS': environment.basePathACM + 'FuncForLOAppToGetOutsourceLab',
'SAVE_OUTSOURCE_DETAILS': environment.basePathACM + 'FuncForLOAppToAddSampleOutsourcerLab',
'EXPORT_EXCEL_OUTSOURCE_DETAILS': environment.basePathACM + 'FuncForLOAppToOutsourceLabExportExl',

  // Area Master Page APIs
  'AREA_SEARCH': environment.basePathACM + 'FuncForACMAppToAreaSearch',
  'AREA_ADD': environment.basePathACM + 'FuncForACMAppToAddArea',
  'AREA_GET': environment.basePathACM + 'FuncForACMAppToGetArea',
  'AREA_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateArea',
  'AREA_UPDATE_STATUS': environment.basePathACM + 'FuncForACMAppToStatusUpdateArea',


  'USER_REG_CLUSTER_MAP_GET': environment.basePathACM + 'FuncForUMAppToGetUserclustermap',
  'USER_REG_CLUSTER_MAP_ADD': environment.basePathACM + 'FuncForUMAppToAddUserclustermap',
  'USER_REG_CLUSTER_MAP_RSM_GET': environment.basePathACM + 'FuncForUMAppToGetUsercluster',
  'USER_REG_CLUSTER_MAP_RSM_ADD': environment.basePathACM + 'FuncForUMAppToAddUsercluster',

  // Master Scheme Type API's
  'SCHEME_TYPE_SEARCH': environment.basePathACM + 'FuncForACMAppToSearchschemetype',
  'SCHEME_TYPE_UPDATE_STATUS': environment.basePathACM + 'FuncForACMAppToStatusUpdateschemetype',
  'SCHEME_TYPE_ADD': environment.basePathACM + 'FuncForACMAppToAddschemetype',
  'SCHEME_TYPE_GET': environment.basePathACM + 'FuncForACMAppToGetschemetype',
  'SCHEME_TYPE_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateschemetype',

  // Scheme 
  'SCHEME_TYPE_MASTER_LIST_GET': environment.basePathACM + 'FuncForACMAppToGetschemetypeList',
  'SCHEME_SEARCH_GET': environment.basePathACM + 'FuncForACMAppToSearchScheme',
  'SCHEME_BASIC_INFO_ADD': environment.basePathACM + 'FuncForACMAppToAddScheme',
  'SCHEME_BASIC_INFO_GET': environment.basePathACM + 'FuncForACMAppToGetScheme',
  'SCHEME_BASIC_INFO_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateScheme',
  'SCHEME_CLIENT_SEARCH_OUTLET': environment.basePathACM + 'FuncForACMAppToSearchOutletType',
  'SCHEME_SAVE_OUTLET': environment.basePathACM + 'FuncForACMAppToAddSchemeServedBy',
  'SCHEME_GET_OUTLET': environment.basePathACM + 'FuncForACMAppToGetOutlet',
  'SCHEME_OUTLET_DELETE': environment.basePathACM + 'FuncForACMAppToDeleteOutlettype',
  'SCHEME_SEARCH_TEST_LIST': environment.basePathACM + 'FuncForACMAppToSchemeTestSearch',
  'SCHEME_SELECTED_TEST_DETAILS': environment.basePathACM + 'FuncForACMAppToGetSchemeTestEstimationList',
  'SCHEME_SELECTED_TEST_ADD': environment.basePathACM + 'FuncForACMAppToAddSchemeTestInfo',
  'SCHEME_SELECTED_TEST_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateTestInfo',
  'SCHEME_SELECTED_TEST_GET': environment.basePathACM + 'FuncForACMAppToGetSchemeTest',
   // Discount/Coupon Type API's
   'COUPON_SEARCH': environment.basePathACM + 'FuncForACMAppToSearchDiscountCoupan',
   'COUPON_STATUS': environment.basePathACM + 'FuncForACMAppToStatusUpdateCoupon',
   'COUPON_ADD': environment.basePathACM + 'FuncForACMAppToAddDiscountCoupan',
   'COUPON_GET': environment.basePathACM + 'FuncForACMAppToGetDiscountCoupan',
   'COUPON_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdatecoupon',
 
   //GENERATE FORGOT_PASSWORD
   'GENERATE_FORGOT_PASSWORD': environment.basePathACM + 'FuncForHomeAppToSendOtpForGeneratePassword',
   'GENERATE_RESET_PASSWORD': environment.basePathACM + 'FuncForHomeAppToAddGeneratedPassword',
   'GENERATE_PASSWORD_USER': environment.basePathACM + 'FuncForHomeAppToValidateGeneratePasswordUser',

    // Booking Process Center Wise Test MIS Report
    'GET_BOOKING_PROCESS_CENTER_WISE_TEST_REPORT' : environment.basePathACM + 'FuncForMISAppToBookingProcessCenterWiseTestReport',
    'GET_BOOKING_PROCESS_CENTER_WISE_TEST_REPORT_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppToBookingProcessCenterWiseTestReportExportExl',

    // Customer Invoice MIS Report
    'GET_CUSTOMER_INVOICE_REPORT' : environment.basePathACM + 'FuncForMISAppToCustomerInvoice',
    'GET_CUSTOMER_INVOICE_REPORT_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppToCustomerInvoiceExportExl',
   
    // Clusterwise Datawise MIS Report
    'GET_CLUSTERWISE_DATEWISE_REPORT' : environment.basePathACM + 'FuncForMISAppToclusterwisedatewisereport',
    'GET_CLUSTERWISE_DATEWISE_REPORT_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppToclusterwisedatewisereportExl',
    'GET_CBH_RBH_DROPDOWN_DATA' : environment.basePathACM + 'FuncForUMAppToGetcbhrbh',

  
    // Add Coupon/ Scheme in Patient/lab get estimate and patient registration 
    'COUPON_DISCOUNT_LAB': environment.basePathACM + 'FuncForPMAppToGetCouponDiscountLab',
    'SCHEME_DISCOUNT_LAB': environment.basePathACM + 'FuncForPMAppToGetSchemeDiscountLab',

    // User Wise Payment Mode MIS Report
    'GET_USER_WISE_PAYMENT_MODE_REPORT' : environment.basePathACM + 'FuncForMISAppTouserwisepaymentmode',
    'GET_USER_WISE_PAYMENT_MODE_REPORT_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppTouserwisepaymentmodeExportExl',
  
  //Universal search
  'UNIVERSAL_SEARCH_GET' : environment.basePathACM + 'FuncForACMAppToSearchUniversalSample',
  'UNIVERSAL_SAMPLE_TRACKING' : environment.basePathACM + 'FuncForACMAppToGetUniversalSampleTracking',
  'UNIVERSAL_BOOKINGLOCATION_LIST' : environment.basePathACM + 'FuncForLOAppToGetUniversalBookingLocList',
  'UNIVERSAL_PSN_TNP_DETAILS' : environment.basePathACM + 'FuncForACMAppToGetUniversalTNPPSNReason',
  
  // Sample Load MIS Report
  'GET_SAMPLE_LOAD_REPORT' : environment.basePathACM + 'FuncForMISAppTosampleload',
  'GET_SAMPLE_LOAD_REPORT_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppTosampleloadExportExl',
  
  // Outsource Details MIS Report
  'GET_OUTSOURCE_DETAILS_REPORT' : environment.basePathACM + 'FuncForMISAppTooutsorcedetails',
  'GET_OUTSOURCE_DETAILS_REPORT_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppTooutsorcedetailsExportExl',
  
  // User Registration MIS Report
  'GET_USER_REGISTRATION_REPORT' : environment.basePathACM + 'FuncForMISAppToUserRegistration',
  'GET_USER_REGISTRATION_REPORT_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppToUserRegistrationExportExl',
    
  // Patient Detail With Test MIS Report
  'GET_PATIENT_DETAIL_WITH_TEST' : environment.basePathACM + 'FuncForMISAppTopatientdetailswithtest',
  'GET_PATIENT_DETAIL_WITH_TEST_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppTopatientdetailswithtestExportExl',
      
  // Patient Detail With Barcode Number MIS Report
  'GET_PATIENT_DETAIL_WITH_BARCODE' : environment.basePathACM + 'FuncForMISAppTopatientdetailswithbarcode',
  'GET_PATIENT_DETAIL_WITH_BARCODE_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppTopatientdetailswithbarcodeExportExl',

  // Patient Details MIS Report
  'GET_PATIENT_DETAIL' : environment.basePathACM + 'FuncForMISAppTopatientdetails',
  'GET_PATIENT_DETAIL_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppTopatientdetailsExportExl',
     
  // Performance Report By Department MIS Report
  'GET_PERFORMACE_REPORT' : environment.basePathACM + 'FuncForMISAppToperformancereportbydepartment',
  'GET_PERFORMACE_REPORT_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppToperformancereportbydepartmentExportExl',
    
  // Patient Wise Revenue MIS Report
  'GET_PATIENT_WISE_REVENUE' : environment.basePathACM + 'FuncForMISAppTopatientwiserevenue',
  'GET_PATIENT_WISE_REVENUE_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppTopatientwiserevenueExportExl',
      
  // Report Result Summary MIS Report
  'GET_REPORT_RESULT_SUMMARY' : environment.basePathACM + 'FuncForMISAppToreportresultsummary',
  'GET_REPORT_RESULT_SUMMARY_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppToreportresultsummaryExportExl',
    
  // Performing Location Wise Revenue MIS Report
  'GET_PERFORMING_LOCATION_REVENUE' : environment.basePathACM + 'FuncForMISAppToperforminglocationwiserevenue',
  'GET_PERFORMING_LOCATION_REVENUE_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppToperforminglocationwiserevenueExportExl',
    
  // Revenue Breakup Walkin MIS Report
  'GET_REVENUE_BREAKUP_WALKIN' : environment.basePathACM + 'FuncForMISAppTorevenuebrackupwalkin',
  'GET_REVENUE_BREAKUP_WALKIN_REVENUE_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppTorevenuebrackupwalkinExportExl',
  'GET_CLUSTER': environment.basePathACM + 'FuncForMISAppToclusterlist', 
    
  // Labwise Test & Patient Count MIS Report
  'GET_LABWISE_TEST_PT_COUNT' : environment.basePathACM + 'FuncForMISAppTolabwisetestcount',
  'GET_LABWISE_TEST_PT_COUNT_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppTolabwisetestcountExportExl',
  
  // Customer Ledger MIS Report
  'GET_CUSTOMER_LEDGER' : environment.basePathACM + 'FuncForMISAppTocustomerledger',
  'GET_CUSTOMER_LEDGER_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppTocustomerledgerExportExl',
  
  // Testwise Clusterwise Sale MIS Report
  'GET_TESTWISE_CLUSTERWISE' : environment.basePathACM + 'FuncForMISAppTotestwiseclusterwisesale',
  'GET_TESTWISE_CLUSTERWISE_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppTotestwiseclusterwisesaleExportExl',
  
  // TAT Report Booking & Test Basis MIS Report
  'GET_TAT_REPORT_BOOKING' : environment.basePathACM + 'FuncForMISAppToTatreprotbookingtestbasis',
  'GET_TAT_REPORT_BOOKING_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppToTatreprotbookingtestbasisExportExl',
  
  // Client Wise Revenue MIS Report
  'GET_CLIENT_WISE_REVENUE' : environment.basePathACM + 'FuncForMISAppToclientwiserevenue',
  'GET_CLIENT_WISE_REVENUE_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppToclientwiserevenueExportExl',
  
  // Test Detail With Net Billing MIS Report
  'GET_TEST_DETAIL_WITH_NET_BILLING' : environment.basePathACM + 'FuncForMISAppTotestdetailwithnetbilling',
  'GET_TEST_DETAIL_WITH_NET_BILLING_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppTotestdetailwithnetbillingExportExl',

  // Serving Cities API in Master Module Referring Doctor
  'GET_SERVING_CITIES' : environment.basePathACM + 'FuncForACMAppToGetCommonmasters',

  // Performing Location Wise Summary MIS Report
  'GET_PERFORMING_LOCATION_SUMMARY' : environment.basePathACM + 'FuncForMISAppToperforminglocationwisesummary',
  'GET_PERFORMING_LOCATION_SUMMARY_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppToperforminglocationwisesummaryExportExl',
     
  // Client Ledger MIS Report
  'GET_CLIENT_LEDGER' : environment.basePathACM + 'FuncForMISAppToclientledger',
  'GET_CLIENT_LEDGER_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppToclientledgerExportExl',
  
  // Testwise Monthwise Sales MIS Report
  'GET_TESTWISE_MONTHWISE_SALES' : environment.basePathACM + 'FuncForMISAppTotestwisemonthwisesale',
  'GET_TESTWISE_MONTHWISE_SALES_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppTotestwisemonthwisesaleExportExl',
     
  // Labwise Monthly Sale MIS Report
  'GET_LABWISE_MONTHLY_SALE' : environment.basePathACM + 'FuncForMISAppTolabwisemonthlysale',
  'GET_LABWISE_MONTHLY_SALE_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppTolabwisemonthlysaleExportExl',
    
  // Patient TRF
  'GET_PATIENT_TRF_DATA' : environment.basePathACM + 'FuncForPMAppTopmtrf',

  //ADD And GET Remarks History
  'GET_REMARKS_HISTORY' : environment.basePathACM + 'FuncForLOAppToGetRemarks',
  'ADD_REMARKS' : environment.basePathACM + 'FuncForLOAppToAddRemarks',
  'PATIENT_WISE_TEST_LIST' : environment.basePathACM + 'FuncForPMAppTopatienttestdetails',
  // Pending Checking Visit ID Click API
  'GET_VISIT_ID_PENDING_CHECKING' : environment.basePathACM + 'FuncForLOAppToGetVisitIdTestElementPendCheckin',

  // Pending Authentication Visit ID Click API
  'GET_VISIT_ID_PENDING_AUTH' : environment.basePathACM + 'FuncForLOAppToGetVisitIdTestElementPendingAuth',

  // Authenticated Visit ID Click API
  'GET_VISIT_ID_AUTHENTICATED' : environment.basePathACM + 'FuncForLOAppToGetVisitIdTestElementAuthenticated',

  // Show Result Entry Status API
  'GET_STATUS_RESULT_ENTRY': environment.basePathACM + 'FuncForLOAppToGetResultStatusForPendingEntry',
  //print generated invoice report data
  'PRINT_GENERATED_INVOICE_REPORT' : environment.basePathACM + 'FuncForMISAppToPrintGeneratedInvoice',

  // Quality Master Listing API
  'QUALITY_MASTER_LISTING' : environment.basePathACM + 'FuncForACMAppToSearchQC',
  'QUALITY_MASTER_SEARCH_TEST' : environment.basePathACM + 'FuncForACMAppToSearchQCTest',
  'QUALITY_MASTER_SEARCH_INSTRUMENT' : environment.basePathACM + 'FuncForACMAppToSearchQCInstrument',
  'QUALITY_MASTER_SEARCH_UNIT' : environment.basePathACM + 'FuncForACMAppToSearchQCUnit',
  'QUALITY_MASTER_GET_DETAILS' : environment.basePathACM + 'FuncForACMAppToGetQC',
  'QUALITY_MASTER_TO_ADD_ITEMS' : environment.basePathACM + 'FuncForACMAppToAddQC',
  'QUALITY_MASTER_TO_UPDATE_ITEMS' : environment.basePathACM + 'FuncForACMAppToUpdateQC',
  
  //TDS AND SERVICE CHARGE FOR CLIENT
  'CLIENT_TDS_AND_SERVICE_CHARGE' : environment.basePathACM + 'FuncForACMAppToClientTdsServiceCharge',
  'CLIENT_TDS_AND_SERVICE_CHARGE_EXPORT_EXCEL' : environment.basePathACM + 'FuncForACMAppToClientTdsServiceChargeExcel',

  // Financial Year Mst Master
  'GET_FINANCIAL_YEAR_MASTER_DETAIL' : environment.basePathACM + 'FuncForACMAppToGetFinancialYear',
  'ADD_FINANCIAL_YEAR_MASTER' : environment.basePathACM + 'FuncForACMAppToAddFinancialYear',
  'UPDATE_FINANCIAL_YEAR_MASTER' : environment.basePathACM + 'FuncForACMAppToUpdateFinancialYear',
  'STATUS_CHANGE_FINANCIAL_YEAR_MASTER' : environment.basePathACM + 'FuncForACMAppToStatusUpdateFinancialYear',
  'FINANCIAL_YEAR_MASTER_LISTING' : environment.basePathACM + 'FuncForACMAppToSearchFinancialYear',

       

  //UPDATE PRICE LIST API
  'GET_PRICE_LIST': environment.basePathACM + 'FuncForACMAppToGetPricelistEffectiveDate',
  'UPDATE_PRICE_LIST': environment.basePathACM + 'FuncForACMAppToUpdatePricelistEffectiveDate',
  
  //QC RESULT
  'QC_RESULT_DROP_DOWN': environment.basePathACM + 'FuncForACMAppToGetcontrolname',
  'QC_GET_RESULT': environment.basePathACM + 'FuncForACMAppToGetqualitycheckresult',
  'QC_SAVE_RESULT': environment.basePathACM + 'FuncForACMAppToAddQualitycheckresult',
  //IQC
  'QC_INTERNAL': environment.basePathACM + 'FuncForACMAppToGetIQCresult',
  //External QC
  'QC_EXTERNAL_ADD': environment.basePathACM + 'FuncForACMAppToAddExternalQualityControl',
  'QC_EXTERNAL_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateExternalQualityControl',
  'QC_EXTERNAL_GET_SEARCH': environment.basePathACM + 'FuncForACMAppToGetExternalQualityControlList',
  'QC_EXTERNAL_GET_SAVE_FORM': environment.basePathACM + 'FuncForACMAppToGetExternalQualityControlsingleList',

  // Manual Update Performing & Booking Location
  'MANUAL_UPDATE_PERFORMING_LOCATION': environment.basePathACM + 'FuncForACMAppToAddBookingandperformingcenterexcelupd',

  // Re Authentication API in Result Entry
  'RE_AUTHENTICATION_LISTING': environment.basePathACM + 'FuncForLOAppToSearchPendingReAuthentication',

  // Get Doctor List API in Re Authtication
  'GET_DOCTOR_LIST_DROPDOWN_API': environment.basePathACM + 'FuncForLOAppToGetResultReAuthUser',

  // Send Data For Re- Authetication
  'SEND_DATA_FOR_RE_AUTHTICATION': environment.basePathACM + "FuncForLOAppToAddPatientTestResultPendingReAuthentication",
 
  // Re Authtication API When clicked on Visit ID
  'GET_RE_AUTHTICATION_VISIT_ID': environment.basePathACM + "FuncForLOAppToGetVisitIdTestElementPendingReAuth",
   
  // Re Authtication API When clicked on Barcode
  'GET_RE_AUTHTICATION_BARCODE': environment.basePathACM + "FuncForLOAppToGetResultEntryForReAuthentication",

 // Re Process Lab Name Dropdown When clicked on Barcode
 'GET_RE_PROCESS_BARCODE_LAB_NAME': environment.basePathACM + "FuncForLOAppToSearchReProcessLab",

  //EQC APPROVAL
  'QC_EQC_APPROVAL_GET_LISTING': environment.basePathACM + 'FuncForACMAppToexternalqcapproval',
  'QC_EQC_APPROVAL_REQEST': environment.basePathACM + 'FuncForACMAppToexternalqcapprovalinsupd',
  'QC_EQC_APPROVAL_REQEST_VIEW': environment.basePathACM + 'FuncForACMAppToEqcApproveDetailsView',

  // Notification Get API Client
  'NOTIFICATION_GET_LISTING_CLIENT': environment.basePathACM + 'FuncForPMAppToGetClintNotification',

  // Notification Get API Lab
  'NOTIFICATION_GET_LISTING_LAB': environment.basePathACM + 'FuncForPMAppToGetLabNotification',

  // Notification Update API
  'NOTIFICATION_VIEW_UPDATE': environment.basePathACM + 'FuncForPMAppToUpdateNotification',
  
  // SCC Camp Active Camp Multiple Delete
   'ACTIVE_CAMP_MULTIPLE_DELETE': environment.basePathACM + 'FuncForSCCMAppToCamputletmultipleDelete',
 
   //Image Upload API
   'IMAGE_UPLOAD_API_FOR_EDITOR': environment.basePathACM + 'FuncForACMAppToUploadckeditor?command=QuickUpload&type=Files&responseType=json',
 
  // Whats APP API for Patient Booking Report Available Tab
  'WHATSAPP_REPORT_AVAILABLE_API': environment.basePathACM + 'FuncForLOAppToSendWhatsappPatientReport',
  
  //Directory Of Services LAB------
  'GET_DIRECTORY_BASED_TEST_LIST': environment.basePathACM + 'FuncForPMAppToGetDirectoryBasedTestNameLab',
  'SEARCH_DIRECTORY_TEST': environment.basePathACM + 'FuncForPMAppToSearchDirectoryTestEstimationLab',
  'CONFIRM_API':environment.basePathACM + 'funcForPMAppToSearchDirectoryAllTestEstimationLab',
  
  //Directory Of Services Client------
  'CLIENT_GET_DIRECTORY_BASED_TEST_LIST': environment.basePathACM + 'FuncForPMAppToGetDirectoryBasedTestNameClient',
  'CLIENT_SEARCH_DIRECTORY_TEST': environment.basePathACM + 'FuncForPMAppToSearchDirectoryTestEstimationClient',
  'CLIENT_CONFIRM_API': environment.basePathACM + 'funcForPMAppToSearchDirectoryAllTestEstimationClient',
  
  //Sample Cancellation on client and lab
  'SAMPLE_CANCELLATION_GET_LIST_ON_LAB': environment.basePathACM + 'FuncForPMAppToGetPatientCancelSampleLab',
  'SAMPLE_CANCELLATION_GET_LIST_ON_CLIENT': environment.basePathACM + 'FuncForPMAppToGetPatientCancelSampleClient',
  'SAMPLE_CANCELLATION_SAVE_ON_LAB': environment.basePathACM + 'FuncForPMAppToCancelPatientPendingSampleLab',
  'SAMPLE_CANCELLATION_SAVE_ON_CLIENT': environment.basePathACM + 'FuncForPMAppToCancelPatientPendingSampleClient',

  // Manage Lab Other Tab API's
  'MAP_AUTHORIZER_SAVE': environment.basePathACM + 'FuncForACMAppTodiscountlabins',
  'GET_LAB_LISTING_SEARCH_FOR_MAP': environment.basePathACM + 'FuncForACMAppTodiscountlabsearch',
  'MAP_AUTHORIZER_GET': environment.basePathACM + 'FuncForACMAppTodiscountlabget',
  'DELETE_SINGLE_LAB_AUTHORIZER': environment.basePathACM + 'FuncForACMAppTodiscountlabdelete',
  'MAP_AUTHORIZER_UPDATE': environment.basePathACM + 'FuncForACMAppTodiscountlabupdate',

  // Lab DropDown API to get name & amount(flat/percentage)
  'GET_AUTHORIZER_DROPDOWN_LAB': environment.basePathACM + 'FuncForPMAppTolabDiscountperflat',

  // Patient DropDown API to get name & amount(flat/percentage)
  'GET_AUTHORIZER_DROPDOWN_PATIENT': environment.basePathACM + 'FuncForPMAppToclientDiscountperflat',

  //Report Avl Detatils
  'REPORT_AVL_DETAILS': environment.basePathACM + 'FuncForPMAppToGetPatientReportAvail',

  // Get Lab, Instrument listing
  'ADD_DELAY_LOG_GET_LAB_INSTRUMENT': environment.basePathACM + 'FuncForDIAppToGetLabDeptInstrument',

  // Get Lab name based on department & instrument
  'GET_TEST_NAME_BASED_ON_DEPARTMENT_INSTRUMENT': environment.basePathACM + 'FuncForDIAppToGetTest',

  // Get delay logs listing
  'GET_DELAY_LOG_LISTING': environment.basePathACM + 'FuncForDIAppToSearchDelayLog',

  // Add delay logs
  'ADD_DELAY_LOG': environment.basePathACM + 'FuncForDIAppToAddDelay',

  // Get delay detail 
  'GET_DELAY_DETAIL': environment.basePathACM + 'FuncForDIAppToGetDetails',

  // Get approve delay listing
  'GET_APPROVE_DELAY_LISTING': environment.basePathACM + 'FuncForDIAppToSearchApproveDelayLog',

  // Add remarks for delay
  'ADD_REMARKS_FOR_DELAY': environment.basePathACM + 'FuncForDIAppToAddRemarks',
  
  // View remarks for delay
  'VIEW_REMARKS_FOR_DELAY': environment.basePathACM + 'FuncForDIAppToViewRemarks',
  
  // Delay log Send for approval
  'DELAY_SEND_FOR_APPROVAL': environment.basePathACM + 'FuncForDIAppToSendForApprove',

   // Message Scheduler
   'ADD_MESSAGE_SCHEDULER': environment.basePathACM +'FuncForUMAppToAddMessageScheduler',
   'SEARCH_MESSAGE_SCHEDULER':environment.basePathACM + 'FuncForUMAppToSearchMessageScheduler',
   'STATUS_UPDATE_MESSAGE_SCHEDULER':environment.basePathACM + 'FuncForUMAppToMessageSchedulerStatusUpdate',
   'UPDATE_MESSAGE_SCHEDULER':environment.basePathACM + 'FuncForUMAppToMessageSchedulerUpdate',
   'GET_MESSAGE_SCHEDULER':environment.basePathACM + 'FuncForUMAppToGetMessageScheduler',
  // Sample Load Module API's
  'GET_SAMPLE_LOAD_DETAILS': environment.basePathACM + 'FuncForLOAppToSearchSampleLoad',
  'GET_BATCH_DETAILS': environment.basePathACM + 'FuncForLOAppToSearchSampleLoadBatch',
  // Sample Load Module API's
  'GET_SAMPLE_LOAD_DETAILS_LAB': environment.basePathACM + 'FuncForLOAppToSearchAnalyticalSampleLoad',
  'GET_BATCH_DETAILS_LAB': environment.basePathACM + 'FuncForLOAppToSearchAnalyticalSampleLoadBatch',

  // Department Master Add Excel API
  'ADD_DEPARTMENT_EXCEL': environment.basePathACM + 'FuncForACMAppToAddExcelUploadDepartmentMstDtls',

  // Performing Location Wise Revenue MIS Report
  'GET_PERFORMING_LOCATION_REPORT' : environment.basePathACM + 'FuncForMISAppToPerfLocationwiseReport',
  'GET_PERFORMING_LOCATION_REPORT_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppToPerfLocationwiseReportExportExl',

  // Performing Location Wise Revenue MIS Report
  'GET_CREDIT_DEBIT_NOTE' : environment.basePathACM + 'FuncForMISAppToclientCreditDebitNote',
  'GET_CREDIT_DEBIT_NOTE_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppToClientCreditDebitNoteReportExportExl',
  

  //Booking Perf Share

  'GET_PERFORMING_LOCATION' : environment.basePathACM + 'FuncForACMAppToLabSearch',
  'ADD_BOOKING_SHARE': environment.basePathACM + 'FuncForACMAppToBookingandPerformingCenterSharepercentageAdd',
  'GET_BOOKING_SHARE': environment.basePathACM+ 'FuncForACMAppToBookingandPerformingCenterSharepercentageGet',
  'SEARCH_BOOKING_SHARE': environment.basePathACM+ 'FuncForACMAppToBookingandPerformingCenterSharepercentageSearch',
  'UPDATE_BOOKING_SHARE': environment.basePathACM+ 'FuncForACMAppToBookingandPerformingCenterSharepercentageUpdate',
  'STATUS_UPDATE_BOOKING_SHARE': environment.basePathACM+ 'FuncForACMAppToBookingandPerformingCenterSharepercentageStatusUpdate',


  //TEST WISE PSN LIST
  'GET_TEST_WISE_PSN_LIST' : environment.basePathACM + 'FuncForLOAppToGetPSNTestWiseAuthentication',

  //payment mode
  'GET_VIEW_REMARK_CLIENT' : environment.basePathACM + 'FuncForPMAppToGetremarksclientbilling',
  'GET_VIEW_REMARK_LAB' : environment.basePathACM + 'FuncForPMAppToGetremarksLabbilling',

  //RECEIPT REGISTER REPORT
  'OUTLET_API':environment.basePathACM+ 'FuncForMISAppToGetClientLabList',

  // GET CLIENT-WISE FUND TYPE LIST
  'GET_FUND_TYPE_LIST': environment.basePathACM+ 'FuncForACMAppToGetClientFundTypeList',




  // NDHM API IMPLEMENTATION

  'TOKEN_GENERATION_NDHM': environment.basePathACM + 'FuncForPMAppToGetNDHMSession',
  'GENERATE_AADHAR_OTP': 'https://healthidsbx.abdm.gov.in/api/v1/registration/aadhaar/generateOtp',
  'VERIFY_OTP_REQUEST': 'https://healthidsbx.abdm.gov.in/api/v1/registration/aadhaar/verifyOTP',
  'GENERATE_MOBILE_OTP': 'https://healthidsbx.abdm.gov.in/api/v1/registration/aadhaar/generateMobileOTP',
  'VERIFY_MOBILE_OTP': 'https://healthidsbx.abdm.gov.in/api/v1/registration/aadhaar/verifyMobileOTP',
  'CREATE_HEALTH_ID': 'https://healthidsbx.abdm.gov.in/api/v1/registration/aadhaar/createHealthIdWithPreVerified',
  'AADHAAR_RESEND_OTP': 'https://healthidsbx.abdm.gov.in/api/v1/registration/aadhaar/resendAadhaarOtp',
  'RESEND_MOBILE_OTP_REGISTER': 'https://healthidsbx.abdm.gov.in/api/v1/registration/mobile/resendOtp',

  'VERIFY_MOBILE_WITH_AADHAAR': 'https://healthidsbx.abdm.gov.in/api/v2/registration/aadhaar/checkAndGenerateMobileOTP',

  // ABHA Number Login

  'SEARCH_HEALTH_ID': 'https://healthidsbx.abdm.gov.in/api/v1/search/searchByHealthId',
  'AUTH_METHOD_TYPE': 'https://healthidsbx.abdm.gov.in/api/v1/auth/init',
  'VERIFY_ABHA_OTP_NUMBER_AADHAAR': 'https://healthidsbx.abdm.gov.in/api/v1/auth/confirmWithAadhaarOtp',
  'VERIFY_ABHA_OTP_NUMBER_MOBILE': 'https://healthidsbx.abdm.gov.in/api/v1/auth/confirmWithMobileOTP',
  'GET_ABHA_USER_PROFILE': 'https://healthidsbx.abdm.gov.in/api/v1/account/profile',
  'GET_PROFILE_QR_CODE': 'https://healthidsbx.abdm.gov.in/api/v1/account/qrCode',

  //PAN card validation
  'PAN_CARD_CHECK_VALIDATION': environment.basePathACM + 'FuncForCMAppToGetpanexists',


  //FINANCE 
  'FINANCE_GET_FUND_TYPE_LIST': environment.basePathACM + 'FuncForFMAppToGetClientFundTypeList',
  'FINANCE_CLIENT_MANUAL_RECHARGE_ROLLING_UPDATE': environment.basePathACM + 'FuncForFMAppToUpdateClientRollingAmount',
  // Mobile Number Login

  'GET_PUBLIC_KEY_ENCRYPTION': 'https://healthidsbx.abdm.gov.in/api/v2/auth/cert',
  'GENERATE_ENCRYPT_DATA': 'https://healthidsbx.abdm.gov.in/api/v2/auth/cert',
  'SEND_MOBILE_OTP': 'https://healthidsbx.abdm.gov.in/api/v2/registration/mobile/login/generateOtp',
  'VALIDATE_MOBILE_OTP': 'https://healthidsbx.abdm.gov.in/api/v2/registration/mobile/login/verifyOtp',
  'RESEND_MOBILE_OTP': 'https://healthidsbx.abdm.gov.in/api/v2/registration/mobile/login/resendOtp',
  'USER_AUTHORIZED_DATA' : 'https://healthidsbx.abdm.gov.in/api/v2/registration/mobile/login/userAuthorizedToken',
  'USER_PROFILE_BY_MOBILE': 'https://healthidsbx.abdm.gov.in/api/v2/account/profile',
  'USER_QRCODE_BY_MOBILE': 'https://healthidsbx.abdm.gov.in/api/v2/account/qrCode',




  //Client API based on area
  'CLIENT_GET_LIST_BASED_ON_AREA': environment.basePathACM + 'FuncForMISAppToGetAreaWiseClientList',

  'GET_PNG_CARD_ABHA' : 'https://healthidsbx.abdm.gov.in/api/v1/account/getPngCard' ,

 
  //Price List Effective Date check
  'CLIENT_CHECK_PRICE_LIST_EFFECTIVE_DATE_VALIDATION': environment.basePathACM + 'FuncForACMAppToGetPriceListbyLab',
      
//Report Builder

// '_SEARCH_API_REPORTING_BUILDER': environment.basePathACM + 'FuncForACMAppToReportingtypeSearch',
// 'ADD_BOOKED_BY_CENTER': environment.basePathACM +'FuncForACMAppToAddBookedByCenter',
// 'ADD_PROCESSED_BY_CENTER': environment.basePathACM +'FuncForACMAppToAddProcessedByCenter',
// 'ADD_PRINT_DETAIL1': environment.basePathACM +'FuncForACMAppToAddPrintDrDetail',
// 'ADD_BARCODE': environment.basePathACM +'FuncForACMAppToAddRbBarcode',
// 'ADD_LOGO_SETUP': environment.basePathACM + 'FuncForACMAppToAddLogoSetup',
// 'ADD_TEST_HEADER': environment.basePathACM + 'FuncForACMAppToAddTestHeader',
// 'ADD_PRINT_DETAILS2': environment.basePathACM + 'FuncForACMAppToAddPrintReportDetail',
// 'GET_PAGE_SETUP': environment.basePathACM + 'FuncForACMAppToGetPageSetup',

// common Get API 
'SEARCH_REPORT_BUILDER': environment.basePathACM + 'FuncForACMAppToGetReportBuilderSetup',
'REPORTING_TYPE': environment.basePathACM + 'FuncForACMAppToGetCommonmasters',
'GET_REPORT_BUILDER': environment.basePathACM + 'FuncForACMAppToGetReportBuilder',
// 'MASTER_REPORTING_STATUS_UPDATE': environment.basePathACM + 'FuncForACMAppToStatusUpdateReportingtype',


// page setup
'ADD_PAGE_SETUP': environment.basePathACM +'FuncForACMAppAddPageSetup',
'UPDATE_PAGE_SETUP': environment.basePathACM + 'FuncForACMAppToUpdatePageSetup',

//Header Foter API
'ADD_HEADER_FOOTER': environment.basePathACM + 'FuncForACMAppToAddLogoSetup',
'UPDATE_HEADER_FOOTER_SETUP': environment.basePathACM + 'FuncForACMAppToUpdateLogoSetup',

//General SetUP
'ADD_GENERAL_SETUP': environment.basePathACM + 'FuncForACMAppToAddGeneralSetup',
'UPDATE_GENERAL_SETUP': environment.basePathACM + 'FuncForACMAppToUpdateGeneralSetup',

//Test SetUP
'ADD_TEST_SETUP': environment.basePathACM + 'FuncForACMAppToAddTestHeader',
'UPDATE_TEST_SETUP': environment.basePathACM + 'FuncForACMAppToUpdateTestHeader',

//Signature API
'REPORTING_DOCTOR_LIST': environment.basePathACM + 'FuncForACMAppToGetCommonmasters',
'ADD_SIGNATURE_AND_COMMENT': environment.basePathACM + 'FuncForACMAppToAddDoctorSignature',
'UPDATE_SIGNATURE_AND_COMMENT': environment.basePathACM + 'FuncForACMAppToUpdateDoctorSignature',

// Refund Process API in TNP Sample when login with Lab

'PATIENT_LAB_VIEW_REFUND_DATA': environment.basePathACM +'FuncForPMAppToGetPatientRefundLab',
'PATIENT_LAB_GENERATE_REFUND': environment.basePathACM + 'FuncForPMAppAddRefund',
'GET_REFUND_INVOICE_DATA' :  environment.basePathACM + 'FuncForPMAppToGetPatientRefundProcessLab',

// NEW MIS Reports

'GET_CLIENT_MASTER_REPORT' :  environment.basePathACM + 'FuncForMISAppToClientMasterreport',
'GET_CLIENT_MASTER_REPORT_EXCEL' :  environment.basePathACM + 'FuncForMISAppToClientMasterExportExl',

'GET_ROLLING_BALANCE_SUMMARY' :  environment.basePathACM + 'FuncForMISAppTorollingbalancesummaryreport',
'GET_ROLLING_BALANCE_SUMMARY_EXCEL' :  environment.basePathACM + 'FuncForMISAppTorollingbalancesummaryreportExportExl',

'GET_COLLECTION_SUMMARY_REPORT' :  environment.basePathACM + 'FuncForMISAppToSearchCollectionsummaryreport',
'GET_COLLECTION_SUMMARY_REPORT_EXCEL' :  environment.basePathACM + 'FuncForMISAppToSearchCollectionreportsummaryExpoertExcel',

'GET_BANK_BOOK_REPORT' :  environment.basePathACM + 'FuncForMISAppTobankbookreport',
'GET_BANK_BOOK_REPORT_EXCEL' :  environment.basePathACM + 'FuncForMISAppTobankbookreportExportExl',

//Bill Mode
'MIS_GET_BILLING_CODE_LIST': environment.basePathACM + 'FuncForPMAppToGetBillingCodeMis',

// NEW MIS Reports Refund Receipt

'GET_REFUND_RECEIPT_REPORT' :  environment.basePathACM + 'FuncForMISAppToReceiptRefund',
'GET_REFUND_RECEIPT_EXCEL' :  environment.basePathACM + 'FuncForMISAppToReceiptRefundExportExl',

// Manual Fund Recharge Payment Mode Dropdown API

'GET_MANUAL_FUND_PAYMENT_MODE' :  environment.basePathACM + 'FuncForACMAppToManualFundRechargeDdl',

// Patient Listing For CC/ Lab

'GET_PATIENT_LISTING' :  environment.basePathACM + 'FuncForPMAppToSearchPatientReportAvailableLabView',
'GET_PATIENT_DETAIL_VIEW' :  environment.basePathACM + 'FuncForPMAppToSearchPatientReportAvailableLabGetView',
'PATIENT_LISTING_UNCOLLECTED_SAMPLE_GET' :  environment.basePathACM + 'FuncForPMAppToGetPatientRemainingSampleLab',

// API for EDOS Client & Lab Login

'GET_DIRECTORY_CLIENT_CHECK_TEST' :  environment.basePathACM + 'FuncForPMAppToSearchDirectoryClientCheckTestExistance',

// Patient List Filter API's

'GET_DEPARTMENT_LISTING': environment.basePathACM + 'FuncForPMAppToPatientReportAvailableLabdept',
'GET_BOOKING_LOC_LISTING': environment.basePathACM + 'FuncForPMAppToPatientReportAvailableLabclient',
'GET_TEST_PATIENT_LISTING': environment.basePathACM + 'FuncForPMAppToPatientReportAvailableLabTest',
'UPDATE_DEMOGRAPHIC_DETAILS_PATIENT': environment.basePathACM + 'FuncForPMAppToUpdatepatientdetail',

// API for PAtient Interface Report

'PATIENT_INTERFACE_MOBILE_LOGIN': environment.basePathACM + 'FuncForPatientAppToSendUserOTP',
'PATIENT_INTERFACE_VALIDATE_OTP' : environment.basePathACM + 'FuncForPatientAppToValidateOTP',
'PATIENT_INTERFACE_RESEND_OTP': environment.basePathACM + 'FuncForPatientAppToReSendUserOTP',
'PATIENT_INTERFACE_DASHBOARD_LIST': environment.basePathACM + 'FuncForPatientDashboardToPatientrptlist',
'PATIENT_INTERFACE_DASHBOARD_DROPDOWN' : environment.basePathACM + 'FuncForPatientDashboardToPatientDashdropdown',

// API For Update Batch Wise Sample 
'BATCH_WISE_SAMPLE_UPDATE' : environment.basePathACM + 'FuncForPMAppToUpdateBatchWiseSample',

// API For Invoice Settlement

'SEARCH_GENERATED_INVOICE_SETTLEMENT':environment.basePathACM+'FuncForACMAppToGetGeneratedInvoicesettlement',
'SEARCH_LOG_INVOICE_SETTLEMENT':environment.basePathACM+'FuncForACMAppToGetGeneratedInvoicesettlementlog',

'MODIFIED_PRICE_LIST_API': environment.basePathACM +'FuncForACMAppToPricelistExportExcel',

'INSERT_INVOICE_SETTLEMENT': environment.basePathACM + 'FuncForACMAppToAddGeneratedInvoicesettlement',

// ADD Test Price List API in Price List Master
'GET_ADD_TEST_PRICE_LIST': environment.basePathACM + 'FuncForACMAppToGetaddtestpriceList',
'ADD_TEST_PRICE_ENTERED' : environment.basePathACM + 'FuncForACMAppToaddtestpriceList',

//Update Client Templete (Reporting Type) Client Master
'UPDATE_REPORTING_TYPE' : environment.basePathACM + 'FuncForCMAppToUpdatereportingType',
'GET_REPORTING_TYPE' : environment.basePathACM + 'FuncForCMAppToGetreportingType',

// Refund API for Client
'GET_PATIENT_REFUND_CLIENT' : environment.basePathACM + 'FuncForPMAppToGetPatientRefundClient',
'ADD_CANCEL_TEST_REFUND_CLIENT': environment.basePathACM + 'FuncForPMAppAddCancelTestsRefund',

// 
'GET_PATIENT_REFUND_RECEIPT_CLIENT' : environment.basePathACM + 'FuncForPMAppToGetPatientRefundReceiptClient',
 
// Client (DC Under CC) Make Own Rolling API

'GET_MAKE_OWN_ROLLING' : environment.basePathACM + 'FuncForCMAppToGetMakeOwnRolling',
'UPDATE_MAKE_OWN_ROLLING' : environment.basePathACM + 'FuncForCMAppToUpdateMakeOwnRolling',

//PSN DOC APPROVE/REJECT
'GET_PSN_DOCUMENT' : environment.basePathACM + 'FuncForLOAppToGetAccPsndoc',
'ADD_PSN_DOCUMENT' : environment.basePathACM + 'FuncForLOAppToAddPSNdoc',
'APPROVE_REJECT_PSN_DOCUMENT' : environment.basePathACM + 'FuncForLOAppToUpdatePSNDocApproveReject',
'GET_PSN_DOCUMENT_LAB' : environment.basePathACM + 'FuncForLOAppToGetAnalyticalPsndoc',
'ADD_PSN_DOCUMENT_LAB' : environment.basePathACM + 'FuncForLOAppToAddAnalyticalPsndoc',

// Refund API For Patient Test Cancel
'GET_PATIENT_CANCEL_TEST_REFUND' : environment.basePathACM + 'FuncForPMAppToGetPatientCancelTestRefundLab',
'ADD_PATIENT_CANCEL_TEST_REFUND' : environment.basePathACM + 'FuncForPMAppAddCancelTestsRefundLab',
'GET_PATIENT_REFUND_RECEIPT': environment.basePathACM + 'FuncForPMAppToGetPatientRefundReceiptLab',

// Payment Mode change option after receipt generation

'GET_LAB_CHANGE_PAYMENT_MODE' : environment.basePathACM + 'FuncForPMAppToGetLabChangePaymentmode',
'GET_LAB_CHANGE_PAYMENT_MODE_LOG' : environment.basePathACM + 'FuncForPMAppToGetLabChangePaymentmodelog',
'UPDATE_LAB_CHANGE_PAYMENT_MODE' :  environment.basePathACM + 'FuncForPMAppToUpdateLabChangePaymentmode',

//Report Details
'PATIENT_REPORT_DETAIL_GET' : environment.basePathACM + 'FuncForPMAppToPatientReportAvailableLab',

// Master Department Sequencing API

'GET_DEPARTMENT_SEQUENCING_SEARCH' :  environment.basePathACM + 'FuncForACMAppToDeptSequenceSearch',
'GET_DEPARTMENT_STATUS_CHANGE' :  environment.basePathACM + 'FuncForACMAppToStatusDeptSequence',
'GET_CLIENT_LAB' :  environment.basePathACM + 'FuncForACMAppToGetclientlab',
'GET_SELECTED_CLIENT_LAB' :  environment.basePathACM + 'FuncForACMAppToGetDeptSerialno',
'ADD_DEPARTMENT_SEQUENCING' : environment.basePathACM + 'FuncForTMAppToAdddeptSerialNo',
'GET_DEPARTMENT_GROUP' :  environment.basePathACM + 'FuncForACMAppToGetDeptGroupList',
'ADD_DEPARTMENT_GROUP' :  environment.basePathACM + 'FuncForTMAppToAdddeptgroup',
'GET_TEST_PROFILE_LIST' : environment.basePathACM + 'FuncForACMAppToGetTestProfileList',
'ADD_CONFIGURE_SEQUENCING' : environment.basePathACM + 'FuncForTMAppToAddTestProfileSerialNo',
'DELETE_DEPT_GROUP' : environment.basePathACM + 'FuncForACMAppToDeleteDeptGroup',
'UPDATE_DEPT_GROUP' : environment.basePathACM + 'FuncForACMAppToUpdateDeptGroup',

//SIS MIS
'SEARCH_SIS_MIS':environment.basePathACM+ 'FuncForMISAppTosis',
'DOWNLOAD_SIS_MIS':environment.basePathACM+'FuncForMISAppTosisExpoertExcel',

// CLIENT CLOSURE API FOR CLIENT MODULE

'SEARCH_CLIENT_CLOSURE': environment.basePathACM + 'FuncForCMAppToSearchClientClosure',
'GET_CLIENT_CLOSURE': environment.basePathACM + 'FuncForCMAppToGetClientClosure',
'ADD_CLIENT_CLOSURE' : environment.basePathACM + 'FuncForACMAppToAddClientClosure',
'GET_CLIENT_CLOSURE_LOG' : environment.basePathACM + 'FuncForCMAppToSearchClientClosureLog',

//home collection

'SEARCH_HOME_COLLECTION': environment.basePathACM+'FuncForMISAppTohomecollection',
'PDF_DOWNLOAD_HOME_COLLECTION': environment.basePathACM+'FuncForMISAppTohomecollectionExpoertExcel',

//Problem Sample Remarks API
'PROBLEM_SAMPLE_REMARKS': environment.basePathACM + 'FuncForPMAppToGetPSNApproveRejectRemarks',
        

//revenue sharing for hlm master
'ADD_REVENUE_SHARING': environment.basePathACM + 'FuncForTMAppToAddRevenueSharingHLMLab',
'SEARCH_LAB_REVENUE_SHARING': environment.basePathACM + 'FuncForLOAppToGetRevenueSharingHLMLabList',
'UPDATE_LAB_REVENUE_SHARING': environment.basePathACM + 'FuncForACMAppToUpdateRevenueSharingHLMLab',
'GET_LAB_REVENUE_SHARING': environment.basePathACM + 'FuncForACMAppToGetRevenueSharingHLMLab',
'SEARCH_REVENUE_SHARING': environment.basePathACM + 'FuncForACMAppToRevenueSharingHLMLabSearch',
'STATUS_UPDATE_LAB_REVENUE_SHARING': environment.basePathACM + 'FuncForACMAppToStatusUpdateRevenueSharingHLMLab',
'REVENUE_SHARING_HISTORY': environment.basePathACM + 'FuncForACMAppToGetRevenueSharingHLMLabHis',

//Client special rate report
'SEARCH_CLIENT_SPECIAL_RATE_REPORT': environment.basePathACM + 'FuncForMISAppToClientSpecialRatesReportGet',
'EXPORT_EXCEL_CLIENT_SPECIAL_RATE': environment.basePathACM + 'FuncForMISAppToClientSpecialRatesReportGetExpoertExcel',

//Revenue Sharing for hlm report
'REVENUE_SHARING_HLM_REPORT': environment.basePathACM + 'FuncForMISAppTohlm',
'EXPORT_EXCEL_FOR_HLM_REPORT': environment.basePathACM + 'FuncForMISAppTohlmExpoertExcel',

// New TNP Process
'INVALID_INCONCLUSIVE_RESULT': environment.basePathACM + 'FuncForLOAppToGetInvalidAndInconclusiveTest',
'ADD_INVALID_INCONCLUSIVE_RESULT' : environment.basePathACM + 'FuncForLOAppToAddInvalidAndInconclusiveTest',

//Discount approval report
'DISCOUNT_APPROVAL_SEARCH_REPORT':environment.basePathACM + 'FuncForMISAppToDiscountApproval',
'EXPORT_EXCEL_DISCOUNT_APPROVAL_SEARCH_REPORT':environment.basePathACM + 'FuncForMISAppToDiscountApprovalExpoertExcel',

// Delta Check API In Result Entry
'LAB_OPERATION_RESULT_ENTRY_GET_ELEMENT_DELTACHECK_RESULT_HISTORY': environment.basePathACM + 'FuncForLOAppToGetDeltacheckElement',
'LAB_OPERATION_RESULT_ENTRY_GET_ELEMENT_DELTACHECK_RESULT_HISTORY_ADD' : environment.basePathACM + 'FuncForLOAppToAddDeltacheckElement',

//Update security Deposit Amount
'GET_SECURITY_DEPOSIT_AMOUNT':environment.basePathACM+'FuncForCMAppToGetClientsecurityamt',
'UPDATE_SECURITY_DEPOSIT_AMOUNT':environment.basePathACM+'FuncForCMAppToUpdateClientsecurityamt',

'ADD_EXCEL_FILE':environment.basePathACM+'FuncForACMAppToDebitTransactionUploadExcel',

//Co-promotion Excel Upload
'SEARCH_CO_PROMOTION_EXCEL_UPLOAD':environment.basePathACM+'FuncForTMAppToSearchClientDebitTransactionUploadExcel',
'GET_CO_PROMOTION_EXCEL_UPLOAD_HOSTORY':environment.basePathACM+'FuncForACMAppToGetClientDebitTransactionUploadExcellog',
'STATUS_UPDATE_CO_PROMOTION_EXCEL_UPLOAD':environment.basePathACM+'FuncForACMAppToClientStatusUpdateDebitTransaction',



//Upload multi document Document on result entry
// 'UPLOAD_DOCUMENT_PENDING_ENTRY': environment.basePathACM + 'FuncForACMAppToFTPUploadDocument',


//Test Outsource MIS

'TEST_OUTSOURCE_MIS': environment.basePathACM + 'FuncForMISAppToTestOutSourceReport',
'TEST_OUTSOURCE_MIS_EXCEl': environment.basePathACM + 'FuncForMISAppToTestOutSourceExpoertExcel',

//Discount Breakup MIS

'DISCOUNT_BREAKUP_REPORT': environment.basePathACM + 'FuncForMISAppToDiscountBreakupReport',
'DISCOUNT_BREAKUP_REPORT_EXPORT_EXCEL': environment.basePathACM + 'FuncForMISAppToDiscountBreakupExpoertExcel',

//Machine Interface MIS
'MACHINE_INTERFACE_MIS': environment.basePathACM + 'FuncForMISAppToMachineInterface',
'MACHINE_INTERFACE_MIS_EXCEL': environment.basePathACM + 'FuncForMISAppToMachineInterfaceExpoertExcel',

//Update Machine Interface 
'UPDATE_MACHINE_INTERFACE': environment.basePathACM + 'FuncForLOAppToUpdatemachineBarcode',

// Department Sequence Type Master
'DEPARTMENT_SEQUENCE_SEARCH': environment.basePathACM + 'FuncForACMAppToDeptSecuencetypeSearch',
'DEPARTMENT_SEQUENCE_STATUS_CHANGE': environment.basePathACM + 'FuncForACMAppToStatusUpdateDeptSecuencetype',
'GET_DEPARTMENT_SEQUENCE_DETAILS': environment.basePathACM + 'FuncForACMAppToGetDeptSecuencetype',
'ADD_DEPARTMENT_SEQUENCE_TYPE': environment.basePathACM + 'FuncForACMAppToAddDeptSecuencetype',
'UPDATE_DEPARTMENT_SEQUENCE_TYPE': environment.basePathACM + 'FuncForACMAppToUpdateDeptSecuencetype',

// API For Department Sequencing Get Added Test
'GET_DEPARTMENT_TEST_GROUP_LIST': environment.basePathACM + 'FuncForACMAppToGetDeptTestGroupList',
'DELETE_TEST_PROFILE_SERIAL_NUMBER': environment.basePathACM + 'FuncForACMAppToDeleteTestProfileSerialNo',
'UPDATE_DEAPRTMENT_SEQ_NUMBER': environment.basePathACM + 'FuncForACMAppToUpdateDeptSerialNo',
'UPDATE_SINGLE_TEST_PROFILE' : environment.basePathACM + 'FuncForACMAppToUpdateTestProfileSerialNo',
'DELETE_DEPT_SEQUENCE_NUMBER' :environment.basePathACM + 'FuncForACMAppToDeleteDeptSerialno',

// API for update department sequence type client
'GET_DEPT_SEQ_TYPE_CLIENT': environment.basePathACM + 'FuncForACMAppToGetDeptSecuencetypeclient',
'UPDATE_DEPT_SEQ_TYPE_CLIENT': environment.basePathACM + 'FuncForACMAppToUpdateDeptSecuencetypeClient',

// Upload Document
'UPLOAD_DOCUMENT_AZURE': environment.basePathACM + 'FuncForACMAppToUploadDocumentOnAzureBlob',

// View Generated Report 
'VIEW_GENERATED_REPORT' : environment.basePathACM + 'FuncForLOAppToGetReportFromBlob',

// New MIS For Patient Wise Revenue With Test Breakup
'GET_PATIENT_WISE_REVENUE_TEST_BREAKUP' : environment.basePathACM + 'FuncForMISAppTopatientwiserevenuetestbreakup',
'GET_PATIENT_WISE_REVENUE_TEST_BREAKUP_EXCEL' : environment.basePathACM + 'FuncForMISAppTopatientwiserevenuetestbreakupExportExl',

// Test Cancellation Reason Master API
'TEST_CANCEL_REASON_SEARCH': environment.basePathACM + 'FuncForACMAppToSearchCancelTestReason',
'TEST_CANCEL_UPDATE_STATUS': environment.basePathACM + 'FuncForACMAppToStatusUpdateCancelTestReason',
'TEST_CANCEL_REASON_ADD': environment.basePathACM + 'FuncForACMAppToAddcanceltestReason',
'TEST_CANCEL_REASON_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateCancelTestReason',
'TEST_CANCEL_REASON_GET': environment.basePathACM + 'FuncForACMAppToGetCancelTestReason',

// Fund Reason Master API
'FUND_REASON_SEARCH': environment.basePathACM + 'FuncForACMAppToSearchFundReason',
'FUND_UPDATE_STATUS': environment.basePathACM + 'FuncForACMAppToStatusUpdateFundReason',
'FUND_REASON_ADD': environment.basePathACM + 'FuncForACMAppToAddFundReason',
'FUND_REASON_UPDATE': environment.basePathACM + 'FuncForACMAppToUpdateFundReason',
'FUND_REASON_GET': environment.basePathACM + 'FuncForACMAppToGetFundReason',

// Fund Reason Dropdown in Manual Fund Recharge & Finance
'GET_FUND_REASON_LIST' : environment.basePathACM + 'FuncForACMAppToGetfundreasonList',

// Test Cancel After Sample Collection Lab
'CANCEL_TEST_AFT_SAMPLE_COLLECTION' : environment.basePathACM + 'FuncForPMAppAddCancelTestaftersamplecollectionlab',

// New Cash Free API for Verify Payment Status
'TRANSECTION_STATUS_RESPONSE_DETAILS_NEW': environment.basePathACM + 'FuncForPMAppToGetCashfreePaymentbyOrderIdTrnStatus',

// 
'GET_MIS_CO_PROMOTION_LEDGER_REPORT': environment.basePathACM + 'FuncForMISAppTocopromotionledger',
'GET_MIS_CO_PROMOTION_LEDGER_REPORT_EXCEL': environment.basePathACM + 'FuncForMISAppTocopromotionledgerExportExl',

// API To Get Other CC List
'GET_OTHER_CC_LIST': environment.basePathACM + 'FuncForACMAppToGetBtotherccList',

 // Cancel Test MIS Report
 'GET_CANCEL_TEST_REPORT' : environment.basePathACM + 'FuncForMISAppTocanceltestreport',
 'GET_CANCEL_TEST_REPORT_EXPORT_EXCEL' : environment.basePathACM + 'FuncForMISAppTocanceltestreportExportExl',

 // Test Cancel After Sample Collection Lab
'CANCEL_TEST_AFT_SAMPLE_COLLECTION_CLIENT' : environment.basePathACM + 'FuncForPMAppAddCancelTestaftersamplecollectionclient',

 //SNR MIS
 'SEARCH_SNR_MIS': environment.basePathACM+'FuncForMISAppToSnrReport',
 'SEARCH_SNR_EXCEL_EXPORT': environment.basePathACM+'FuncForMISAppToSnrReportExportExl',

 //Tariff Master Report

 'SEARCH_TARIFF_MASTER_REPORT':environment.basePathACM+ 'FuncForMISAppToTariffReport',
 'SEARCH_TARIFF_MASTER_EXCEL_REPORT':environment.basePathACM+ 'FuncForMISAppToTariffReportExportExl',

 // Lab, Client API To Cancel Test in Today Booking Window
 'PATIENT_LAB_CANCEL_TEST_GET_TODAY_BOOKING': environment.basePathACM + 'FuncForPMAppToGetPatientTestCancelaftersamplecollectionLab',
 'PATIENT_CLIENT_CANCEL_TEST_GET_TODAY_BOOKING': environment.basePathACM + 'FuncForPMAppToGetPatientTestCancelAfterSampleCollectionClient',

 // Test Performing Location TAT TIME GET API
 'PERFORMING_LOCATION_TAT_GET_DETAIL' : environment.basePathACM + 'FuncForACMAppToGetTestPerfLocTat',

 //Inconclusive Report
 'INCONCLUSIVE_REPORT': environment.basePathACM +'FuncForMISAppToInconclusiveReport',
 'INCONCLUSIVE_REPORT_EXCEL': environment.basePathACM +'FuncForMISAppToInconclusiveReportExportExl',

 //Open Registration MIS
 'OPEN_REGISTRATION':environment.basePathACM+'FuncForMISAppToopenregistrationreport',
 'OPEN_REGISTRATION_EXCEL':environment.basePathACM+'FuncForMISAppToopenregistrationreportExportExl',

 // NEW MIS API (ARPT-ARPA, DRN-CRN, Package Breakuo)
 'GET_MIS_ARPT_ARPA_REPORT': environment.basePathACM + 'FuncForMISAppToarptarpareport',
 'GET_MIS_ARPT_ARPA_REPORT_EXPORT_EXCEL': environment.basePathACM + 'FuncForMISAppToarptarpareportExportExl',
 'GET_MIS_DRN_CRN_REPORT': environment.basePathACM + 'FuncForMISAppToDrnCrn',
 'GET_MIS_DRN_CRN_REPORT_EXPORT_EXCEL': environment.basePathACM + 'FuncForMISAppToDrnCrnExportExcel',
 'GET_MIS_PACKAGE_BREAKUP_REPORT': environment.basePathACM + '',
 'GET_MIS_PACKAGE_BREAKUP_EXPORT_EXCEL': environment.basePathACM + '',

 //CANCELATION TNP REVENUE REPORT
 'GET_MIS_CANCELATION_TNP_REVENUE_REPORT' :environment.basePathACM + 'FuncForMISAppTocancelationtnprevenue',
 'GET_MIS_CANCELATION_TNP_REVENUE_REPORT_EXPORT_EXCEL' :environment.basePathACM + 'FuncForMISAppTocancelationtnprevenueExpoertExcel',

  // HIS Order Listing For Lab User
  'GET_HIS_ORDER_LIST' :environment.basePathACM + 'FuncForHISAppToGetPatientOrderDeatils',
  
   //BALANCE DUE REPORT
 'GET_MIS_BALANCE_DUE_REPORT':environment.basePathACM+'FuncForMISAppToBalanceDueReport',
 'GET_MIS_BALANCE_DUE_REPORT_EXPORT_EXCEL':environment.basePathACM+'FuncForMISAppToBalanceDueReportExportExcel',

 // Approve HIS Data
 'ACCEPT_HIS_DATA' : environment.basePathACM + 'FuncForHISAppToAddPatientOrderDeatils',
 
  //HIS MASTER
 'HIS_SEARCH_TEST_LIST':environment.basePathACM+'FuncForTMAppToSearchTestName',
 'HIS_TEST_MAPPING_ADD':environment.basePathACM+'FuncForTMAppToHisAddTestMap',
 'HIS_GET_TEST_MAPPING_LIST':environment.basePathACM+'FuncForTMAppToHisTestNameList',
 'HIS_GRID_SEARCH':environment.basePathACM+'FuncForTMAppToHisNameSearch',
 'HIS_EXCEL_UPLOAD':environment.basePathACM+'FuncForTMAppToaddHisTestfromExcel',

 //HIS REF. DOCTOR
 'HIS_ADD_REFFERING_DOCTOR_MAPPING':environment.basePathACM+'FuncForTMAppToHisAddRefDoctorMap',
 'HIS_GET_REFFERING_DOCTOR_MAPPING':environment.basePathACM+'FuncForTMAppToHisDoctorNameList',
 'HIS_GET_SEARCH_DOCTOR_LIST':environment.basePathACM+'FuncForTMAppToSearchRefDoctorName',


  //BALANCE DUE REPORT
  'GET_TESTWISE_REVENUE_SUMMARY_REPORT':environment.basePathACM + 'FuncForMISAppTotestwiserevenuesummary',
  'GET_TESTWISE_REVENUE_SUMMARY_REPORT_EXPORT_EXCEL':environment.basePathACM + 'FuncForMISAppTotestwiserevenuesummaryExportExcel',
   
//TEST BILLED VS TEST RUN REPORT
'GET_TEST_BILLED_VS_TEST_RUN_REPORT':environment.basePathACM + 'FuncForMISAppTotestbilledvstestrun',
'GET_TEST_BILLED_VS_TEST_RUN_REPORT_EXPORT_EXCEL':environment.basePathACM + 'FuncForMISAppTotestbilledvstestrunExportExcel',

//STATUS OF INVOICES TO CLIENTS REPORT
'STATUS_OF_INVOICES_TO_CLIENTS_REPORT':environment.basePathACM + 'FuncForMISAppTostatusofinvoice',
'STATUS_OF_INVOICES_TO_CLIENTS_REPORT_EXPORT_EXCEL':environment.basePathACM + 'FuncForMISAppTostatusofinvoiceExportExcel',

// DHOOT HIS Order Listing For Lab User
'GET_DHOOT_HIS_ORDER_LIST' :environment.basePathACM + 'FuncForHISDhootAppToGetPatientOrderDetails',
'ACCEPT_DHOOT_HIS_DATA' : environment.basePathACM + 'FuncForHISDhootAppToAddPatientOrderDetails',

//CLIENT CATEGORY WISE REVENUE REPORT
'CLIENT_CATEGORY_WISE_REVENUE_REPORT':environment.basePathACM + 'FuncForMISAppToclientcategorywiserevenue',
'CLIENT_CATEGORY_WISE_REVENUE_REPORT_EXPORT_EXCEL':environment.basePathACM + 'FuncForMISAppToclientcategorywiserevenueExportExcel',

//PACKAGE BREAKUP REPORT
'PACKAGE_BREAKUP_REPORT':environment.basePathACM + 'FuncForMISAppToPackageBreaup',
'PACKAGE_BREAKUP_REPOR_EXPORT_EXCEL':environment.basePathACM + 'FuncForMISAppToPackageBreaupExportExcel',

'PATIENT_CORP_BULK_REGISTRATION_LAB':  environment.basePathACM + 'FuncForBulkPatientRegistration',


//PSN Document
'PSN_DOCUMENT_LIST':environment.basePathACM + 'FuncForPMAppToGetPatientBookingListByDocuments',
'PSN_DOCUMENT_VIEW':environment.basePathACM + 'FuncForPMAppToGetPatientBookingDocsList',
'MORE_PSN_LIST':environment.basePathACM + 'FuncForPMAppToGetPSNList',




}




///////////################################///////////////////////

const rootPath = (dataVar: any) => {

  let url: any = environment.newEndpoint;
  let path = url[dataVar] + "/api/";
  return path;
}



export const apiUrl: any = {

  _basePath:{
    
    hisArtFertility:
      {
          url: "V1/FuncForHISArtFertilityAppToGetPatientOrderDeatils", method: "POST"
      },
      artFertilityAccept:
      {
          url: "V1/FuncForHISArtFertilityAppToAddPatientOrderDeatils", method: "POST"
      },
  },
  _common:
  {
      reportPath:
      {
          url: "v1/ViewReport", method: "POST"
      },
      reportView:
      {
          url: "v1/PreviewReportPath", method: "POST"
      }
      

  },
 
  _genrateReport: {
    ViewReportBuilderNew:
    {
        url: "LabReportBuilder/ViewReportBuilderNew", method: "POST"
    },
  },


  of(dataVar: any) {

      let urlPath;

      for (let i in this) {
          if (this[i][dataVar]) {
              let fullUrl = rootPath(i) + this[i][dataVar].url
              urlPath = { ...this[i][dataVar], url: fullUrl };
              break;
          }

      }
      return urlPath;
  }


}