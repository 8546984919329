import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { HeaderComponent } from './header/header.component';
import {SidebarModule} from 'primeng/sidebar';
import {TooltipModule} from 'primeng/tooltip';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
import {MatSelectModule} from '@angular/material/select';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {ToastModule} from 'primeng/toast';
import {MenuModule} from 'primeng/menu';
import {InputTextModule} from 'primeng/inputtext';
import {TableModule} from 'primeng/table';
import {PaginatorModule} from 'primeng/paginator';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {RadioButtonModule} from 'primeng/radiobutton';
import {CheckboxModule} from 'primeng/checkbox';
import {CalendarModule} from 'primeng/calendar';
import {TabViewModule} from 'primeng/tabview';
import {MultiSelectModule} from 'primeng/multiselect';
import {DragDropModule} from 'primeng/dragdrop';
import {PanelMenuModule} from 'primeng/panelmenu';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {MenuItem} from 'primeng/api';
import {MatRadioModule} from '@angular/material/radio';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { DateAdapter, MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatDialogModule, MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { NgxDropzoneModule } from 'ngx-dropzone';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { NgScrollbarModule } from 'ngx-scrollbar';
import {MatExpansionModule} from '@angular/material/expansion';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DatePipe } from '@angular/common';
import {  MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { ActualDateFormatPipe } from './pipes/actual-date-format.pipe';
import { NumberDirective } from './directives/numbers-only.directive';
import {AadharDirective } from './directives/aadhar-only.directive';
import { MatBadgeModule } from '@angular/material/badge';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import { MomentModule } from 'ngx-moment';
import {MatAutocompleteModule} from '@angular/material/autocomplete'
import {FileUploadModule} from 'primeng/fileupload';
import {MatChipsModule} from '@angular/material/chips';
import {TwoDigitPercentageDirective} from './directives/percentage-value.directive';
import {EightTwoDigitDecimalDirective} from './directives/eight_two_decimal.directive';
import {DialogModule} from 'primeng/dialog';
import {TabMenuModule} from 'primeng/tabmenu';
import { NegativeEightTwoDigitDecimalDirective } from './directives/negative_eight_two_decimal.directive';
import { FourTwoDigitDecimalDirective } from './directives/four_two_decimal.directive';
import { DragableAgmMapComponent } from './dragable-agm-map/dragable-agm-map.component';
import {AgmCoreModule} from '@agm/core';
import { TwoSevenDirective } from './directives/two_seven-value.directive';
import {  AlphaNumbericDirective } from './directives/alpha_numeric.directive';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { AlphaNumbericWithSpecialCharDirective } from './directives/alpha_numeric_with_spacialChar.directive';
import { EighteenTwoDigitDecimalDirective } from './directives/eighteen_two_decimal.directive';

import {ConfirmDialogModule} from 'primeng/confirmdialog';
import { DeleteConfirmationComponent } from '../dialog/delete-confirmation/delete-confirmation.component';
import { TextEditorComponent } from '../dialog/text-editor/text-editor.component';
import { ReceiptPreviewPrintComponent } from '../dialog/receipt-preview-print/receipt-preview-print.component';
import { BarcodeScannerLivestreamModule } from "ngx-barcode-scanner";
import { TextCommonEditorComponent } from './text-editor/text-editor.component';
import { QuillModule } from 'ngx-quill';
import { NineTwoDigitDecimalDirective } from './directives/nine_two_decimal.directive';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { FormulaCalculationDirective } from './directives/formula-calculation.directive';
import { AlphaNumbericWithCopyPastAllowDirective } from './directives/alpha_numeric_with_copy_past_allow.directive';
import { TwoDigitDecimalDirective } from './directives/two_digit_decimal.directive';
import { TenThreeDigitDecimalDirective } from './directives/ten_three_decimal.directive';

// import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import { CKEditorModule } from 'ckeditor4-angular';

import { CkEditorComponent } from './ck-editor/ck-editor.component';
import {TreeTableModule} from 'primeng/treetable';
import { AlphaNumbericWithoutSpecialCharDirective } from './directives/alpha_numeric-without-specialchar.directive';
import { ViewTextEditorContentComponent } from '../dialog/view-text-editor-content/view-text-editor-content.component';
import { VerifyOtpComponent } from '../dialog/verify-otp/verify-otp.component'
import { MasterCodeDirective } from './directives/master-code.directive';
import { MasterNameDirective } from './directives/master-name.directive';
import { RemarkDirective } from './directives/master-remark.directive';
import { SalutationNameDirective } from './directives/salutation-name.directive';
import { NumberwithCopyDirective } from './directives/number_only_with_copy_paste_allow.directive';

import { NgxBarcodeModule } from 'ngx-barcode';
import { NegativeNotAllowedDirective } from './directives/negative-not-allowed.directive'
import { AddRemarksComponent } from '../dialog/add-remarks/add-remarks.component';
import { AlphabetsOnlyDirective } from './directives/alphabets-only.directive';
import { AlphabetsOnlyWithSpaceDirective } from './directives/alphabets-only-with-space.directive';
import { QRCodeModule } from 'angularx-qrcode';
import { CkEditorMasterComponent } from './ck-editor-master/ck-editor-master.component'
import { AlphaNumericWithoutSpaceAndSpecialCharDirective } from './directives/alphanumeric_WithoutSpaceAndWithoutSpecialChar.directive';
import { TwoThreePercentageDirective } from './directives/three_two_directive';
import { KeyPressStopDirective } from './directives/key_press_prevent.directive';
import { RollingClientPanCardConfirmationComponent } from '../dialog/rolling-client-pan-card-confirmation/rolling-client-pan-card-confirmation.component';
import { CheckEffectiveDateComponent } from '../dialog/check-effective-date/check-effective-date.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { OneTwoDigitDirective } from './directives/one_two_decimal.directive';
import { ConfirmationPopupComponent } from '../dialog/confirmation-popup/confirmation-popup.component';
import { OnlyAlphaNumbericWithCopyPastAllowDirective } from './directives/onlyAlphanumericAndSpace.directive';
// import {MatButtonToggleModule} from '@angular/material/button-toggle';

@NgModule({
  declarations: [
    SideMenuComponent, HeaderComponent,
    DeleteConfirmationComponent,
    ConfirmationPopupComponent,
    RollingClientPanCardConfirmationComponent,
    AddRemarksComponent,
    ViewTextEditorContentComponent,
    VerifyOtpComponent,
    CheckEffectiveDateComponent,
    TextEditorComponent,
    ReceiptPreviewPrintComponent, 
    ActualDateFormatPipe,NumberDirective,
    TwoDigitPercentageDirective,TwoSevenDirective,
    OneTwoDigitDirective,
    EightTwoDigitDecimalDirective,EighteenTwoDigitDecimalDirective,
    NegativeEightTwoDigitDecimalDirective,FourTwoDigitDecimalDirective, 
    NineTwoDigitDecimalDirective,
    TwoDigitDecimalDirective,
    DragableAgmMapComponent,AlphaNumbericDirective,
    AlphaNumbericWithoutSpecialCharDirective, 
    MasterCodeDirective,
    MasterNameDirective,
    RemarkDirective,
    AlphaNumbericWithSpecialCharDirective,
    AadharDirective,
    TextCommonEditorComponent,
    ConfirmDialogComponent,
    FormulaCalculationDirective,
    AlphaNumbericWithCopyPastAllowDirective,
    OnlyAlphaNumbericWithCopyPastAllowDirective,
    TenThreeDigitDecimalDirective,
    CkEditorComponent,
    SalutationNameDirective,
    NumberwithCopyDirective,
    NegativeNotAllowedDirective,
    AlphabetsOnlyDirective,
    AlphabetsOnlyWithSpaceDirective,
    CkEditorMasterComponent,
    AlphaNumericWithoutSpaceAndSpecialCharDirective,
    TwoThreePercentageDirective,
    KeyPressStopDirective,
    // MatButtonToggleModule

  ],
  imports: [
    QRCodeModule,
    NgxBarcodeModule,
    CommonModule,
    SidebarModule,
    TooltipModule,
    ButtonModule,
    MatSelectModule,
    MatMenuModule,
    MatIconModule,
    ToastModule,
    MenuModule,
    InputTextModule,
    DropdownModule,
    TableModule,    
    PaginatorModule,
    PanelMenuModule,
    DynamicDialogModule,
    RadioButtonModule,
    CheckboxModule,
    CalendarModule,
    TabViewModule,
    MultiSelectModule,
    DragDropModule,
    MatRadioModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule, 
    MatMomentDateModule,
    ReactiveFormsModule,
    FormsModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatCheckboxModule,
    MatButtonModule,
    MatTabsModule,
    MatTooltipModule,
    MaterialFileInputModule,
    NgxDropzoneModule,
    NgxMaterialTimepickerModule, 
    MatNativeDateModule, MatMomentDateModule,
    NgScrollbarModule,
    MatExpansionModule,
    PerfectScrollbarModule,
    OverlayPanelModule,
    MomentModule,
    MatAutocompleteModule,
    FileUploadModule,
    MatChipsModule,
    DialogModule,
    TabMenuModule ,
    DynamicDialogModule,
    TreeTableModule,
    NgxMatDatetimePickerModule, 
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    MatBadgeModule,
    BarcodeScannerLivestreamModule,
    QuillModule.forRoot(),
    MatMomentDateModule,
    ConfirmDialogModule,
    CKEditorModule,
    MatButtonToggleModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCFXVvqthcbHVsP9eVtmaxSzsTEXj-ao7c',
      libraries: ['places']
    }),
  ],
  entryComponents:[
    DeleteConfirmationComponent,
    RollingClientPanCardConfirmationComponent,
    AddRemarksComponent,
    ViewTextEditorContentComponent,
    VerifyOtpComponent,
    CheckEffectiveDateComponent,
    ReceiptPreviewPrintComponent,
    TextEditorComponent
  ],

  exports:[SideMenuComponent,
    QRCodeModule,
    NgxBarcodeModule,
    TextCommonEditorComponent,
    ConfirmDialogComponent,
    CkEditorComponent,
    CkEditorMasterComponent,
    HeaderComponent,
    NumberDirective,
    TwoDigitPercentageDirective,
    OneTwoDigitDirective,
    TwoSevenDirective,
    EightTwoDigitDecimalDirective,
    EighteenTwoDigitDecimalDirective,
    NegativeEightTwoDigitDecimalDirective,
    FourTwoDigitDecimalDirective,
    NineTwoDigitDecimalDirective,
    TwoDigitDecimalDirective,
    DragableAgmMapComponent,
    SidebarModule,
    TooltipModule,
    ButtonModule,
    MatSelectModule,
    MatMenuModule,
    MatIconModule,
    ToastModule,
    MenuModule,
    InputTextModule,
    DropdownModule,
    TableModule,    
    PaginatorModule,
    PanelMenuModule,
    DynamicDialogModule,
    RadioButtonModule,
    CheckboxModule,
    CalendarModule,
    TabViewModule,
    MultiSelectModule,
    DragDropModule,
    MatRadioModule,
    MatInputModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatCheckboxModule,
    MatNativeDateModule, MatMomentDateModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatTabsModule,
    MatTooltipModule,
    MatCheckboxModule,
    MaterialFileInputModule,
    NgxDropzoneModule,
    NgxMaterialTimepickerModule, 
    NgScrollbarModule,
    MatExpansionModule,
    PerfectScrollbarModule,
    OverlayPanelModule,
    MatButtonToggleModule,
    MomentModule,
    MatAutocompleteModule,
    FileUploadModule,
    MatChipsModule,
    DialogModule,
    TabMenuModule,
    DynamicDialogModule,
    NgxMatNativeDateModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    MatMomentDateModule,
    MatMomentDateModule,
    AlphaNumbericDirective,
    AlphaNumbericWithoutSpecialCharDirective,
    MasterCodeDirective,
    MasterNameDirective,
    RemarkDirective,
    AlphaNumbericWithSpecialCharDirective,
    ConfirmDialogModule,
    AadharDirective,
    BarcodeScannerLivestreamModule,
    FormulaCalculationDirective,
    AlphaNumbericWithCopyPastAllowDirective,
    OnlyAlphaNumbericWithCopyPastAllowDirective,
    TenThreeDigitDecimalDirective,
    CKEditorModule,
    TreeTableModule,
    SalutationNameDirective,
    NumberwithCopyDirective,
    NegativeNotAllowedDirective,
    AlphabetsOnlyDirective,
    AlphabetsOnlyWithSpaceDirective,
    MatBadgeModule,
    TwoThreePercentageDirective,
    KeyPressStopDirective,
    AlphaNumericWithoutSpaceAndSpecialCharDirective,
  ],
  providers: [MatDatepickerModule,MatNativeDateModule, DatePipe,
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true,strict:true }},
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: true}},
    {provide: MAT_DIALOG_DATA, useValue: {}}
   
    // {provide:MAT_DATE_LOCALE,useValue:'en-GB'}

  ],
  
})
export class QCommonModule { }
