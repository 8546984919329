import { Component, OnInit } from '@angular/core';
import { PathkindService } from 'src/app/services/pathkind.service';
import { API_PATH } from 'src/app/utility/reference/api-constant';
import { ToasterService } from 'src/app/services/toaster.service'
import { CommonService } from 'src/app/services/common.service';
import { ConfirmationService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router'

export interface MENUDATA {
  menuList: any[]
  moduleList: any[]
  submenuList: any[]
}
export interface menuModel {
  label: string,
  icon: string,
  routerLink: string,
  items?: menuModel[]
  command:any
}

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})

export class SideMenuComponent implements OnInit {

  model;
  userModel;
  testModel;
  LabModel;
  InstrumentModel;
  masterModel;
  clientModel;
  apiConstant: any = API_PATH;
  selectedModuleGuid: string;
  mainMenu = [
    'Dashboard', 'Master', 'User', 'Test', 'Lab', 'Instrument', 'Client', 'Profile'
  ]

  subMenuList: any[] = [];
  menuData: MENUDATA = {
    menuList: [],
    moduleList: [],
    submenuList: []
  };
  userData: any;
  userShortName: string = '';
  dashboard = {
    displayname: "Dashboard",
    moduleimagepath: "assets/images/dashboard-icon.svg"
  }

  Profile = {
    displayname: "Profile",
    moduleimagepath: "assets/images/profile-icon.svg"
  }

  profile = [
    {
      label: "Account Settings",
      icon: "",
      routerLink: "/profile/account-setting"
    },
    {
      label: "Audit Trail",
      icon: "",
      routerLink: "/profile/audit-trail"
    },
    {
      label: "Sign Out",
      icon: "",
      command:(event)=>{
        this.onMenuChange(event);
      }
    },
  ]
  tabShow: boolean=true;
  crmIndexMenu: any;

  constructor(private router: Router,
    public service: PathkindService,
    private toaster: ToasterService,
    private route: ActivatedRoute,private commonservice: CommonService
  ) {
    
       
  }

  ngOnInit(): void {
    this.commonservice.getTabReset().subscribe(data => {
      
           if (data['isTabSave']==true) {
             this.tabShow = true;
           }else if(data['isTabSave']==false){
            this.tabShow = false;
           }
    })
   this.selectedModuleGuid =  sessionStorage.getItem('selectedModuleGuid');
    this.setUserDetails();
    if (sessionStorage.getItem('menuData')) {

      let data = JSON.parse(sessionStorage.getItem('menuData'));
      this.menuData = data;
      
      this.menuData.moduleList = data.moduleList;
      this.menuData.menuList = data.menuList;
      //commented due to submenu not required
     // this.menuData.submenuList = data.submenuList;
     // For Ameyo CRM Index Landing to Live call page
     let crmUserData = JSON.parse(sessionStorage.getItem('crmUserData'));
     let currentURL= location.href;
     let lastUrlWord=currentURL.substring(currentURL.lastIndexOf('/'));
     if(crmUserData?.sessionId && lastUrlWord == '/live-call'){
       this.getAmeyoCrmPageMenu();
     }else if(crmUserData?.sessionId && (lastUrlWord == '/info' || lastUrlWord == '/manage-lead')){
      this.getAmeyoCrmPageMenu();
     }
     // End
    }
    else {
      this.getMenuList();
    }
    //------------get Menus if path is not dashboard--------------
    if (sessionStorage.getItem("menus") && !this.router.url.includes('/dashboard')) {
      let menuList= JSON.parse(sessionStorage.getItem("menus"))
      
      if(menuList && menuList.length>0){
        menuList.forEach(element => {
          element.command=(event)=>{
            this.onMenuChange(event);
          }
        });
      }
      this.model =menuList;
    }
    else {
      this.model = null;
    }
    //--------------------------------------
  }

 getAmeyoCrmPageMenu(){
   
    // For Ameyo LiveCall Redirect
    let menu = JSON.parse(sessionStorage.getItem("menuData"));
    let crmUserData = JSON.parse(sessionStorage.getItem('crmUserData'));
    menu.moduleList.forEach(element => {
      if(element.modulename == 'Customer Relationship Management' && crmUserData?.campaignId != '74'){
        this.crmIndexMenu = element;
      }else if(element.modulename == 'Lead Management System' && crmUserData?.campaignId == '74')
      this.crmIndexMenu = element;
    });
    if(crmUserData?.sessionId){
    this.openMenuTabs(this.crmIndexMenu);
    }
    // End
 }

  getMenuList() {
    
    let request = {
      "userGuid": "ef0ca74e-6542-11eb-863e-00155d022d06",
      "Data": {}
    }
    this.service.postService(this.apiConstant.MENU_GET, request).subscribe(res => {
      if (res['statusCode'] === "0") {
        
        let data = res['data']
        if (data['_moduleList']) {
          this.menuData.moduleList = data._moduleList;
          this.menuData.menuList = data._menuList;
           //commented due to submenu not required
          this.menuData.submenuList = data._submenuList;

          // this.moduleList =data['_moduleList'];
          this.menuData.moduleList.push(this.Profile);
          this.menuData.moduleList.unshift(this.dashboard);
        }
        else {
          this.menuData.moduleList.push(this.Profile);
          this.menuData.moduleList.unshift(this.dashboard);

        }
     
        sessionStorage.setItem('menuData', JSON.stringify(this.menuData));
      this.getAmeyoCrmPageMenu();
      } else {
        // this.menuData = [];
        this.toaster.showError(res['statusMessage']);
      }
    }, error => {
      
      this.toaster.showError(error.error.Message);
    })
  }

  setMenuList(module) {
    
    let menuList: any = [];
    this.selectedModuleGuid = module.moduleGuid;
    sessionStorage.setItem('selectedModuleGuid', this.selectedModuleGuid);
    this.menuData['menuList'].forEach(data => {
      if (data.moduleGuid === module.moduleGuid) {
        let obj: menuModel;
        obj = {
          label: data.menuname,
          icon: data.menuimage,
          routerLink: data.menupath,
          command:(event)=>{
            this.onMenuChange(event);
          }
        }
         //commented due to submenu not required
        // if (this.menuData['submenuList'] && this.menuData['submenuList'].length > 0) {
        //   this.menuData['submenuList'].forEach(submenu => {
        //     if (data.menuGuid === submenu.parentmenuGuid) {
        //       if (!obj['items']) {
        //         obj['items'] = [];
        //       }
        //       let subMenuObj = {
        //         label: submenu.menuname,
        //         icon: '',
        //         routerLink: submenu.menupath
        //       }
        //       obj['items'].push(subMenuObj);
        //     }
        //   });
        // }
        //------if login user is LAB ----------------//
        if (data.menuGuid == 'fadb17f7-b873-11eb-88ab-0022486e4534') { //For booking in patient registration
          if (this.userData && this.userData.clientlabType === 'L') {
            obj.routerLink = '/patient-registration/booking-lab';
          }
        }
        if (data.menuGuid == '086750f6-b874-11eb-88ab-0022486e4534') {  //For Batch in patient registration
          if (this.userData && this.userData.clientlabType === 'L') {
            obj.routerLink = '/patient-registration/batch-lab';
          }
        }
        if (data.menuGuid == '801bbdd3-a06d-11ed-bfd0-0022486e4534') { //Lab User Directory of service 
          if (this.userData && this.userData.clientlabType === 'L') {
            obj.routerLink = '/patient-registration/directory-of-services-lab';
          }
        }
        //---------------------------------------------

        menuList.push(obj);
      }
    });

    this.model = menuList;
    
    sessionStorage.setItem("menus",JSON.stringify(this.model));
  }

  openMenuTabs(module) {
    
    if (module.displayname == 'Dashboard') {
      this.router.navigate(['/dashboard']);
      this.model = null;
    }
    else if (module.displayname == 'Profile') {

      this.model = this.profile;
    }
    else {
      this.menuData.moduleList.forEach(element => {
        if (element.moduleGuid === module.moduleGuid) {
          this.setMenuList(module);
        }
      })

    }
    
    sessionStorage.setItem("menus", JSON.stringify(this.model));
  }
  
  signOutApiIntegration(){
    let request = {
      "Data": {loginstatus: true}
    }
    this.service.postService(this.apiConstant.LOG_OUT, request).subscribe(res => {
      if (res['statusCode'] === "0") {
        this.service.signOut();
        this.toaster.showSuccess(res['statusMessage']);
      } else {
        this.toaster.showError(res['statusMessage']);
      }
    }, error => {
      this.toaster.showError(error.error.Message);
    })
  }

  onMenuChange(event) {
    
   // 

    if (event.item.label == 'Sign Out') {
      this.signOutApiIntegration()
        // this.service.signOut();
      return;
    }
    
    this.menuData['menuList'].forEach(element => {
      if (element.menuname === event.item.label) {
        sessionStorage.setItem("menuGuid", JSON.stringify(element));
      }
    }
    );     
  }

  setUserDetails() {
    
    if (JSON.parse(sessionStorage.getItem('userData')) && JSON.parse(sessionStorage.getItem('userData')).data) {
      this.userData = JSON.parse(sessionStorage.getItem('userData')).data;
      this.userShortName = this.userData.profileName
    }

  }

}
