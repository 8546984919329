import { Directive,ElementRef,HostListener } from '@angular/core';

@Directive({
  selector: '[appAlphabetsOnlyWithSpace]'
})
export class AlphabetsOnlyWithSpaceDirective {

  key:any;
  private regex:RegExp = new RegExp('^[A-Za-z]+[A-Za-z ]*$')

  constructor(private el:ElementRef) { }

  @HostListener('keydown',['$event']) onKeydown(event:KeyboardEvent){

  let current: string = this.el.nativeElement.value;
  const position = this.el.nativeElement.selectionStart;
  const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
  if (next && !String(next).match(this.regex)) {
    event.preventDefault();
  }

}

}
