<!--view text editor content Confirmation-->
<div class="common-dialog">
    <div class="header">
        <div class="row">
            <div class="col-md-10">
                <h1 class="mb-0">{{popupHeader}}</h1>
            </div>
            <div class="col-md-1 mt-n1">
                <button mat-icon-button mat-dialog-close color="mat-primary">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <perfect-scrollbar thumbClass="custom-scrollbars">
        <div class="modal-body" style="height: 280px;overflow: auto;overflow-x: scroll;">
            <div id="viewEditorHtml"></div>
        </div>
    </perfect-scrollbar>
    <div class="modal-footer">
        <button pButton name="no" type="cancel" (click)="close()" label="Close" class="button-border mr-1"></button>
    </div>
</div>