import { Component, OnInit } from '@angular/core';
import { CommonService } from './services/common.service';

import { HostListener } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Pathkind-UI';
  check;
  array;
  constructor(private commonService:CommonService){
    
  }
  ngOnInit(){
    this.getData();
  }
  getData(){
     this.array =[1,2,3,4,5,6,7]
  }


  @HostListener('keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    
    if(event.code=='Enter' )
    {
      this.commonService.isKeyPressed = true;
    }
    else
    {
      this.commonService.isKeyPressed = false;
    }
      //event.preventDefault();
      //event.stopPropagation();
     // return false;
  }

  @HostListener('keyup', ['$event'])
  handleKeyUpEvent(event: KeyboardEvent) { 
    
    this.commonService.isKeyPressed = false;
      
  }


}
