<div class="modal-header modal-top-header">
    <h2 class="mb-1">Editor</h2>	
  </div>	
    <div class="modal-body" style="max-height:75vh;">
      <div class="row">
        <div class="col-md-12">
           <!-- <ckeditor [(ngModel)]="this.content"></ckeditor> -->
           <ckeditor [(ngModel)]="this.content" 
           #myckeditor="ngModel"
           name="myckeditor"
           required
           [config]="ckeConfig" 
           debounce="500" class="custom-style"
       >
       </ckeditor>
          <!-- <ckeditor class="custom-style" [(ngModel)]="this.content" [editor]="Editor"></ckeditor> -->
        </div>
      </div>

      <!--Footer-->
      <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="modal-footer w-100 justify-content-start bg-white position-end left-0">                   
                  <button type="button" (click)="close()" pButton label="Close" class="button-border"></button>
                  <button type="button" (click)="add()" pButton label="Add" class="save-button mr-1"></button>
              </div>
          </div>
      </div>
    </div>